import { createReducer } from "@reduxjs/toolkit";

export const userReducer = createReducer({}, (builder) => {
  builder

    // LOADING REQUEST

    .addCase("userLoginRequest", (state) => {
      state.loading = true;
    })
    .addCase("getUserDetailsRequest", (state) => {
      state.loading = true;
    })
    .addCase("getPlanSettingsRequest", (state) => {
      state.loading = true;
    })
    .addCase("createNewClientRequest", (state) => {
      state.loading = true;
    })
    .addCase("getCostumersRequest", (state) => {
      state.loading = true;
    })
    .addCase("createFolderRequest", (state) => {
      state.loading = true;
    })
    .addCase("getFolderRequest", (state) => {
      state.loading = true;
    })
    .addCase("getTrashFolderRequest", (state) => {
      state.loading = true;
    })
    .addCase("uploadFileRequest", (state) => {
      state.loading = true;
    })
    .addCase("moveFileToTrashRequest", (state) => {
      state.loading = true;
    })
    .addCase("deleteFileRequest", (state) => {
      state.loading = true;
    })
    .addCase("addCommentRequest", (state) => {
      state.loading = true;
    })
    .addCase("updateApprovalStatusRequest", (state) => {
      state.loading = true;
    })
    .addCase("updateImportantStatusRequest", (state) => {
      state.loading = true;
    })
    .addCase("userPaymentRequest", (state) => {
      state.loading = true;
    })
    .addCase("userStorageUsageRequest", (state) => {
      state.loading = true;
    })
    .addCase("userUpdateCustomerStatusRequest", (state) => {
      state.loading = true;
    })
    .addCase("deleteCustomerRequest", (state) => {
      state.loading = true;
    })
    .addCase("restoremultiplefileRequest", (state) => {
      state.loading = true;
    })
    .addCase("getCustomerDetailsRequest", (state) => {
      state.loading = true;
    })

    // SUCCESS REQUEST

    .addCase("userLoginSuccess", (state, action) => {
      state.loading = false;
      state.userLogin = action.payload;
    })
    .addCase("getUserDetailsSuccess", (state, action) => {
      state.loading = false;
      state.user = action.payload;
    })
    .addCase("getPlanSettingsSuccess", (state, action) => {
      state.loading = false;
      state.planSettings = action.payload;
    })
    .addCase("createNewClientSuccess", (state, action) => {
      state.loading = false;
      state.user_details = action.payload;
    })
    .addCase("getCostumersSuccess", (state, action) => {
      state.loading = false;
      state.get_Costumers = action.payload;
    })
    .addCase("createFolderSuccess", (state, action) => {
      state.loading = false;
      state.create_Folder = action.payload;
    })
    .addCase("getFolderSuccess", (state, action) => {
      state.loading = false;
      state.get_Folder = action.payload;
    })
    .addCase("getTrashFolderSuccess", (state, action) => {
      state.loading = false;
      state.get_trash_Folder = action.payload;
    })
    .addCase("uploadFileSuccess", (state, action) => {
      state.loading = false;
      state.upload_file = {
        value: action.payload,
        timestamp: Date.now(),
      };
    })
    .addCase("moveFileToTrashSuccess", (state, action) => {
      state.loading = false;
      state.trashed_files = action.payload;
    })
    .addCase("deleteFileSuccess", (state, action) => {
      state.loading = false;
      state.deleted_files = action.payload;
    })
    .addCase("addCommentSuccess", (state, action) => {
      state.loading = false;
      state.add_Comment = action.payload;
    })
    .addCase("updateApprovalStatusSuccess", (state, action) => {
      state.loading = false;
      state.approval_status = action.payload;
    })
    .addCase("updateImportantStatusSuccess", (state, action) => {
      state.loading = false;
      state.important_status = action.payload;
    })
    .addCase("userPaymentSuccess", (state, action) => {
      state.loading = false;
      state.payment = action.payload;
    })
    .addCase("userStorageUsageSuccess", (state, action) => {
      state.loading = false;
      state.user_storage_usage = action.payload;
    })
    .addCase("userUpdateCustomerStatusSuccess", (state, action) => {
      state.loading = false;
      state.user_update_customer_status = action.payload;
    })
    .addCase("deleteCustomerSuccess", (state, action) => {
      state.loading = false;
      state.delete_customer = action.payload;
    })
    .addCase("restoremultiplefileSuccess", (state, action) => {
      state.loading = false;
      state.restored_files = action.payload;
    })
    .addCase("getCustomerDetailsSuccess", (state, action) => {
      state.loading = false;
      state.Customer_Details = action.payload;
    })
    .addCase("progressUpdateSuccess", (state, action) => {
      state.loading = false;
      state.uploadingFileProgress = action.payload;
    })

    // FAIL REQUEST

    .addCase("userLoginFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("getUserDetailsFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("getPlanSettingsFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("createNewClientFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("getCostumersFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("createFolderFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("getFolderFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("getTrashFolderFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("uploadFileFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("moveFileToTrashFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("deleteFileFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("addCommentFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("updateApprovalStatusFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("updateImportantStatusFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("userPaymentFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("userStorageUsageFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("userUpdateCustomerStatusFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("deleteCustomerFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("restoremultiplefileFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("getCustomerDetailsFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
});
