import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <img
      src="https://img.icons8.com/material-sharp/50/left.png"
      alt="go-back"
      id="back-icon"
      onClick={goBack}
    />
  );
};

export default BackButton;
