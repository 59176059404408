import { createReducer } from "@reduxjs/toolkit";

export const sidebarReducer = createReducer({}, (builder) => {
  builder.addCase("SIDEBAR_OPTION", (state, action) => {
    state.sidebarOption = action.payload;
  })
  builder.addCase("Add_ClientOption", (state, action) => {
    state.Add_ClientOption = action.payload;
  });
});
