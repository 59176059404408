import React from 'react';
import FavIcon from "../assets/img/FavIcon.png";
import CheckIcon from "../assets/img/Check.svg";
import Footerimage from "../assets/img/Footerimage.png";
export default function TryBanner() {
  return (
    <div className="container w-container">
                <div className="section-title text-center m-b-0">
                  <img src={FavIcon} className="getstartedLogo gs_reveal" />
                  <h2 className="no-margin gs_reveal">Get Started Today</h2>
                  <div className="w-flex w-col-8 text-center xy-auto m-t-10p text-primary-2 gs_reveal">
                    <div className="w-col-4 w-flex w-getstarted-checks">
                      <img src={CheckIcon} />
                      <span className="f18">Free &amp; Paid Plans</span>
                    </div>
                    <div className="w-col-4 w-flex w-getstarted-checks">
                      <img src={CheckIcon} />
                      <span className="f18">Easy Payment Options</span>
                    </div>
                    <div className="w-col-4 w-flex w-getstarted-checks">
                      <img src={CheckIcon} />
                      <span className="f18">Cancel Anytime</span>
                    </div>
                  </div>
                  <div className="topbannerLogo gs_reveal">
                  <a href="/login" style={{textDecoration:"none"}}><div class="button-primary-1 button-subscribe w-button">Try GSPRO For Free</div></a>
                  </div>
                  <div className="getstartedimage gs_reveal">
                    <img src={Footerimage} />
                  </div>
                </div>
              </div>
  )
}
