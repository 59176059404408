import React, { useState } from "react";
import "./loginStyles.css";
import logo from "../../assets/images/GSPro-Logo.svg";
import { Link } from "react-router-dom";
import BackButton from "../../components/BackButton";
import axios from "axios";
import toast from "react-hot-toast";
import loginImg from "../../assets/images/LoginAvatar.svg";
import { backend_api } from "../../redux/store";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const emailValidation = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const forgetPassword = async () => {
    if(!emailValidation(email)) return setEmailError(true);
    else setEmailError(false);
    await axios.post(`${backend_api}/api/requestResetPassword`, {
      email: email,
    }).then((res) => {
      if(res.status === 200) {
        return toast.success(res.data.message);
      }
    }).catch((err) => {
      return toast.error(err.response.data.message || "Something went wrong");
    });
  }

  return (
    <div className="login-page">
      <div className="login-page-inner-container container-left">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <h1 id="title-text">Hi Professional, Welcome </h1>
          <h1 id="title-text">to Gallery Studio Pro</h1>
        </div>
        <p id="subtitle-text">
          Explore the app, Upload your client image and share with them for
        </p>
        <img src={loginImg} alt="login" id="login-img" />
      </div>
      <div className="login-page-inner-container container-right">
        <div className="login-page-form-container">
          <div className="login-page-form-container-top">
            <BackButton />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img src={logo} alt="google" id="logo" style={{height: '3rem'}} />
            </div>
          </div>
          <div className="login-page-form-container-middle">
            <h1 id="welcome-back">Forgot Password</h1>
            <input type="email" placeholder="Email Address" className="login-form-input" onChange={(e) => setEmail(e.target.value)} />
            {emailError && (
              <span style={{ color: "red" }}>
                Please enter a valid email
              </span>
            )}
            <button className="login-btn main-btn" onClick={forgetPassword}>Reset Password</button>
          </div>
          <div className="login-page-form-container-bottom">
            <Link
              to="/login"
              style={{
                textDecoration: "none",
              }}
            >
              <p id="continue-with-email">Already have an account? Sign in</p>
            </Link>
            <div id="line" />
            <p>Powered By</p>
          </div>
        </div>
      </div>
      <div id="bottom-div"></div>
    </div>
  );
};

export default ForgotPassword;
