import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { CheckBox } from '@mui/icons-material';
import ReactPlayer from 'react-player';
import HeadPhoneImage from "../../assets/images/headphone.png";
import AudioIcon from "../../assets/images/AudioIcon.png";
import VideoIcon from "../../assets/images/VideoIcon.png";
import Dashboard from '../../pages/Dashboard/Dashboard';
import ClientDashboardImg from '../../pages/ClientDashboardImage/ClientDashboardImg';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
};

const ImageModal = (data) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        if(data.shouldOpen && !data.selectFilesPressed) setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const path = window.location.pathname;
    
    return (
        <div className='masonry-item'>
            <Image {...data} onClick={handleOpen} />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {
                        path === "/Clientdashboard" ||
                        path === "/ClientDashboard" ||
                        path === "/clientdashboard" ||
                        path === "/clientDashboard" || 
                        path === "/Clientdashboard/folder" ?
                            <ClientDashboardImg data={data.FilesArray} index={data.index} handleClose={handleClose} />
                        :
                            <Dashboard data={data.FilesArray} index={data.index} handleClose={handleClose}  />
                    }
                </Box>
            </Modal>
        </div>
    );
}

export default ImageModal;

const Image = (data) => {
    const {  item, index, selectFilesPressed, selectedFiles, handleSelectFile, comments, approved, important, onClick } = data;
    const [fileType, setFileType] = useState(item?.mimeType?.split("/")[0]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setFileType(item?.mimeType?.split("/")[0]);
        setLoading(true);
    }, [item?.mimeType, index, data?.FilesArray]);

    useEffect(() => {
        if (fileType === "image") {
            const img = new window.Image();
            img.onload = () => setLoading(false);
            img.src = item?.url;
        } else {
            setLoading(false);
        }
    }, [fileType, item?.url, data?.FilesArray]);

    const renderContent = () => {
        if (loading) {
            return (
                <div className="loading-skeleton" style={{ width: '250px', height: '200px', backgroundColor: '#f0f0f0' }}>
                    <Skeleton height={200} />
                </div>
            );
        }

        if (fileType === "image") {
            return (
                <img
                    draggable={false}
                    src={item?.url}
                    alt="gallery-item"
                    id="image-gallery-img"
                    style={{
                        width: "100%"
                    }}
                />
            );
        } else if (fileType === "video") {
            return <ReactPlayer url={item?.url} width={'100%'} height={'200px'} />;
        } else if (fileType === "audio") {
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '250px', height: '200px', backgroundColor: "#F9F6F1" }} >
                    <img src={HeadPhoneImage} style={{ width: '5rem', height: '5rem' }} />
                </div>
            );
        } else {
            return null;
        }
    };

    return(
        <div id="image-gallery-img-container" style={{ cursor: 'pointer',borderRadius: '10px', overflow:'hidden' }} onClick={onClick}>
            {selectFilesPressed && (
                <div id="image-gallery-img-overlay" onClick={() => handleSelectFile(item?._id)}>
                    {selectedFiles?.includes(item?._id) ? <CheckBox id="image-gallery-img-checkbox" /> : <CheckBoxOutlineBlankIcon id="image-gallery-img-checkbox" />}
                </div>
            )}
            {item?.comments?.length > 0 && (
                <img src={comments} alt="comments" id="image-gallery-comments-icon" className="image-gallery-img-icon" style={{zIndex: 1}} />
            )}
            <div className="image-gallery-img-icons-container" style={{zIndex: 1}}>
                {item?.isApproved && <img src={approved} alt="approved" id="image-gallery-approved-icon" className="image-gallery-img-icon" />}
                {item?.isImportant && <img src={important} alt="important" id="image-gallery-important-icon" className="image-gallery-img-icon" />}
            </div>
            {renderContent()}
            {(fileType === "audio" || fileType === "video") && (
                <div className="image-gallery-audio-container">
                    <img src={fileType === "audio" ? AudioIcon : VideoIcon} alt="audio" id="image-gallery-approved-icon" className="image-gallery-audio-icon" />
                </div>
            )}
        </div>
    );
};