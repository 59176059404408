import React, { useContext, useEffect, useState } from "react";
import "./adminClientStyles.css";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import {
  adminDeleteUser,
  adminUpdateUserPlan,
  adminUpdateUserStatus,
  getAllUsers,
  getAllUsersCustomers,
} from "../../../redux/actions/adminActions";
import Cookies from "js-cookie";
import { backend_api } from "../../../redux/store";
import toast from "react-hot-toast";
import { LoadingContext } from "../../Home/ContextProvider";

const ClientHeader = ({ goBack, name, setOpen }) => (
  <div className="admin-client-header">
    <div className="admin-client-header-left">
      <img
        src="https://img.icons8.com/material-sharp/50/left.png"
        alt="go-back"
        id="admin-client-back-btn"
        onClick={goBack}
      />
      <h1 id="admin-client-header-title">{name || "Loading..."}</h1>
    </div>
    <div className="admin-client-header-right">
      <DeleteClient setOpen={setOpen} />
    </div>
  </div>
);

const DeleteClient = ({ setOpen }) => (
  <button id="admin-client-delete-btn" onClick={() => setOpen(true)}>
    Delete User
  </button>
);

const FilesAndFoldersCount = ({ files, folders }) => (
  <div className="admin-client-details-lower-container-left">
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <img
        id="folder-card-icon"
        src="https://img.icons8.com/small/50/folder-invoices--v1.png"
        alt="folder-invoices--v1"
      />
      <p className="admin-client-details-client-subtitle">{folders || 0}</p>
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        marginLeft:'5px'
      }}
    >
      <img
        id="folder-card-icon"
        src="https://img.icons8.com/ios/50/copy--v1.png"
        alt="copy--v1"
      />
      <p className="admin-client-details-client-subtitle">{files || 0}</p>
    </div>
  </div>
);

const AdminClientPersonalDetails = ({
  userDetails,
  switchState,
  handleSwitch,
  handlePlan,
  userPlan,
}) => (
  <div className="admin-client-details-container">
    <div className="admin-client-details-upper-container">
      <div className="admin-client-details-status-container">
        <div className="admin-client-details-status-container-left">
          <img
            width="30"
            height="30"
            src="https://img.icons8.com/fluency-systems-regular/48/guest-male.png"
            alt="guest-male"
          />
          <div className="admin-client-details-client-name-container">
            <p className="admin-client-details-client-name">User</p>
            <div className="admin-client-details-client-plan-dropdown-container">
              <select
                name="plans"
                id="admin-client-details-client-plan-dropdown"
                onChange={(e) => handlePlan(e.target.value)}
                value={userPlan}
              >
                <option value="lite">Lite</option>
                <option value="pro">Pro</option>
                <option value="free">Free</option>
              </select>
            </div>
          </div>
        </div>
        <div className="admin-client-details-status-container-right">
          <Switch
            onChange={handleSwitch}
            checked={switchState}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#5DCE7B"
          />
          <p
            id="admin-client-details-status"
            className={switchState ? "active-status" : "inactive-status"}
          >
            {switchState ? "Active" : "Inactive"}
          </p>
        </div>
      </div>
    </div>
    <div className="admin-client-details-lower-container">
      <div className="admin-client-details-lower-container-left">
        <p className="admin-client-details-client-subtitle">
          Created Date : {moment(userDetails?.createdAt).format("DD MMM YYYY")}
        </p>
      </div>
    </div>
  </div>
);

const AdminClientLoginDetails = ({ userDetails }) => (
  <div className="admin-client-details-container">
    <div className="admin-client-details-upper-container">
      <div className="admin-client-details-status-container">
        <div className="admin-client-details-status-container-left">
          <div className="admin-client-details-client-name-container">
            <p className="admin-client-details-client-login">Login History</p>
          </div>
        </div>
      </div>
    </div>
    <div className="admin-client-details-lower-container">
      <div className="admin-client-details-lower-container-left">
        <p className="admin-client-details-client-subtitle">Last Login:</p>
        <span className="admin-client-details-client-subtitle-2">
          {moment(userDetails?.lastLogin).format("DD MMM YYYY hh:mm")}
        </span>
      </div>
    </div>
  </div>
);

const AdminClientFilesStorage = ({ userDetails }) => {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const [totalStorage, setTotalStorage] = useState(0);
  const [storageUsed, setStorageUsed] = useState(0);

  useEffect(() => {
    if(userDetails){
      getTotalStorage();
      setStorageUsed(convertToMb(userDetails?.storageUsed));
    }
  }, [userDetails]);

  function convertToMb(inbytes) {
    return Math.round(inbytes / 1024 / 1024);
  }

  const getTotalStorage = async () => {
    increaseLoadingCounter();
    await axios.get(
      `${backend_api}/api/admin/getPlanSettings`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("admin_access_token")}`,
        },
      }
    ).then((res) => {
      const totalStorageInBytes = res.data?.planSettings[userDetails?.subscriptionType]?.storageLimit;
      setTotalStorage(convertToMb(totalStorageInBytes));
    }).catch((err) => {
      if(err.response.status === 401) {
        Cookies.remove("admin_access_token");
        window.location.href = "/error404";
      }
    }).finally(()=> {
      decreaseLoadingCounter();
    })
  };
  return (
    <div className="admin-client-details-container">
      <div className="admin-client-details-upper-container">
        <div className="admin-client-details-status-container">
          <div className="admin-client-details-status-container-left">
            <div className="admin-client-details-client-name-container">
              <p
                className="admin-client-details-client-login"
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                Files and Storage
              </p>
              {/* FILES AND FOLDERS ICONS */}
              <FilesAndFoldersCount
                files={userDetails?.totalFiles}
                folders={userDetails?.totalFolders}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="admin-client-details-lower-container">
        <div
          className="admin-client-details-lower-container-left"
          style={{
            width: "100%",
            gap: "30px",
          }}
        >
          <p className="admin-client-details-client-subtitle-2">
            {`${storageUsed<1024?storageUsed+' MB':storageUsed/1024+' GB'}`} of {`${totalStorage<1024?totalStorage+' MB':totalStorage/1024+' GB'}`} used
          </p>
          <LinearProgress
            variant="determinate"
            value={(storageUsed/totalStorage)*100}
            style={{
              height: "8px",
              borderRadius: "10px",
              width: "200px",
              margin: "10px 0px",
              backgroundColor: "#D9D9D9",
            }}
            sx={{
              "& .MuiLinearProgress-bar": {
                borderRadius: "10px",
                backgroundColor: "#4DB267",
              },
            }}
          />
        </div>
      </div>
    </div>
  )
};

const AdminCustomers = ({ usersCustomers }) => (
  <div className="admin-client-details-container">
    <div className="admin-client-details-upper-container">
      <div className="admin-client-details-status-container">
        <div className="admin-client-details-status-container-left">
          <div className="admin-client-details-client-name-container">
            <p className="admin-client-details-client-login">Customers</p>
          </div>
        </div>
      </div>
    </div>
    {/* LOWER CONTAINERS FOR FILES AND FOLDERS */}
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {usersCustomers?.length > 0 ? (
        usersCustomers?.map((customer) => (
          <div className="admin-client-details-lower-container-2">
            <div className="admin-client-details-lower-container-left">
              <p className="admin-client-details-client-subtitle-2">
                {customer?.name}
              </p>
            </div>
            <div className="admin-client-details-lower-container-right">
              <FilesAndFoldersCount
                files={customer?.totalFiles}
                folders={customer?.totalFolders}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="admin-client-details-lower-container-2">
          <div className="admin-client-details-lower-container-left">
            <p className="admin-client-details-client-subtitle-2">
              No Clients yet
            </p>
          </div>
        </div>
      )}
    </div>
    {/* LOWER CONTAINERS END */}
  </div>
);

const AdminClientCredentials = ({ userDetails }) => {
  return (
    <div className="admin-client-details-container">
      {/* USERNAME */}
      <div className="admin-client-details-upper-container">
        <div className="admin-client-details-status-container">
          <div className="admin-client-details-status-container-left">
            <div className="admin-client-details-client-name-container">
              <p className="admin-client-details-client-login">Username</p>
            </div>
          </div>
        </div>
      </div>
      <div className="admin-client-details-lower-container-2">
        <div className="admin-client-details-lower-container-left">
          <p className="admin-client-details-client-username">
            {userDetails?.email}
          </p>
        </div>
      </div>

      {/* PASSWORD */}
      {/* <div className="admin-client-details-upper-container">
        <div className="admin-client-details-status-container">
          <div className="admin-client-details-status-container-left">
            <div className="admin-client-details-client-name-container">
              <p className="admin-client-details-client-login">Password</p>
            </div>
          </div>
        </div>
      </div>
      <div className="admin-client-details-lower-container">
        <div className="admin-client-details-lower-container-left">
          <p className="admin-client-details-client-username">********</p>
        </div>
        <div className="admin-client-details-lower-container-right">
          <p className="admin-client-details-client-link-btn">Show Password</p>
        </div>
      </div> */}
    </div>
  );
};

const AdminClientDetails = () => {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const { adminAllUsers, adminUsersCustomers } = useSelector(
    (state) => state.admin
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allUsers, setAllUsers] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [usersCustomers, setUsersCustomers] = useState([]);
  const [open, setOpen] = useState(false);
  const [switchState, setSwitchState] = useState(true);
  const [userPlan, setUserPlan] = useState("free"); // ["lite", "pro", "free"]
  const searchParams = new URLSearchParams(window.location.search);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePlan = (e) => {
    setUserPlan(e);
  };

  useEffect(() => {
    if (adminAllUsers?.users) {
      setAllUsers(adminAllUsers?.users);
    }
  }, [adminAllUsers]);

  useEffect(() => {
    if (searchParams.get("userId")) {
      const user = allUsers.find(
        (user) => user._id === searchParams.get("userId")
      );
      setUserDetails(user);
    }
  }, [allUsers, searchParams]);

  useEffect(() => {
    if(userDetails?._id) dispatch(getAllUsersCustomers(userDetails?._id));
    setSwitchState(userDetails?.status === "active" ? true : false);
    setUserPlan(userDetails?.subscriptionType);
  }, [userDetails]);

  useEffect(() => {
    if (adminUsersCustomers?.customers) {
      setUsersCustomers(adminUsersCustomers?.customers);
    }
  }, [adminUsersCustomers]);

  const goBack = () => {
    navigate(-1);
  };

  const handleDelete = () => {
    increaseLoadingCounter();
    dispatch(adminDeleteUser(userDetails?._id, navigate)).then(()=> {
      decreaseLoadingCounter();
    })
    handleClose();
  };

  const handleUpdate = () => {
    handleUpdateStatus();
    handleUpdatePlan();
  };

  const handleUpdateStatus = () => {
    if((userDetails.status === 'active' && switchState) || (userDetails.status === 'inactive' && !switchState)) return;
    increaseLoadingCounter();
    dispatch(adminUpdateUserStatus(userDetails?._id, switchState, navigate)).then(()=> {
      decreaseLoadingCounter();
    });
  };

  const handleUpdatePlan = () => {
    if(userDetails.subscriptionType === userPlan) return;
    increaseLoadingCounter();
    dispatch(adminUpdateUserPlan(userDetails?._id, userPlan)).then(()=> {
      decreaseLoadingCounter();
    });
  };

  const DeleteUserAlert = () => (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Are you sure you want to delete ${userDetails?.email}?`}
      </DialogTitle>
      <DialogActions>
        <button
          onClick={handleClose}
          className="admin-client-delete-dialog-btn cancel-btn"
        >
          Cancel
        </button>
        <button
          onClick={handleDelete}
          autoFocus
          className="admin-client-delete-dialog-btn confirm-btn"
        >
          Delete
        </button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div id="admin-client-page">
      <DeleteUserAlert />
      <ClientHeader
        goBack={goBack}
        name={userDetails?.email}
        setOpen={setOpen}
      />
      <hr
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#E5E5E5",
          border: "none",
          margin: "0",
          padding: "0",
        }}
      />
      <hr
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#E5E5E5",
          border: "none",
          margin: "0",
          marginTop: "-20px",
          padding: "0",
        }}
      />
      <>
        <h1 id="admin-client-details-page-title">Client Details</h1>
        <div className="admin-client-details-items-container">
          <AdminClientPersonalDetails
            userDetails={userDetails}
            switchState={switchState}
            handleSwitch={() => setSwitchState(!switchState)}
            userPlan={userPlan}
            handlePlan={handlePlan}
          />
          <AdminClientLoginDetails userDetails={userDetails} />
          <AdminClientCredentials userDetails={userDetails} />
          <AdminCustomers usersCustomers={usersCustomers} />
          <AdminClientFilesStorage userDetails={userDetails} />
        </div>
      </>
      <div id="admin-client-details-update-btn-container">
        <button onClick={handleUpdate} id="admin-client-details-update-btn">
          Update
        </button>
      </div>
    </div>
  );
};

export default AdminClientDetails;
