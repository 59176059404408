import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Button, TextField } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import moment from "moment/moment";
import { setSidebarOption } from "../../redux/actions/sidebarAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { backend_api } from "../../redux/store";
import toast from "react-hot-toast";
import { LoadingContext } from "../Home/ContextProvider";

export default function AdminAddUser() {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const dispatch = useDispatch();
  const [plan, setPlan] = useState("");
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    setPlan(event.target.value);
  };

  const createUser = async () => {
    if (userDetails.password.length < 6) {
      alert("Password should be atleast 6 characters long");
      return;
    }
    if (userDetails.name === "" || userDetails.email === "") {
      alert("Please enter all the details");
      return;
    }
    if (plan === "") {
      alert("Please select a plan");
      return;
    }

    

    increaseLoadingCounter();
    dispatch({
      type: "adminCreateUserLoading",
    });
    await axios.post(
      `${backend_api}/api/admin/createUser`,
      {
        name: userDetails.name,
        email: userDetails.email,
        password: userDetails.password,
        planType: plan,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("admin_access_token")}`,
        },
      }
    ).then(async (res) => {
      dispatch({
        type: "adminCreateUserSuccess",
        payload: res.data,
      });
      toast.success(res.data.message);
      dispatch(setSidebarOption("users"));
    }).catch((err) => {
      dispatch({
        type: "adminCreateUserFailure",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) window.location.href = "/error404";
      return toast.error(err.response.data.message || "Something went wrong");
    }).finally(()=> {
      decreaseLoadingCounter();
    })
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "20px",
        paddingRight: "20px",
        paddingLeft: "20px",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          marginBottom: "100px",
          marginTop: "20px",
          flexDirection: "column",
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#F8E8E6",
            width: "400px",
            padding: "50px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <span
              style={{
                fontFamily: "Nunito",
                fontSize: "20px",
                margin: "auto",
                color: "#3F414E",
                fontWeight: "bold",
              }}
            >
              Create New User
            </span>
          </div>
          <span
            style={{
              fontFamily: "Nunito",
              fontSize: "15px",
              color: "#3F414E",
            }}
          >
            Client Name
          </span>
          <input
            style={{
              borderStyle: "none",
              width: "90%",
              padding: "20px",
              borderRadius: "10px",
              marginTop: "5px",
            }}
            type="text"
            placeholder="Client Name"
            value={userDetails.name}
            onChange={(e) => {
              setUserDetails({
                ...userDetails,
                name: e.target.value,
              });
            }}
          />
          <span
            style={{
              fontFamily: "Nunito",
              fontSize: "15px",
              color: "#3F414E",
              marginTop: "30px",
            }}
          >
            Login Details
          </span>
          <input
            style={{
              borderStyle: "none",
              width: "90%",
              padding: "20px",
              borderRadius: "10px",
              marginTop: "5px",
            }}
            type="text"
            placeholder="Email"
            value={userDetails.email}
            onChange={(e) => {
              setUserDetails({
                ...userDetails,
                email: e.target.value,
              });
            }}
          />
          <input
            style={{
              borderStyle: "none",
              width: "90%",
              padding: "20px",
              borderRadius: "10px",
              marginTop: "15px",
            }}
            type="password"
            placeholder="Password"
            value={userDetails.password}
            onChange={(e) => {
              setUserDetails({
                ...userDetails,
                password: e.target.value,
              });
            }}
          />
          <span
            style={{
              fontFamily: "Nunito",
              fontSize: "15px",
              color: "#3F414E",
              marginTop: "30px",
            }}
          >
            Subscription
          </span>
          <Box
            sx={{
              minWidth: 120,
              borderStyle: "none",
              backgroundColor: "#fff",
              marginTop: "10px",
            }}
          >
            <FormControl fullWidth style={{ borderStyle: "none" }}>
              <TextField
                select
                value={plan}
                label="Plan"
                onChange={handleChange}
                style={{
                  borderStyle: "none",
                  color: "#3F414E",
                  border: "none",
                  outline: "none",
                }}
              >
                <MenuItem value={"free"}>Free</MenuItem>
                <MenuItem value={"lite"}>Lite</MenuItem>
                <MenuItem value={"pro"}>Pro</MenuItem>
              </TextField>
            </FormControl>
          </Box>

          <Button
            onClick={() => {
              createUser();
            }}
            style={{
              marginTop: "20px",
              width: "100%",
              display: "flex",
              backgroundColor: "#FA6E5A",
              borderRadius: "100px",
              paddingLeft: "30px",
              paddingRight: "30px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                fontSize: "14px",
                color: "#fff",
                fontFamily: "Nunito",
                margin: "10px",
              }}
            >
              + Add New User
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}
