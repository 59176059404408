import axios from "axios";
import Cookies from "js-cookie";
import { backend_api } from "../store";
import toast from "react-hot-toast";

const redirectToLogin = () => {
  Cookies.remove("client_access_token");
  window.location.href = "/error404";
};

export const loginClient = (clientDetails, id) => async (dispatch) => {
  dispatch({
    type: "clientLoginLoading",
  });
  if (clientDetails.password.length < 6) {
    alert("Password should be atleast 6 characters long");
    return;
  }
  if (clientDetails.email === "") {
    alert("Please enter all the details");
    return;
  }
  await axios.post(`${backend_api}/api/auth/customerLogin`, {
    userId: id,
    username: clientDetails.username,
    password: clientDetails.password,
  }).then((response) => {
    return new Promise((resolve, reject) => {
      Cookies.set("client_access_token", response.data.access_token);
      resolve();
    }).then(() => {
      dispatch({
        type: "clientLoginSuccess",
        payload: response.data,
      });
      toast.success(response.data.message);
    });
  }).catch((err) => {
    dispatch({
      type: "clientLoginFailure",
      payload: err.response.data.message,
    });
    return toast.error(err.response.data.message || "Something went wrong");
  });
};

export const getFirstFile = () => async (dispatch) => {
  dispatch({
    type: "getFirstFileLoading",
  });
  await axios.get(`${backend_api}/api/customer/getFirstFile`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("client_access_token")}`,
    },
  }).then((response) => {
    dispatch({
      type: "getFirstFileSuccess",
      payload: response.data?.file,
    });
  }).catch((err) => {
    dispatch({
      type: "getFirstFileFailure",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
  });
}; 

export const getClientDetails = () => async (dispatch) => {
  dispatch({
    type: "clientDetailsLoading",
  });
  await axios.get(`${backend_api}/api/customer/getCustomerDetails`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("client_access_token")}`,
    },
  }).then((response) => {
    dispatch({
      type: "clientDetailsSuccess",
      payload: response.data?.customer,
    });
  }).catch((err) => {
    dispatch({
      type: "clientDetailsFailure",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
  });
};

export const getClientFilesFolders = (id) => async (dispatch) => {
  if (!id) return;
  dispatch({
    type: "clientFilesFoldersLoading",
  });
  await axios.get(`${backend_api}/api/customer/listFiles/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("client_access_token")}`,
    },
  }).then((response) => {
    dispatch({
      type: "clientFilesFoldersSuccess",
      payload: response.data,
    });
  }).catch((err) => {
    dispatch({
      type: "clientFilesFoldersFailure",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
  });
};

export const moveClientFileToTrash = (fileId) => async (dispatch) => {
  dispatch({
    type: "moveClientFileToTrashLoading",
  });
  await axios.delete(`${backend_api}/api/customer/moveFileToTrash/${fileId}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("client_access_token")}`,
    },
  }).then((response) => {
    dispatch({
      type: "moveClientFileToTrashSuccess",
      payload: response.data,
    });
  }).catch((err) => {
    dispatch({
      type: "moveClientFileToTrashFailure",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
  });
};

export const updateClientApproveStatus = (fileId, status) => async (dispatch) => {
  dispatch({
    type: "clientApproveStatusLoading",
  });
  await axios.patch(`${backend_api}/api/customer/updateApprovalStatus/${fileId}`,
    {
      isApproved: status,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("client_access_token")}`,
      },
    }
  ).then((response) => {
    dispatch({
      type: "clientApproveStatusSuccess",
      payload: response.data,
    });
    toast.success(response.data.message);
  }).catch((err) => {
    dispatch({
      type: "clientApproveStatusFailure",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
  });
};

export const updateClientImportantStatus = (fileId, status) => async (dispatch) => {
  dispatch({
    type: "clientImportantStatusLoading",
  });
  await axios.patch(
    `${backend_api}/api/customer/updateImportantStatus/${fileId}`,
    {
      isImportant: status,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("client_access_token")}`,
      },
    }
  ).then((response) => {
    dispatch({
      type: "clientImportantStatusSuccess",
      payload: response.data,
    });
    toast.success(response.data.message);
  }).catch((err) => {
    dispatch({
      type: "clientImportantStatusFailure",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
  });
};

export const addClientComment = (fileId, comment) => async (dispatch) => {
  dispatch({
    type: "clientCommentLoading",
  });
  await axios.patch(`${backend_api}/api/customer/addComment/${fileId}`,
    {
      comment: comment,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("client_access_token")}`,
      },
    }
  ).then((response) => {
    dispatch({
      type: "clientCommentSuccess",
      payload: response.data,
    });
    toast.success(response.data.message);
  }).catch((err) => {
    dispatch({
      type: "clientCommentFailure",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
  });
};

export const moveMultipleFilesToTrashCustomer = (files) => async (dispatch) => {
  if (!files || files.length < 1) return toast.error("Please select files to delete");
  dispatch({
    type: "moveClientFileToTrashLoading",
  });
  await axios.delete(`${backend_api}/api/customer/multipleFilesToTrash`, {
    data: { files: files },
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("client_access_token")}`,
    },
  },).then((response) => {
    toast.success(response.data.message);
    dispatch({
      type: "moveClientFileToTrashSuccess",
      payload: response.data,
    });
  }).catch((err) => {
    dispatch({
      type: "moveClientFileToTrashFailure",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
    return toast.error(err.response.data.message);
  });
};

export const getClientTrashFilesFolders = () => async (dispatch) => {
  
  dispatch({
    type: "getClientTrashFilesFoldersLoading",
  });
  await axios.get(`${backend_api}/api/customer/listFilesInTrash`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("client_access_token")}`,
    },
  }).then((response) => {
    dispatch({
      type: "getClientTrashFilesFoldersSuccess",
      payload: response.data,
    });
  }).catch((err) => {
    dispatch({
      type: "getClientTrashFilesFoldersFailure",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
  });
};

export const restoreclientmultiplefile = ( files,rootFolder) => async (dispatch) => {
  dispatch({
    type: "restoreFromTrashLoading",
  });
  await axios.delete(
    `${backend_api}/api/customer/restoreMultipleFiles`, {
    data: { files: files },
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("client_access_token")}`,
    },
  }).then((res) => {
    dispatch({
      type: "restoreFromTrashSuccess",
      payload: res.data,
    });
    toast.success(res.data.message);
  }).catch((err) => {
    dispatch({
      type: "restoreFromTrashFailure",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
    return toast.error(err.response.data.message || "Something went wrong");
  });
}

export const deleteclientmultiplefile = ( files) => async (dispatch) => {
  dispatch({
    type: "deleteclientmultiplefileLoading",
  });
  await axios.delete(
    `${backend_api}/api/customer/deleteMultipleFiles`, {
    data: { files: files },
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("client_access_token")}`,
    },
  }).then((res) => {
    dispatch({
      type: "deleteclientmultiplefileSuccess",
      payload: res.data,
    });
    toast.success(res.data.message);
  }).catch((err) => {
    dispatch({
      type: "deleteclientmultiplefileFailure",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
    return toast.error(err.response.data.message || "Something went wrong");
  });
}
