import { backend_api } from './redux/store';
import Cookies from 'js-cookie';
const io = require('socket.io-client');

let socket;

const socketService = {
  async connect() {
    const token = Cookies.get('access_token');

    if (!token) {
      throw new Error('No token found');
    }

    socket = io(backend_api, {
      extraHeaders: {
        'Authorization': `Bearer ${token}`,
      },
    });

    return new Promise((resolve, reject) => {
      socket.on('connect', () => {
        resolve();
      });

      socket.on('connect_error', (error) => {
        console.error('Socket connection error', error);
        reject(error);
      });
    });
  },

  disconnect() {
    socket.disconnect();
  },

  on(eventName, callback) {
    if (!socket) {
      throw new Error('Must connect to socket before setting event handlers');
    }

    socket.on(eventName, callback);
  },

  emit(eventName, data) {
    if (!socket) {
      throw new Error('Must connect to socket before emitting events');
    }

    socket.emit(eventName, data);
  },
};

export default socketService;