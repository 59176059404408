import React, { useContext } from "react";
import "./homeStyles.css";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { Button, Tooltip } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Modal, Pagination } from "@mui/material";
import {
  getUserDetails,
  userPlanPayment,
} from "../../redux/actions/userAction";
import toast from "react-hot-toast";
import _ from "lodash";
import {  useNavigate } from "react-router-dom";
import { LoadingContext } from "./ContextProvider";

export default function Pricing() {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [currency, setCurrency] = useState("INR");
  const navigate = useNavigate();

  const Prices = [
    {
      title: "Free",
      priceINR: 0,
      priceUSD: 0,
    },
    {
      title: "Lite",
      priceINR: 849,
      priceUSD: 10,
    },
    {
      title: "Pro",
      priceINR: 1699,
      priceUSD: 20,
    },
  ];

  useEffect(() => {
    increaseLoadingCounter();
    dispatch(getUserDetails()).then(() => {
        decreaseLoadingCounter();
    });
  }, []);


  const [selectedPlan, setselectedPlan] = useState()

  const handleOnSubscribe = async(plan) => {
    setselectedPlan(plan);
    if(plan){
      if (user?.subscriptionType === "lite" && plan === "pro") {
        setOpenModal(true)
      }else {
        increaseLoadingCounter();
        dispatch(userPlanPayment(plan)).then(() => {
            decreaseLoadingCounter();
        });
      }
    }
  };

  const UpdatePlanModal = () => {
    return(
    <div className="image-gallery-modal">
      <Modal
        open={openModal}
        // onClose={() => setOpenDeleteWarningModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div id="image-gallery-delete-warning-modal-container">
          <p style={{textAlign:'center'}} id="image-gallery-delete-warning-modal-text">
            Are you sure you want to update Plan. your subscription will automatically upgraded
          </p>
          <div id="image-gallery-delete-warning-modal-btn-container">
            <button
              id="image-gallery-delete-warning-modal-btn-cancel"
              className="image-gallery-delete-warning-modal-btn"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
            <button
              id="image-gallery-delete-warning-modal-btn-delete"
              className="image-gallery-delete-warning-modal-btn"
              onClick={() => {
                dispatch(userPlanPayment(selectedPlan));
                setOpenModal(false)
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )};

  return (
    <div className="home-page">
      <UpdatePlanModal/>
      <Header />
      <Sidebar />
      <div id="sidebar-placeholder"></div>
      <div
        style={{
          width: "100%",
          alignItems: "center",
          alignContent: "center",
          marginTop: "55px",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
        id="home-main-container"
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1>Pricing</h1>
        </div>
        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '2rem'}}>
          <button style={{cursor: 'pointer', backgroundColor: `${currency==='INR'?'#5DCE7B':'#fff'}`, color: `${currency==='INR'?'#fff':'#0B0133'}`, padding: '0.5rem 2.2rem', border: '1px solid #E9E9E9', fontWeight: 'bold', borderTopLeftRadius: '5rem', borderBottomLeftRadius: '5rem'}} onClick={()=> setCurrency('INR')}>₹ INR</button>
          <button style={{cursor: 'pointer', backgroundColor: `${currency==='USD'?'#5DCE7B':'#fff'}`, color: `${currency==='USD'?'#fff':'#0B0133'}`, padding: '0.5rem 2.2rem', border: '1px solid #E9E9E9', fontWeight: 'bold', borderTopRightRadius: '5rem', borderBottomRightRadius: '5rem'}} onClick={()=> setCurrency('USD')}>$ USD</button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <div style={{ width: "28%", border: "3px solid #F9F6F1" }}>
            <div
              style={{
                backgroundColor: "#0b0133",
                height: "10px",
                width: "100%",
                marginBottom:'10px'
              }}
            ></div>
            <p
              style={{ color: "#0b0133", fontSize: "20px", marginLeft: "20px",marginBottom:'0%' }}
            >
              Free
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
                alignItems: "baseline",
                padding: "0px 0px 0px 0px",
                // marginBottom:'10%'
              }}
            >
              <p
                style={{
                  color: "#0b0133",
                  fontSize: "4rem",
                  marginTop: "0px",
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
              >
                {currency === "INR" ? `₹.${Prices[0].priceINR}` : `$.${Prices[0].priceUSD}`}
              </p>
            </div>
            <p
              style={{
                color: "#000",
                marginLeft: "20px",
                marginRight: "20px",
                fontSize: "25px",
              }}
            >
              All essential sharing and review options to get started.
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
              }}
            >
              <DoneOutlinedIcon style={{ color: "#5DCE7B" }} />
              <p style={{ color: "#000", marginLeft: "5px", marginTop: "0px" }}>
                2 GB Storage
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
              }}
            >
              <DoneOutlinedIcon style={{ color: "#5DCE7B" }} />
              <p style={{ color: "#000", marginLeft: "5px", marginTop: "0px" }}>
                One Client Creation
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
              }}
            >
              <DoneOutlinedIcon style={{ color: "#5DCE7B" }} />
              <p style={{ color: "#000", marginLeft: "5px", marginTop: "0px" }}>
                All Review Options
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
              }}
            >
              <DoneOutlinedIcon style={{ color: "#5DCE7B" }} />
              <p style={{ color: "#000", marginLeft: "5px", marginTop: "0px" }}>
                Password Protection
              </p>
            </div>
            <Button
              style={{
                margin: "20px",
                marginRight: "20px",
                backgroundColor: "#EA4133",
                borderRadius: "20px",
                alignSelf: "center",
                width: "80%",
              }}
              onClick={()=>{navigate("/home")}}
            >
              <p className="p" style={{ fontSize: "10px" }}>
                Try For Free
              </p>
            </Button>
          </div>
          <div style={{ width: "28%", border: "3px solid #F9F6F1" }}>
            <div
              style={{
                backgroundColor: "#5DCE7B",
                height: "10px",
                width: "100%",
              }}
            ></div>
            <p
              style={{ color: "#5DCE7B", fontSize: "20px", marginLeft: "20px",marginBottom:'0%' }}
            >
              Lite
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
                alignItems: "baseline",
                padding: "0px 0px 0px 0px",
                // marginBottom:'10%'
              }}
            >
              <p
                style={{
                  color: "#5DCE7B",
                  fontSize: "4rem",
                  marginTop: "0px",
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
              >
                {currency === "INR" ? `₹.${Prices[1].priceINR}` : `$.${Prices[1].priceUSD}`}
              </p>
              <p
                style={{
                  color: "#5DCE7B",
                  fontSize: "20px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                Per Month
              </p>
            </div>
            <p
              style={{
                color: "#000",
                marginLeft: "20px",
                marginRight: "20px",
                fontSize: "25px",
              }}
            >
              All essential sharing and review options to get started.
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
              }}
            >
              <DoneOutlinedIcon style={{ color: "#5DCE7B" }} />
              <p style={{ color: "#000", marginLeft: "5px", marginTop: "0px" }}>
                15 GB Storage
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
              }}
            >
              <DoneOutlinedIcon style={{ color: "#5DCE7B" }} />
              <p style={{ color: "#000", marginLeft: "5px", marginTop: "0px" }}>
                10 Client Creation
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
              }}
            >
              <DoneOutlinedIcon style={{ color: "#5DCE7B" }} />
              <p style={{ color: "#000", marginLeft: "5px", marginTop: "0px" }}>
                All Review Options
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
              }}
            >
              <DoneOutlinedIcon style={{ color: "#5DCE7B" }} />
              <p style={{ color: "#000", marginLeft: "5px", marginTop: "0px" }}>
                Password Protection
              </p>
            </div>
            <Button
              style={{
                margin: "20px",
                marginRight: "20px",
                backgroundColor: "#EA4133",
                borderRadius: "20px",
                alignSelf: "center",
                width: "80%",
              }}
              onClick={() => {
                handleOnSubscribe("lite");
              }}
            >
              <p className="p" style={{ fontSize: "10px" }}>
                Subscribe
              </p>
            </Button>
          </div>
          <div style={{ width: "28%", border: "3px solid #F9F6F1" }}>
            <div
              style={{
                backgroundColor: "#EA4133",
                height: "10px",
                width: "100%",
              }}
            ></div>
            <p
              style={{ color: "#EA4133", fontSize: "20px", marginLeft: "20px",marginBottom:'0%' }}
            >
              Pro
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
                alignItems: "baseline",
                padding: "0px 0px 0px 0px",
                // marginBottom:'10%'
              }}
            >
              <p
                style={{
                  color: "#EA4133",
                  fontSize: "4rem",
                  marginTop: "0px",
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
              >
                {currency === "INR" ? `₹.${Prices[2].priceINR}` : `$.${Prices[2].priceUSD}`}
              </p>
              <p
                style={{
                  color: "#EA4133",
                  fontSize: "20px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                Per Month
              </p>
            </div>
            <p
              style={{
                color: "#000",
                marginLeft: "20px",
                marginRight: "20px",
                fontSize: "25px",
              }}
            >
              All essential sharing and review options to get started.
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
              }}
            >
              <DoneOutlinedIcon style={{ color: "#5DCE7B" }} />
              <p style={{ color: "#000", marginLeft: "5px", marginTop: "0px" }}>
                50 GB Storage
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
              }}
            >
              <DoneOutlinedIcon style={{ color: "#5DCE7B" }} />
              <p style={{ color: "#000", marginLeft: "5px", marginTop: "0px" }}>
                Unlimited Client Creation
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
              }}
            >
              <DoneOutlinedIcon style={{ color: "#5DCE7B" }} />
              <p style={{ color: "#000", marginLeft: "5px", marginTop: "0px" }}>
                All Review Options
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "20px",
              }}
            >
              <DoneOutlinedIcon style={{ color: "#5DCE7B" }} />
              <p style={{ color: "#000", marginLeft: "5px", marginTop: "0px" }}>
                Password Protection
              </p>
            </div>
              <Button
                style={{
                  margin: "20px",
                  marginRight: "20px",
                  backgroundColor: "#EA4133",
                  borderRadius: "20px",
                  alignSelf: "center",
                  width: "80%",
                }}
                onClick={() => {
                  handleOnSubscribe("pro");
                }}
              >
                <p className="p" style={{ fontSize: "10px" }}>
                  Subscribe
                </p>
              </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
