import React from "react";
// import "./assets/css/custom.css";
// import "./assets/css/main.css";

const TermsPage = ({url}) => {
    const hostedUrl = `${url}/static`;
    const staticHTML = `
    <body>
        <div class="navbar w-nav" id="myHeader">
            <div class="container w-container">
                <div class="grid-navbar">
                    <a href="/" 
                        class="brand w-nav-brand" aria-label="home">
                        <img
                            src="${hostedUrl}/assets/img/GSPro-Logo.svg"
                            loading="eager" alt="" class="logo"></a>
                            <nav role="navigation" 
                            class="nav-menu w-nav-menu">
                            <a href="/" aria-current="page"
                            class="nav-link w-nav-link" >Home</a>
                            <a href="/about" aria-current="page" class="nav-link w-nav-link" >About</a>
                            <a href="/features" class="nav-link w-nav-link" >Features</a>
                            <a href="/pricings" class="nav-link w-nav-link" >Pricing</a>
                            
                            <a href="/login" class="nav-link m-nav-link">Login</a>
                        </nav>
                    <div class="navbar-right nav-hide">
                        <a href="/login" class="nav-link">Login</a>
                        <a data-w-id="f3952b50-6d1f-393e-b11f-b676ceacc841" href="/registration"
                            class="button-link-icon nav-button-hide w-inline-block">
                            <div>
                                <input type="submit" value="Try For Free" data-wait="Please wait..." class="button-primary-1 button-subscribe w-button">
                            </div>
                        </a>
                    </div>
                    <div  class="menu-button w-nav-button">
                        <div class="w-icon-nav-menu"></div>
                    </div>
                </div>
            </div>
            <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
        </div>
        <section class="hero-section">
            <div class="hero-section-decoration innerpage">
                <div class="container-medium w-container">
                    <div class="hero-inner-content-wrap">
                        <div class="hero-inner-content">
                            
                            <h1>Terms & conditions</h1>
                            <p class="benefit-content">
                                Last updated: March 05, 2024
                            </p>
                            <div class="rich-text w-richtext" style="text-align: left;">
                                <h5>Introduction</h5>
                                <p class="blacktext">Welcome to Gallery Studio Pro! These Terms of Service (the "Terms") govern your use of the Gallery Studio Pro platform (the "Platform") for managing media galleries, obtaining approvals, and collaborating through comments. Please read these Terms carefully before using our services. By using our services, you agree to be bound by these Terms.</p>
                                
                                <h5>1. User Eligibility</h5>
                                <p class="blacktext">By using Gallery Studio Pro, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms. If you are using the Platform on behalf of an organization or entity, you represent and warrant that you have the authority to bind that organization or entity to these Terms.</p>
        
                                <h5>2. Account Registration</h5>
                                <p class="blacktext">
                                    2.1 Account Creation: To use certain features of the Platform, you may be required to create an account. You agree to provide accurate and complete information during the registration process and to keep your account information up to date.
                                    </p><p class="blacktext">
                                    2.2 Account Security: You are responsible for maintaining the security of your account and password. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>
                                <h5>3. Your Content</h5>
                                <p class="blacktext">3.1 Media Files: You retain ownership of all media files and content that you upload to the Platform. By uploading content, you grant Gallery Studio Pro a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and display your content solely for the purpose of providing our services.
                                    </p><p class="blacktext">
                                    3.2 Comments and Collaborative Content: You are responsible for the comments, annotations, and collaborative content you create on the Platform. We do not claim ownership of such content, but you grant us the right to display, distribute, and share it as part of our services.</p>
                                    <h5>
                                        4. Acceptable Use
                                    </h5>
                                    <p class="blacktext">

    4.1 Compliance: You agree to use the Platform in compliance with all applicable laws and regulations.
    </p><p class="blacktext">
    4.2 Prohibited Conduct: You shall not engage in any of the following activities on the Platform:
    Violating any third-party rights, including intellectual property rights.
    Uploading, transmitting, or distributing malicious code, viruses, or any other harmful technology.
    Attempting to gain unauthorized access to the Platform or other users' accounts.
    Engaging in any activity that disrupts or interferes with the operation of the Platform.
                                    </p>
                                    <h5>5. Privacy</h5>
                                    <p class="blacktext">
                                        Your use of the Platform is subject to our Privacy Policy, which describes how we collect, use, disclose, and protect your data. By using our services, you consent to our privacy practices.</p>
                                    <h5>6. Termination</h5>
                                    <p class="blacktext">We reserve the right to suspend or terminate your account and access to the Platform at our discretion, with or without cause, and with or without notice.</p>
                                    <h5>7. Changes to Terms</h5>
                                    <p class="blacktext">We may update these Terms from time to time to reflect changes in our services or for legal reasons. Any changes will be posted on the Platform, and your continued use of the Platform after such changes constitute your acceptance of the revised Terms.</p>
                                    
                                <p class="blacktext">&zwj;</p>
                                <h5>Contact Us</h5>
                                <p class="blacktext"><strong>&zwj;</strong>Please send your feedback, comments, requests for technical support:</p>
                                <p class="blacktext"><a href="#" target="_blank">info@gallerystudiopro.com</a></p>
                            </div>
                        </div>
                    </div>
                    
            </div>
            
            </div>

        </section>


    
        <footer class="footer">
            <div class="container w-container">
                <div class="w-layout-grid grid-footer">
                    <div>
                        <a href="/"
                            aria-current="page" class="footer-logo-wrap w-inline-block w--current">
                            <img src="${hostedUrl}/assets/img/FavIcon.png" loading="eager" alt="" class="footer-logo-wrap">
                        </a>
                        <p class="no-margin blacktext">Securely Organize, Showcase,and Collaborate 
                            On your Media Assets with <b>Gallery Studio pro</b></p>
                    </div>
                    <div id="w-node-_5b5b05ab-e775-238d-7db9-2056f436ada0-f436ad98" class="footer-item-wrap">
                        <h6 class="footer-title">Pages</h6>
                        <div class="w-layout-grid grid-footer-link">
                            <div class="footer-link-wrap">
                                <a href="/" aria-current="page"
                                class="footer-link w--current">Home</a>
                                <a href="/about" aria-current="page"
                                class="footer-link w--current">About</a>
                                <a href="/features" aria-current="page"
                                class="footer-link w--current">Features</a>
                                <a href="/pricings" aria-current="page"
                                class="footer-link w--current">Pricing</a>
                                
                            </div>
                        </div>
                    </div>
                    <div id="w-node-_5b5b05ab-e775-238d-7db9-2056f436adb6-f436ad98" class="footer-item-wrap">
                        <h6 class="footer-title">Utility pages</h6>
                        <div class="w-layout-grid grid-footer-link">
                            <div class="footer-link-wrap">
                                <a href="privacy" class="footer-link"> Privacy</a>
                                <a href="terms" class="footer-link">Terms</a>
                                <a href="security" class="footer-link">Security</a>
                            </div>
                        </div>
                    </div>
                    <div id="w-node-_5b5b05ab-e775-238d-7db9-2056f436adcc-f436ad98" class="footer-item-wrap">
                        <h6 class="footer-title">Contact Us</h6>
                        <div class="grid-footer-link"><a href="mailto:info@gallerystudiopro.com"
                                class="footer-link">info@gallerystudiopro.com</a>
                            <div class="footer-social-wrap">
                                <a href="" target="_blank"
                                    class="footer-social-link  w-inline-block"><img
                                        src="${hostedUrl}/assets/img/fb.svg"
                                        loading="eager" alt="" class="footer-social-icon"></a>
                                        <a href=""
                                    target="_blank" class="footer-social-link w-inline-block"><img
                                        src="${hostedUrl}/assets/img/insta.svg"
                                        loading="eager" alt="" class="footer-social-icon"></a>
                                        <a
                                    href="" target="_blank"
                                    class="footer-social-link w-inline-block"><img
                                        src="${hostedUrl}/assets/img/youtube.svg"
                                        loading="eager" alt="" class="footer-social-icon"></a>
                                    
                                    </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom text-center ">
                    <div class="xy-auto"> Powered by <a href=""
                            target="_blank" class="footer-link text-primary-2">The Smart Trunk</a></div>
                </div>
            </div>
        </footer>
    </body>
    `;
    return <div dangerouslySetInnerHTML={{ __html: staticHTML }} />;
}

export default TermsPage;