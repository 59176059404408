import axios from "axios";
import { backend_api } from "../store";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import socketService from "../../socket";

const redirectToLogin = () => {
  Cookies.remove("access_token");
  window.location.href = "/error404";
};

export const loginUser = (userDetails) => async (dispatch) => {
  dispatch({
    type: "userLoginRequest",
  });
  await axios.post(`${backend_api}/api/auth/userLogin`, {
    email: userDetails.email,
    password: userDetails.password,
  }).then(async (res) => {
    return new Promise((resolve, reject) => {
      Cookies.set("access_token", res.data.access_token);
      resolve();
    }).then(() => {
      dispatch({
        type: "userLoginSuccess",
        payload: res.data,
      });
      toast.success(res.data.message);
    });
  }).catch((err) => {
    dispatch({
      type: "userLoginFail",
      payload: err.response.data.message,
    });
    return toast.error(err.response.data.message || "Something went wrong");
  });
};

export const registerUser = (userDetails) => async (dispatch) => {
  dispatch({
    type: "userLoginRequest",
  });
  await axios.post(`${backend_api}/api/auth/userRegister`, {
    name: userDetails.name,
    email: userDetails.email,
    password: userDetails.password,
  }
  ).then((res) => {
    return new Promise((resolve, reject) => {
      Cookies.set("access_token", res.data.access_token);
      resolve();
    }).then(() => {
      dispatch({
        type: "userLoginSuccess",
        payload: res.data,
      });
      toast.success(res.data.message);
    });
  }).catch((err) => {
    dispatch({
      type: "userLoginFail",
      payload: err.response.data.message,
    });
    return toast.error(err.response.data.message || "Something went wrong");
  });
};

export const getUserDetails = () => async (dispatch) => {
    dispatch({
      type: "getUserDetailsRequest",
    });

    await axios.get(`${backend_api}/api/user/getUserDetails`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("access_token")}`,
      },
    }).then((res) => {
        dispatch({
          type: "getUserDetailsSuccess",
          payload: res.data.user,
        });
      }).catch((err) => {
        dispatch({
          type: "getUserDetailsFail",
          payload: err.response.data.message,
        });
        if(err.response.status === 401) redirectToLogin();
        return toast.error(err.response.data.message || "Something went wrong");
      });
};

export const getPlanSettings = () => async (dispatch) => {
  dispatch({
    type: "getPlanSettingsRequest",
  });
  await axios.get(`${backend_api}/api/user/getPlanSettings`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("access_token")}`,
    },
  }).then((res) => {
    dispatch({
      type: "getPlanSettingsSuccess",
      payload: res.data.planSettings,
    });
  }).catch((err) => {
    dispatch({
      type: "getPlanSettingsFail",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
  });
};

export const createNewClient = (Name, Email, Password, Username, setIsDisabled) => async (dispatch) => {
  dispatch({
    type: "createNewClientRequest",
  });
  await axios.post(`${backend_api}/api/user/addCustomer`,
    {
      name: Name,
      username: Username,
      email: Email,
      password: Password,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("access_token")}`,
      },
    }
  ).then((res) => {
    dispatch({
      type: "createNewClientSuccess",
      payload: res.data,
    });
    toast.success(res.data?.message);
  }).catch((err) => {
    dispatch({
      type: "createNewClientFail",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
    return toast.error(err.response.data.message || "Something went wrong");
  }).finally(() => {
    setIsDisabled(false)
  })
};

export const createFolder = (Uid, folderId, folderName) => async (dispatch) => {
  dispatch({
    type: "createFolderRequest",
  });
  await axios.post(
    `${backend_api}/api/user/createFolder/${Uid}`,
    {
      name: folderName,
      parent: folderId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("access_token")}`,
      },
    }
  ).then((res) => {
    if (res.status === 200) {
      toast.success("Folder Created Successfully");
      dispatch({
        type: "createFolderSuccess",
        payload: res?.data,
      });
    }
  }).catch((err) => {
    dispatch({
      type: "createFolderFail",
      payload: err?.response?.data?.message,
    });
    if(err.response.status === 401) redirectToLogin();
    return toast.error(err?.response?.data?.message || "Something went wrong");
  })
};

export const getCostumers = (token) => async (dispatch) => {
    token = token || Cookies.get("access_token");
    if(!token) return;
    dispatch({
      type: "getCostumersRequest",
    });
    await axios.get(`${backend_api}/api/user/getCustomers`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    }).then((res) => {
      dispatch({
        type: "getCostumersSuccess",
        payload: res.data,
      });
    }).catch((err) => {
      dispatch({
        type: "getCostumersFail",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
    });
};
export const getCustumerDetails = (custumerId) => async (dispatch) => {

  dispatch({
    type: "getCustumerDetailsRequest",
  });

  await axios.get(`${backend_api}/api/user/getCustomerDetails/${custumerId}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("access_token")}`,
    },
  }).then((res) => {
    dispatch({
      type: "getCustomerDetailsSuccess",
      payload: res.data.customer,
    });
  }).catch((err)=>{
    dispatch({
      type: "getCustomerDetailsFail",
      payload: err.response.data.message || 'Something went wrong'
    });
    if(err.response.status === 401) redirectToLogin();
  })
};

export const getFolder = (Uid, FolderId) => async (dispatch) => {
    if(!Uid || !FolderId) return;
    dispatch({
      type: "getFolderRequest",
    });
    await axios.get(
      `${backend_api}/api/user/listFiles/${Uid}/${FolderId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("access_token")}`,
      },
    }).then((res) => {
      dispatch({
        type: "getFolderSuccess",
        payload: res.data,
      });
    }).catch((err) => {
      dispatch({
        type: "getFolderFail",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
    });
};

export const getFolderFromTrash = (Uid) => async (dispatch) => {
    dispatch({
      type: "getTrashFolderRequest",
    });
    await axios.get(`${backend_api}/api/user/listFilesInTrash/${Uid}`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("access_token")}`,
      },
    }).then((res) => {
      dispatch({
        type: "getTrashFolderSuccess",
        payload: res.data,
      });
    }).catch((err) => {
      dispatch({
        type: "getTrashFolderFail",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
    });
};

export const moveFileToTrash = (CustomerId, FileId, navigate) => async (dispatch) => {
      dispatch({
        type: "moveFileToTrashRequest",
      });
      
      await axios.delete(
        `${backend_api}/api/user/moveFileToTrash/${CustomerId}/${FileId}`,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
          },
        }
      ).then((res) => {
        dispatch({
          type: "moveFileToTrashSuccess",
          payload: res.data,
        });
        toast.success(res.data.message);
      }).catch((err) => {
        dispatch({
          type: "moveFileToTrashFail",
          payload: err.response.data.message,
        });
        if(err.response.status === 401) redirectToLogin();
        return toast.error(err.response.data.message || "Something went wrong");
      });
};

export const moveMultipleFilesToTrash = (CustomerId, files, folderId) => async (dispatch) => {
      dispatch({
        type: "moveFileToTrashRequest",
      });
      await axios.delete(
        `${backend_api}/api/user/multipleFilesToTrash/${CustomerId}`, {
        data: { files: files },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("access_token")}`,
        },
      }).then((res) => {
        dispatch({
          type: "moveFileToTrashSuccess",
          payload: res.data,
        });
        toast.success(res.data.message);
      }).catch((err) => {
        dispatch({
          type: "moveFileToTrashFail",
          payload: err.response.data.message,
        });
        if(err.response.status === 401) redirectToLogin();
        return toast.error(err.response.data.message || "Something went wrong");
      });
};

export const restoremultiplefile = (CustomerId, files, folderId) => async (dispatch) => {
  dispatch({
    type: "restoremultiplefileRequest",
  });
  await axios.delete(
    `${backend_api}/api/user/restoreMultipleFiles/${CustomerId}`, {
    data: { files: files },
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("access_token")}`,
    },
  },
  ).then((res) => {
    dispatch({
      type: "restoremultiplefileSuccess",
      payload: res.data,
    });
    toast.success(res.data.message);
  }).catch((err) => {
    dispatch({
      type: "restoremultiplefileFail",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
    return toast.error(err.response.data.message || "Something went wrong");
  });
}

export const deleteMultipleFilesFromTrash = (CustomerId, files) => async (dispatch) => {
  dispatch({
    type: "deleteFileRequest",
  });
  await axios.delete(
    `${backend_api}/api/user/deleteMultipleFiles/${CustomerId}`, {
    data: { files: files },
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("access_token")}`,
    },
  }).then((res) => {
    dispatch({
      type: "deleteFileSuccess",
      payload: res.data,
    });
    toast.success(res.data.message);
  }).catch((err) => {
    dispatch({
      type: "deleteFileFail",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
    return toast.error(err.response.data.message || "Something went wrong");
  });
};

export const moveFolderToTrash = (CustomerId, FolderId) => async (dispatch) => {
  dispatch({
    type: "moveFileToTrashRequest",
  });
  await axios.delete(
    `${backend_api}/api/user/moveFolderToTrash/${CustomerId}/${FolderId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("access_token")}`,
      },
    }
  ).then((res) => {
    dispatch({
      type: "moveFileToTrashSuccess",
      payload: res.data,
    });
    toast.success(res.data.message);
  }).catch((err) => {
    dispatch({
      type: "moveFileToTrashFail",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
    return toast.error(err.response.data.message);
  });
};

export const restoreFolder = (CustomerId, FolderId, rootFolder) => async (dispatch) => {
  dispatch({
    type: "restoremultiplefileRequest",
  });
  await axios.delete(
    `${backend_api}/api/user/restoreFolder/${CustomerId}/${FolderId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("access_token")}`,
      },
    }
  ).then((res) => {
    dispatch({
      type: "restoremultiplefileSuccess",
      payload: res.data,
    });
    toast.success(res.data.message);
  }).catch((err) => {
    dispatch({
      type: "restoremultiplefileFail",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
    return toast.error(err.response.data.message);
  });
};

export const deleteFolder = (CustomerId, FolderId) => async (dispatch) => {
  dispatch({
    type: "deleteFileRequest",
  });
  await axios.delete(
    `${backend_api}/api/user/deleteFolder/${CustomerId}/${FolderId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("access_token")}`,
      },
    }
  ).then((res) => {
    dispatch({
      type: "deleteFileSuccess",
      payload: res.data,
    });
    toast.success(res.data.message);
  }).catch((err) => {
    dispatch({
      type: "deleteFileFail",
      payload: err.response.data.message,
    });
    if(err.response.status === 401) redirectToLogin();
    return toast.error(err.response.data.message);
  });
};

export const deleteFile = (CustomerId, FileId) => async (dispatch) => {
    dispatch({
      type: "deleteFileRequest",
    });
    await axios.delete(
      `${backend_api}/api/user/deleteFile/${CustomerId}/${FileId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("access_token")}`,
        },
      }
    ).then((res) => {
      dispatch({
        type: "deleteFileSuccess",
        payload: res.data,
      });
      toast.success(res.data.message);
    }).catch((err) => {
      dispatch({
        type: "deleteFileFail",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
      return toast.error(err.response.data.message || "Something went wrong");
    });
};
  
export const addComment = (CustomerId, FileId, comment) => async (dispatch) => {
    dispatch({
      type: "addCommentRequest",
    });
    await axios.patch(
      `${backend_api}/api/user/addComment/${CustomerId}/${FileId}`,
      {
        comment: comment,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("access_token")}`,
        },
      }
    ).then((res) => {
      dispatch({
        type: "addCommentSuccess",
        payload: res.data,
      });
      toast.success(res.data.message);
    }).catch((err) => {
      dispatch({
        type: "addCommentFail",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
      return toast.error(err.response.data.message || "Something went wrong");
    });
};

export const updateApprovalStatus = (uid, fileId, isApproved) => async (dispatch) => {
    dispatch({
      type: "updateApprovalStatusRequest",
    });
    await axios.patch(
      `${backend_api}/api/user/updateApprovalStatus/${uid}/${fileId}`,
      {
        isApproved: isApproved,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("access_token")}`,
        },
      }
    ).then((res) => {
      dispatch({
        type: "updateApprovalStatusSuccess",
        payload: res.data,
      });
      toast.success(res.data.message);
    }).catch((err) => {
      dispatch({
        type: "updateApprovalStatusFail",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
      return toast.error(err.response.data.message || "Something went wrong");
    });
};

export const updateImportantStatus = (uid, fileId, isImportant) => async (dispatch) => {
    dispatch({
      type: "updateImportantStatusRequest",
    });
    
    await axios.patch(
      `${backend_api}/api/user/updateImportantStatus/${uid}/${fileId}`,
      {
        isImportant: isImportant,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("access_token")}`,
        },
      }
    ).then((res) => {
      dispatch({
        type: "updateImportantStatusSuccess",
        payload: res.data,
      });
      toast.success(res.data.message);
    }).catch((err) => {
      dispatch({
        type: "updateImportantStatusFailure",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
      return toast.error(err.response.data.message || "Something went wrong");
    });
};

export const userPlanPayment = (plan) => async (dispatch) => {
    dispatch({
      type: "userPaymentRequest",
    });
    await axios.post(
      `${backend_api}/api/user/upgradeSubscription`,
      {
        plan: plan,
        billingPeriod: "monthly", //monthly or yearly
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("access_token")}`,
        },
      }
    ).then((res) => {
      dispatch({
        type: "userPaymentSuccess",
        payload: res.data,
      });
      if (res.data.sessionUrl) window.open(res.data?.sessionUrl, "_blank");
      if(res.data.message) toast.success(res.data.message);
    }).catch((err) => {
      dispatch({
        type: "userPaymentFail",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
      return toast.error(err.response.data.message || "Something went wrong");
    });
};

export const getUserStorageUsage = () => async (dispatch) => {
  dispatch({
    type: "getUserStorageUsageRequest",
  });
  await axios.get(`${backend_api}/api/user/storageUsage`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get("access_token")}`,
    },
  }).then((res) => {
    dispatch({
      type: "userStorageUsageSuccess",
      payload: res.data,
    });
  }).catch((err) => {
    dispatch({
      type: "getUserStorageUsageFail",
      payload: err.response.data.message || "Something went wrong",
    });
    if(err.response.status === 401) redirectToLogin();
  });
};

export const downloadDeleted = (CustomerId, setDownloading) => async (dispatch) => {
  dispatch({
    type: "downloadDeletedRequest",
  });
  setDownloading(true);
  await axios.get(`${backend_api}/api/user/downloadDeleted/${CustomerId}`, {
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${Cookies.get("access_token")}`,
    },
    responseType: "blob",
  }).then((res) => {
    const blob = new Blob([res.data], { type: "application/zip" });
    saveAs(blob, "download.zip");
    dispatch({
      type: "downloadDeletedSuccess",
      payload: "Downloaded Successfully",
    });
    toast.success("Zip Created Successfully");
  }).catch((err) => {
    toast.error(err.response.data.message || "Something went wrong");
    dispatch({
      type: "downloadDeletedFail",
      payload: err.response.data.message || "Something went wrong",
    });
    if(err.response.status === 401) redirectToLogin();
  }).finally(() => {
    setDownloading(false);
  });
};

export const UserUpdateCustomerStatus = (id, status) => async (dispatch) => {
  dispatch({
    type: "userUpdateCustomerStatusRequest",
  });
  await axios.patch(`${backend_api}/api/user/updateCustomerStatus/${id}`,
    {
      status: status ? "active" : "inactive",
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("access_token")}`,
      },
    }
  ).then((res) => {
    toast.success("Client Status Updated Successfully");
    dispatch({
      type: "userUpdateCustomerStatusSuccess",
      payload: res.data,
    });
  }).catch((err) => {
    toast.error(err.response.data.message || "Something went wrong");
    dispatch({
      type: "userUpdateCustomerStatusFail",
      payload: err.response.data.message || "Something went wrong",
    });
    if(err.response.status === 401) redirectToLogin();
  });
};

export const UserDeleteCustomer = (id, navigate) => async (dispatch) => {
  dispatch({
    type: "deleteCustomerRequest",
  });
  await axios.delete(`${backend_api}/api/user/deleteCustomer/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("access_token")}`,
      },
    }
  ).then((res) => {
    toast.success("Client Deleted Successfully");
    dispatch({
      type: "deleteCustomerSuccess",
      payload: res.data,
    });
    navigate("/clients");
  }).catch((err) => {
    toast.error(err.response.data.message || "Something went wrong");
    dispatch({
      type: "deleteCustomerFail",
      payload: err.response.data.message || "Something went wrong",
    });
    if(err.response.status === 401) redirectToLogin();
  });
};

export const cancelSubscription = () => async (dispatch) => {
  await axios.delete(`${backend_api}/api/user/cancelSubscription`, {
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${Cookies.get("access_token")}`,
    },
  }).then((res) => {
    toast.success(res.data?.message);
  }).catch((err) => {
    if(err.response.status === 401) redirectToLogin();
    return toast.error(err.response.data.message || "Something went wrong");
  });
}

export const uploadFile = (CustomerId, files, rootFolder, increaseUploadingLoadingCounter, decreaseUploadingLoadingCounter) => async (dispatch) => {
  let uploadInProgress = true; // Flag to track if uploading is in progress
  const successfullyupload=[]
  
  
  const filesArray = Array.from(files);
  const filesData = filesArray.map((file, index) => ({ index: index, name: file.name, mimeType: file.type, size: file.size }));
  
  const uploading = async (event) => {
    if (uploadInProgress) {
      const message = "You have files uploading. Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    }
  };

  // Add the event listener
  window.addEventListener('beforeunload', uploading);
  
  dispatch({
    type: "uploadFileRequest",
  });

  const token = Cookies.get("access_token");
  increaseUploadingLoadingCounter();
  try {
    const res = await axios.post(
      `${backend_api}/api/user/generateMultipleSignedUrls/${CustomerId}/${rootFolder}`,
      { files: filesData },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    );

    const uploadFiles = res.data.uploadFiles;
    

    const uploadTasks = filesArray.map((file, index) => {
      return () => dispatch(uploadFileToS3(file, uploadFiles[index],successfullyupload));
    });

    const uploadPromises = uploadTasks.map(task => uploadQueue.enqueue(task));

    await Promise.all(uploadPromises);

    toast.success("Files Uploaded successfully");
  } catch (err) {
    toast.error(err.response?.data?.message || "Error uploading files");

    dispatch({
      type: "uploadFileFail",
      payload: err.response?.data?.message || "Something went wrong",
    });

    if (err.response?.status === 401) {
      redirectToLogin();
    }
  } finally {
    // Remove the event listener
    
    socketService.emit("markFilesAsUploaded", successfullyupload);
    window.removeEventListener('beforeunload', uploading);
    // Reset the flag once uploading is complete
    uploadInProgress = false;
    
    dispatch({
      type: "uploadFileSuccess",
      payload: 'success',
    });

    decreaseUploadingLoadingCounter();
  }
};

export const uploadFolder = (CustomerId, folderFiles, rootFolder, increaseUploadingLoadingCounter, decreaseUploadingLoadingCounter) => async (dispatch) => {
  let uploadInProgress = true; // Flag to track if uploading is in progress
  const successfullyupload=[]

  const folderFilesData = Object.entries(folderFiles).map(([folder, files]) => ({
    folder,
    files: files.map((file, index) => ({ index, name: file.name, mimeType: file.type, size: file.size })),
  }));

  const uploading = async (event) => {
    if (uploadInProgress) {
      const message = "You have files uploading. Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    }
  };

  // Add the event listener
  window.addEventListener('beforeunload', uploading);

  dispatch({
    type: "uploadFileRequest",
  });
  const token = Cookies.get("access_token");

  increaseUploadingLoadingCounter();
  try {
    const res = await axios.post(`${backend_api}/api/user/createFoldersAndGenerateSignedUrls/${CustomerId}/${rootFolder}`, { folderFiles: folderFilesData },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    );
      console.log(1);
    const uploadFiles = res.data.uploadFiles;
    for (const folder of Object.keys(folderFiles)) {
      const files = folderFiles[folder];
      const uploadUrls = await uploadFiles.filter((file) => file.folder === folder);

      const uploadTasks = await files.map((file, index) => {
          return () => dispatch(uploadFileToS3(file, uploadUrls[index],successfullyupload));
      });

      const uploadPromises = await uploadTasks.map(task => uploadQueue.enqueue(task));

      await Promise.all(uploadPromises);
    }
    toast.success("Folder Uploaded successfully");
  } catch (err) {
    toast.error(err.response?.data?.message || "Error uploading files");
  } finally {
    // Remove the event listener
      socketService.emit("markFilesAsUploaded", successfullyupload);
    window.removeEventListener('beforeunload', uploading);
    // Reset the flag once uploading is complete
    uploadInProgress = false;
    
    dispatch({
      type: "uploadFileSuccess",
      payload: 'success',
    });

    decreaseUploadingLoadingCounter();
  }
};

// Functions

// Define a queue to control uploads
class UploadQueue {
  constructor(maxConcurrent) {
      this.maxConcurrent = maxConcurrent;
      this.currentlyUploading = 0;
      this.queue = [];
  }

  enqueue(uploadTask) {
      return new Promise((resolve, reject) => {
          this.queue.push({ uploadTask, resolve, reject });
          this.processQueue();
      });
  }

  async processQueue() {
      if (this.currentlyUploading < this.maxConcurrent && this.queue.length) {
          const { uploadTask, resolve, reject } = this.queue.shift();
          this.currentlyUploading++;
          try {
              const result = await uploadTask();
              resolve(result);
          } catch (error) {
              reject(error);
          } finally {
              this.currentlyUploading--;
              this.processQueue();
          }
      }
  }
}

const uploadQueue = new UploadQueue(5);

const uploadFileToS3 = (file, fileData, successfullyupload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let progress = 0;
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", fileData.uploadUrl);
    xhr.setRequestHeader("Content-Type", "multipart/form-data");
    xhr.upload.onprogress = (event) => {
      progress = Math.round((100 * event.loaded) / event.total);
      dispatch({
        type: "progressUpdateSuccess",
        payload: { id: fileData.id, name: fileData.name, progress, status: "uploading" },
      });
    };
    xhr.onload = async () => {
      if (xhr.status === 200) {
        successfullyupload.push(fileData)
        if(successfullyupload.length===10){
          socketService.emit("markFilesAsUploaded", successfullyupload);
          successfullyupload.splice(0, 10)
        }
        // socketService.emit("markFileAsUploaded", fileData);
        dispatch({
          type: "progressUpdateSuccess",
          payload: { id: fileData.id, name: fileData.name, progress, status: "success" },
        });
        resolve("success");
      } else {
        dispatch({
          type: "progressUpdateSuccess",
          payload: { id: fileData.id, name: fileData.name, progress, status: "failed" },
        });
        reject("failed");
      }
    };
    xhr.ontimeout = xhr.onabort = () => {
      dispatch({
        type: "progressUpdateSuccess",
        payload: { id: fileData.id, name: fileData.name, progress, status: "failed" },
      });
      toast.error(`${fileData.name} failed to upload due to internet issue`);
      reject("failed");
    };
    xhr.onerror = () => {
      dispatch({
        type: "progressUpdateSuccess",
        payload: { id: fileData.id, name: fileData.name, progress, status: "failed" },
      });
      toast.error(`Failed to upload ${fileData.name}`);
      reject("failed");
    };
    xhr.send(file);
  });
}