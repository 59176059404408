import React from "react";
// import "./assets/css/custom.css";
// import "./assets/css/main.css";

const ContactPage = ({url}) => {
    const hostedUrl = `${url}/static`;
    const staticHTML = `
    <body>
        <div class="navbar w-nav" id="myHeader">
            <div class="container w-container">
                <div class="grid-navbar">
                    <a href="/" 
                        class="brand w-nav-brand" aria-label="home">
                        <img
                            src="${hostedUrl}/assets/img/GSPro-Logo.svg"
                            loading="eager" alt="" class="logo"></a>
                            <nav role="navigation" 
                            class="nav-menu w-nav-menu">
                            <a href="/" aria-current="page"
                            class="nav-link w-nav-link" >Home</a>
                            <a href="/about" aria-current="page" class="nav-link w-nav-link" >About</a>
                            <a href="/features" class="nav-link w-nav-link" >Features</a>
                            <a href="/pricings" class="nav-link w-nav-link" >Pricing</a>
                            <a href="/contact" class="nav-link w-nav-link" >Contact</a>
                            <a href="/login" class="nav-link m-nav-link">Login</a>
                        </nav>
                    <div class="navbar-right nav-hide">
                        <a href="/login" class="nav-link">Login</a>
                        <a data-w-id="f3952b50-6d1f-393e-b11f-b676ceacc841" href="/registration"
                            class="button-link-icon nav-button-hide w-inline-block">
                            <div>
                                <input type="submit" value="Try For Free" data-wait="Please wait..." class="button-primary-1 button-subscribe w-button">
                            </div>
                        </a>
                    </div>
                    <div  class="menu-button w-nav-button">
                        <div class="w-icon-nav-menu"></div>
                    </div>
                </div>
            </div>
            <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
        </div>
        <section class="hero-section">
            <div class="hero-section-decoration innerpage">
                <div class="container-medium w-container">
                    <div class="hero-inner-content-wrap gs_reveal">
                        <div class="hero-inner-content">
                            <div class="pre-title-inline">
                                <img src="${hostedUrl}/assets/img/star.svg" loading="lazy" alt="" class="pre-title-image">
                                <div>Contact</div>
                            </div>
                            <h1>Contact Us</h1>
                            <p class="benefit-content">
                                Queries, Feedback & Information
                            </p>
                        </div>
                    </div>
            </div>
            <div class="container-medium w-container gs_reveal">
            <div class="w-col w-layout-grid grid-benefit-two contact-from">
                <div class="formLeft">
                    <img src="${hostedUrl}/assets/img/FavIcon.png" alt="" />
                </div>
                <div style="text-align: left;">
            <form id="wf-form-Contact-Form" name="wf-form-Contact-Form"  method="get">
            <div class="input-group"><label for="Name">Name</label><input type="text" class="form-input w-input" maxlength="256"
                    name="Name" data-name="Name" placeholder="" id="Name"></div>
            <div class="input-group"><label for="Email">Email</label><input type="email" class="form-input w-input"
                    maxlength="256" name="Email" data-name="Email" placeholder="" id="Email" required=""></div>
            <div class="input-group"><label for="Phone">Phone</label><input type="tel" class="form-input w-input"
                    maxlength="256" name="Phone" data-name="Phone" placeholder="" id="Phone"></div>
            <div class="input-group"><label for="Message">Message</label><textarea placeholder="" maxlength="5000" id="Message"
                    name="Message" data-name="Message" class="form-input form-textarea w-input"></textarea></div><input
                type="submit" value="Send message" data-wait="Please wait..." class="button-primary-2 w-button">
            </form>
                </div>
            </div>
        </div>
            </div>

        </section>


    <section class="getstarted">
        <div class="container w-container">
            <div class="section-title text-center m-b-0 gs_reveal">
                <img src="${hostedUrl}/assets/img/FavIcon.png" class="getstartedLogo"/>
                <h2 class="no-margin">Get Started Today</h2>
                <div class="w-flex w-col-8 text-center xy-auto m-t-10p text-primary-2">
                    <div class="w-col-4 w-flex w-getstarted-checks">
                        <img src="${hostedUrl}/assets/img/Check.svg" />
                        <span class="f18">Free & Paid Plans</span>
                    </div>
                    <div class="w-col-4 w-flex w-getstarted-checks">
                        <img src="${hostedUrl}/assets/img/Check.svg" />
                        <span class="f18">Easy Payment Options</span>
                    </div>
                    <div class="w-col-4 w-flex w-getstarted-checks">
                        <img src="${hostedUrl}/assets/img/Check.svg" />
                        <span class="f18">Cancel Anytime</span>
                    </div>
                </div>
                <div class="topbannerLogo">
                    <input type="submit" value="Try GSPRO For Free" data-wait="Please wait..." class="button-primary-1 button-subscribe w-button m-t-10p">
                </div>
                <div class="getstartedimage">
                    <img src="${hostedUrl}/assets/img/Footerimage.png" />
                </div>
            </div>
        </div>
    </section>
        <footer class="footer">
            <div class="container w-container">
                <div class="w-layout-grid grid-footer gs_reveal">
                    <div>
                        <a href="/"
                            aria-current="page" class="footer-logo-wrap w-inline-block w--current">
                            <img src="${hostedUrl}/assets/img/FavIcon.png" loading="eager" alt="" class="footer-logo-wrap">
                        </a>
                        <p class="no-margin blacktext">Securely Organize, Showcase,and Collaborate 
                            On your Media Assets with <b>Gallery Studio pro</b></p>
                    </div>
                    <div class="footer-item-wrap">
                        <h6 class="footer-title">Pages</h6>
                        <div class="w-layout-grid grid-footer-link">
                            <div class="footer-link-wrap">
                                <a href="/" aria-current="page"
                                class="footer-link w--current">Home</a>
                                <a href="/about" aria-current="page"
                                class="footer-link w--current">About</a>
                                <a href="/features" aria-current="page"
                                class="footer-link w--current">Features</a>
                                <a href="/pricings" aria-current="page"
                                class="footer-link w--current">Pricing</a>
                                <a href="/contact" aria-current="page"
                                class="footer-link w--current">Contact</a>
                            </div>
                        </div>
                    </div>
                    <div class="footer-item-wrap">
                        <h6 class="footer-title">Utility pages</h6>
                        <div class="w-layout-grid grid-footer-link">
                            <div class="footer-link-wrap">
                                <a href="privacy" class="footer-link"> Privacy</a>
                                <a href="terms" class="footer-link">Terms</a>
                                <a href="security" class="footer-link">Security</a>
                            </div>
                        </div>
                    </div>
                    <div  class="footer-item-wrap">
                        <h6 class="footer-title">Contact Us</h6>
                        <div class="grid-footer-link"><a href="mailto:info@gallerystudiopro.com"
                                class="footer-link">info@gallerystudiopro.com</a>
                            <div class="footer-social-wrap">
                                <a href="" target="_blank"
                                    class="footer-social-link  w-inline-block"><img
                                        src="${hostedUrl}/assets/img/fb.svg"
                                        loading="eager" alt="" class="footer-social-icon"></a>
                                        <a href=""
                                    target="_blank" class="footer-social-link w-inline-block"><img
                                        src="${hostedUrl}/assets/img/insta.svg"
                                        loading="eager" alt="" class="footer-social-icon"></a>
                                        <a
                                    href="" target="_blank"
                                    class="footer-social-link w-inline-block"><img
                                        src="${hostedUrl}/assets/img/youtube.svg"
                                        loading="eager" alt="" class="footer-social-icon"></a>
                                    
                                    </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom text-center ">
                    <div class="xy-auto"> Powered by <a href=""
                            target="_blank" class="footer-link text-primary-2">The Smart Trunk</a></div>
                </div>
            </div>
        </footer>
    </body>
    `;
    return <div dangerouslySetInnerHTML={{ __html: staticHTML }} />;
}

export default ContactPage;