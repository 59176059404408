import React from "react";
import "./loginStyles.css";
import logo from "../../assets/images/GSPro-Logo.svg";
import BackButton from "../../components/BackButton";
import loginImg from "../../assets/images/LoginAvatar.svg";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { backend_api } from "../../redux/store";
import { useNavigate } from 'react-router-dom';


const NewPassword = () => {
  const navigate = useNavigate();
  const { id, token } = useParams();
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);

  const resetPassword = async () => {
    if(password.length < 6) return setPasswordError(true);
    else setPasswordError(false);
    if(password !== confirmPassword) return setConfirmPasswordError(true);
    else setConfirmPasswordError(false);

    await axios.post(`${backend_api}/api/resetPassword`, {
      userId: id,
      token: token,
      password: password,
    }).then((res) => {
        setTimeout(() => {
          navigate('/home');
        }, 3000);
        return toast.success(res.data.message);
    }).catch((err) => {
      return toast.error(err.response.data.message || "Something went wrong");
    });
  }

  return (
    <div className="login-page">
      <div className="login-page-inner-container container-left">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <h1 id="title-text">Hi Professional, Welcome </h1>
          <h1 id="title-text">to Gallery Studio Pro</h1>
        </div>
        <p id="subtitle-text">
          Explore the app, Upload your client image and share with them for approval workflows
        </p>
        <img src={loginImg} alt="login" id="login-img" />
      </div>
      <div className="login-page-inner-container container-right">
        <div className="login-page-form-container">
          <div className="login-page-form-container-top">
            {/* <BackButton /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img src={logo} alt="google" id="logo" style={{height: '3rem'}} />
            </div>
          </div>
          <div className="login-page-form-container-middle">
            <h1 id="welcome-back">Reset Password</h1>
            <input type="password" placeholder="New Password" className="login-form-input" onChange={(e) => setPassword(e.target.value)} />
            {passwordError && (
              <span style={{ color: "red" }}>
                Password should be atleast 6 characters long
              </span>
            )}
            <input type="password" placeholder="Re-type Password" className="login-form-input" onChange={(e) => setConfirmPassword(e.target.value)} />
            {confirmPasswordError && (
              <span style={{ color: "red" }}>
                Passwords should match
              </span>
            )}
            <button className="login-btn main-btn" onClick={resetPassword}> Reset Password </button>
          </div>
          <div className="login-page-form-container-bottom">
            <Link
              to="/login"
              style={{
                textDecoration: "none",
              }}
            >
              <p id="continue-with-email">Already have an account? Sign in</p>
            </Link>
            <div id="line" />
            <p className="poweredBy">Powered By : GalleryStudioPro</p>
          </div>
        </div>
      </div>
      <div id="bottom-div"></div>
    </div>
  );
};

export default NewPassword;
