import React, { useContext, useState } from "react";
import "./formstyles.css";
import { useSelector, useDispatch } from "react-redux";
import { createNewClient, getCostumers } from "../../redux/actions/userAction";
import toast from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingContext } from "../../pages/Home/ContextProvider";
import { setAddClientOption } from "../../redux/actions/sidebarAction";


const AddClientForm = ({ }) => {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Username: '',
    Password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.Name || !formData.Email || !formData.Password || !formData.Username) {
      toast.error("Please fill all the fields");
      return;
    }
    if (!emailRegex.test(formData.Email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    setIsDisabled(true);
    increaseLoadingCounter();
    dispatch(createNewClient(formData.Name, formData.Email, formData.Password, formData.Username, setIsDisabled)).then(() => {
      dispatch(setAddClientOption(false));
      dispatch(getCostumers()).then(()=>{
        decreaseLoadingCounter();
      })
    });
  };

  const clientForm = {
    margin: "25px auto",
  };

  return (
    <div className="clientForm" style={clientForm}>
      <h1 id="form-title">
      Create New Client</h1>
      <div className="form-section">
        <p id="form-section-title">Client Details</p>
        <input
          id="form-input"
          type="text"
          placeholder="Client Name"
          name="Name"
          value={formData.Name}
          onChange={handleChange}
        />
        <input
          id="form-input"
          type="email"
          placeholder="Client Email"
          name="Email"
          value={formData.Email}
          onChange={handleChange}
        />
      </div>
      <div className="form-section">
        <p id="form-section-title"><b>Client Portal</b> - Login Details</p>
        <input
          id="form-input"
          type="text"
          placeholder="Username"
          name="Username"
          value={formData.Username}
          onChange={handleChange}
        />
        <input
          id="form-input"
          type="password"
          placeholder="Password"
          name="Password"
          value={formData.Password}
          onChange={handleChange}
        />
      </div>
      <button id="form-action-btn" disabled={isDisabled} onClick={handleSubmit}>
        {
          isDisabled ?
            <ClipLoader
              color={'#fff'}
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            :
            <>
              <img
                width="15"
                height="15"
                src="https://img.icons8.com/ios-glyphs/30/FFFFFF/plus-math.png"
                alt="plus-math"
              />
              <p style={{ margin: 0 }}>Add New Client</p>
            </>
        }
      </button>
    </div>
  );
};

export default AddClientForm;

