import React, { useContext, useEffect, useState } from "react";
import "./homeStyles.css";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import bgImg from "../../assets/images/homebg.png";
import bgImg2 from "../../assets/images/homebg2.png";
import AddClientForm from "../../components/AddClientForm/AddClientForm";
import Clients from "../Clients/Clients";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";
import {
  getUserDetails,
  getUserStorageUsage,
  cancelSubscription,
} from "../../redux/actions/userAction";
import moment from "moment";
import socketService from "../../socket";
import Cookies from "js-cookie";
import { LoadingContext } from "./ContextProvider";

const Profile = () => {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const { user, user_storage_usage } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [addClient, setAddClient] = useState(false);
  const [progress, setProgress] = React.useState(20);
  const [totalStorage, setTotalStorage] = useState(0);
  const [storageUsage, setStorageUsage] = useState(0);

  const convertBytesToGB = (bytes) => {
    return (bytes / 1024 / 1024 / 1024).toFixed(2);
  };

  const calculatePercentage = (used, total) => {
    return (used / total) * 100;
  };

  useEffect(() => {
    socketService.on("storageUpdate", (data) => {
      setStorageUsage(convertBytesToGB(data.storageUsed));
    });
    increaseLoadingCounter();
    dispatch(getUserStorageUsage()).then(() => {
      decreaseLoadingCounter();
    });
    // return () => {
    //   socketService.off("storageUpdate");
    // };
  }, []);

  useEffect(() => {
    if (user_storage_usage) {
      setTotalStorage(convertBytesToGB(user_storage_usage?.totalStorage));
      setStorageUsage(convertBytesToGB(user_storage_usage?.storageUsed));
    }
  }, [user_storage_usage]);

  useEffect(() => {
    increaseLoadingCounter();
    dispatch(getUserDetails()).then(() => {
      decreaseLoadingCounter();
    });
  }, []);



  const [openCancelModel, setopenCancelModel] = useState(false)

  const styles = {
    box: {
      width: "90%",
      borderRadius: "2px",
      borderWidth: "1px",
      borderColor: "rgba(0,0,0,.1)",
      height: "60px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderStyle: "solid",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  };
  const CancelSubscriptionModel = () => (
    <div className="image-gallery-modal">
      <Modal
        open={openCancelModel}
        onClose={() => setopenCancelModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div id="image-gallery-delete-warning-modal-container">
          <p id="image-gallery-delete-warning-modal-text">
            Are you sure you want Cancel your Subscription
          </p>
          <div id="image-gallery-delete-warning-modal-btn-container">
            <button
              id="image-gallery-delete-warning-modal-btn-cancel"
              className="image-gallery-delete-warning-modal-btn"
              onClick={() => setopenCancelModel(false)}
            >
              No
            </button>
            <button
              id="image-gallery-delete-warning-modal-btn-delete"
              className="image-gallery-delete-warning-modal-btn"
              onClick={() => {
                increaseLoadingCounter();
                dispatch(cancelSubscription()).then(() => {
                  setopenCancelModel(false);
                  decreaseLoadingCounter()
                })
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );

  return (
    <div className="home-page">
      <Header />
      <Sidebar />
      <CancelSubscriptionModel />
      <div id="sidebar-placeholder"></div>
      <div className="home-main-container">
        <div style={{ display: "flex", width: "100%" }}>
          <h1 style={{ alignSelf: "center", marginLeft: "40%" }}>Profile</h1>
          <Button onClick={() => {
            Cookies.remove("access_token");
            Cookies.remove("userId");
            Cookies.remove("client_access_token");
            window.location.reload();
          }} style={{ marginLeft: "auto" }}>Logout</Button>
        </div>
        <div style={{ ...styles.box, marginTop: "10px" }}>
          <PersonIcon style={{ color: "#3F414E" }} />{" "}
          <p
            style={{ fontSize: "20px", color: "#3F414E", fontFamily: "Nunito" }}
          >
            {user?.name}
          </p>
          <p
            style={{
              fontSize: "20px",
              color: "#3F414E",
              fontFamily: "Nunito",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            |
          </p>
          <p
            style={{
              color: "#3F414E",
              fontFamily: "Nunito",
              marginRight: "auto",
            }}
          >
            {user?.email}
          </p>
          <Button
            style={{
              backgroundColor: "#FA6E5A",
              color: "white",
              borderRadius: "20px",
              width: "100px",
            }}
          >
            {user?.subscriptionType}
          </Button>
          <Button
            style={{
              color: "green",
              borderRadius: "20px",
              width: "80px",
              marginLeft: "20px",
              marginRight: "20px",
              textTransform: "capitalize",
            }}
          >
            {user?.status}
          </Button>
        </div>
        <div
          style={{
            ...styles.box,
            backgroundColor: "#fafafa",
            borderTopWidth: "0px",
          }}
        >
          <p
            style={{
              color: "#3F414E",
              fontWeight: "normal",
              fontFamily: "Nunito",
            }}
          >
            Created Date : {moment(user?.createdAt).format("DD MMM YYYY")}
          </p>
        </div>
        <div style={{ ...styles.box, marginTop: "50px" }}>
          <p
            style={{
              fontSize: "18px",
              color: "#3F414E",
              marginRight: "auto",
              fontFamily: "Nunito",
            }}
          >
            File & Share
          </p>
          <Button
            style={{
              color: "black",
              borderRadius: "20px",
              width: "80px",
              fontFamily: "Nunito",
            }}
          >
            <FileCopyOutlinedIcon style={{ color: "#00000080" }} />
            {user?.totalFiles}
          </Button>
          <Button
            style={{
              color: "black",
              borderRadius: "20px",
              width: "80px",
              marginLeft: "20px",
              marginRight: "20px",
              fontFamily: "Nunito",
            }}
          >
            <FolderOpenIcon style={{ color: "#00000080" }} />
            {user?.totalFolders}
          </Button>
        </div>
        <div
          style={{
            ...styles.box,
            backgroundColor: "#fafafa",
            borderTopWidth: "0px",
          }}
        >
          <p
            style={{
              color: "#3F414E",
              fontWeight: "normal",
              fontFamily: "Nunito",
            }}
          >
            {storageUsage} GB of {totalStorage} GB used
          </p>
          <Box sx={{ width: "20%", marginRight: "auto", marginLeft: "20px" }}>
            <LinearProgress
              variant="determinate"
              value={calculatePercentage(storageUsage, totalStorage)}
            />
          </Box>
        </div>
        <Button
          style={{
            borderColor: "#FA6E5A",
            borderStyle: "solid",
            borderRadius: "20px",
            borderWidth: "1px",
            backgroundColor: "white",
            color: "#FA6E5A",
            fontSize: "15px",
            marginTop: "30px",
            marginLeft: "auto",
            marginRight: "50px",
            paddingLeft: "20px",
            paddingRight: "20px",
            fontFamily: "Nunito",
          }}
          onClick={() => { setopenCancelModel(true) }}
        >
          Cancel Subscription
        </Button>
      </div>
    </div>
  );

};



export default Profile;
