import React, { useContext, useState } from "react";
import Header from "./Header";
import DefaultClientBackground from "../../assets/images/DefaultClientBackground.png";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getClientDetails,
  getClientFilesFolders,
  getClientTrashFilesFolders,
  getFirstFile
} from "../../redux/actions/clientActions";
import { useLocation, useNavigate } from "react-router-dom";
import ImageGallery from "../../components/ImageGallery/ImageGallery";
import comments from "../../assets/images/comments.png";
import viewall from "../../assets/images/viewall.png";
import important from "../../assets/images/important.png";
import approved from "../../assets/images/approved.png";
import { LoadingContext } from "../Home/ContextProvider";
import logo from "../../assets/images/GSPro-Logo.svg";
import noFiles from "../../assets/images/emptyfiles.png";
import "./clientDetailsStyles.css";

const ClientDashboard = () => {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const { firstFile, clientFilesFolders, clientDetails, clienttrashFilesFolders, fileToTrash, restoreFiles, clientApproveStatus, clientImportantStatus, clientComment, deleteFiles } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [DeletedFileArray, setDeletedFileArray] = useState()
  const [RootFolder, setrootFolder] = useState()
  const [Path, setPath] = useState([]);
  const [activeTab, setActiveTab] = useState("files");

  useEffect(() => {
    handleGetFirstFile();
    handleGetClientDetails();
  }, []);

  useEffect(() => {
    handleGetCurrentClientFolder();
  }, [RootFolder, clientApproveStatus, clientImportantStatus, clientComment, fileToTrash, restoreFiles]);

  useEffect(() => {
    handleGetClientTrashFolder();
  }, [fileToTrash, restoreFiles, deleteFiles]);

  const handleGetFirstFile = () => {
    increaseLoadingCounter();
    dispatch(getFirstFile()).then(() => {
      decreaseLoadingCounter();
    });
  }

  const handleGetClientDetails = () => {
    increaseLoadingCounter();
    dispatch(getClientDetails()).then(() => {
      decreaseLoadingCounter();
    });
  }

  const handleGetCurrentClientFolder = () => {
    if (RootFolder) {
      increaseLoadingCounter();
      dispatch(getClientFilesFolders(RootFolder)).then(() => {
        decreaseLoadingCounter();
      });
    }
  }

  const handleGetClientTrashFolder = () => {
    increaseLoadingCounter();
    dispatch(getClientTrashFilesFolders()).then(() => {
      decreaseLoadingCounter();
    });
  }

  useEffect(() => {
    setDeletedFileArray(clienttrashFilesFolders?.files)
  }, [clienttrashFilesFolders]);

  useEffect(() => {
    setrootFolder(clientDetails?.rootFolder)
    setPath([{
      name: 'rootFolder',
      id: clientDetails?.rootFolder,
    }])
  }, [clientDetails]);

  const [ImageArray, setImagesArray] = useState(clientFilesFolders?.files)
  useEffect(() => {
    setImagesArray(clientFilesFolders?.files)
  }, [clientFilesFolders]);

  const FolderButtons = ({ folder, openFolder }) => {
    const handleButtonClick = () => {
      if (!openFolder) return;
      // navigate(`/Clientdashboard/folder?folder=${folder?._id}`);
      setrootFolder(folder?._id)
      const newData = {
        name: folder.name,
        id: folder._id,
      };
      // Check if item._id already exists in the Path array
      const isIdPresent = Path.some(pathData => pathData.id === folder._id);
      if (!isIdPresent) {
        // If item._id is not present, add newData to the Path array
        setPath([...Path, newData]);
      }
    }
    return (
      <Button
        style={{
          textTransform: "capitalize",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          padding: "25px",
          paddingLeft: "35px",
          paddingRight: "35px",
          marginRight: "15px",
          height: "150px",
          width: "150px",
          border:"1px solid #e9e9e9",
          borderRadius:"10px",
          boxShadow:"0 1px 10px 0 rgba(145,145,145,0.2)"
        }}
        onClick={handleButtonClick}
      >
        <img
          src={require("../../assets/images/foldericon.png")}
          style={{ height: "50px", width: "50px" }}
          alt="Folder"
        />
        <p style={{ color: "#3F414E", fontFamily: "Nunito" }}>{folder?.name}</p>
      </Button>
    );
  };

  const ClientTabs = ({ activeTab, setActiveTab }) => {
    // useEffect(()=> {
    //   if(activeTab === "details") dispatch(getCustumerDetails(urlParams.get("client-details")));
    // }, [activeTab])
    return (
      <div className="client-tabs">

        <div
          className={`client-tab${activeTab === "files" ? "-active" : ""}`}
          onClick={() => {
            setActiveTab("files");
          }}
          style={{ paddingBottom: '10px'}}
        >
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/plumpy/96/folder-invoices--v1.png"
            alt="folder-invoices--v1"
          />
          <p className="client-tab-text" style={{ marginBottom: '0px' }}>Files & Folders</p>
        </div>
        <div
          className={`client-tab${activeTab === "trash" ? "-active" : ""}`}
          onClick={() => {
            setActiveTab("trash");
          }}
          style={{ paddingBottom: '10px'}}
        >
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/plumpy/96/trash--v1.png"
            alt="folder-invoices--v1"
          />
          <p className="client-tab-text" style={{ marginBottom: '0px' }}>Trash</p>
        </div>
      </div>
    );
  };

  const ImageGalleryHeader = () => (
    <div id="image-gallery-header" >
      <div className="image-gallery-header-breadcrumbs">
        {Path.length > 1 && <img
          src="https://img.icons8.com/material-sharp/50/left.png"
          alt="go-back"
          id="client-back-btn"
          onClick={() => {
            if (Path.length === 2) {
              setrootFolder(clientDetails?.rootFolder)
            } else {
              setrootFolder(Path[Path.length - 2]?.id);
            }
            Path.pop(); // Remove the last element
            setPath([...Path]);
          }}
        />}
        <img
          src="https://img.icons8.com/small/200/3F414E/folder-invoices--v1.png"
          alt="folder-invoices--v1"
          id="image-gallery-header-breadcrumbs-img"
        />
        {Path?.map((item, index) => (

          index > 0 && index > Path.length - 4 ? (
            <button onClick={() => {
              setrootFolder(item.id)
              const arrayOfObjects = Path
              if (index !== -1) {
                arrayOfObjects.splice(index + 1);
              }
              setPath(arrayOfObjects);
            }} key={index} style={{ display: "flex" }}>
              <img
                src="https://img.icons8.com/fluency-systems-filled/48/3F414E/right.png"
                alt="right"
                id="image-gallery-header-breadcrumbs-img"
              />
              <p id="image-gallery-header-breadcrumbs-text">{item.name}</p>
            </button>
          ) : null
        ))}
      </div>
      <div className="image-gallery-header-options ">
        <div
          onClick={() => {
            setImagesArray(clientFilesFolders?.files.filter((file) => file.comments.length > 0));
          }}
          className="image-gallery-header-options-item"
        >
          <img width="30" height="30" src={comments} alt="comments" />
          <p id="image-gallery-header-options-item-text">Comments</p>
        </div>
        <div
          onClick={() => {
            setImagesArray(
              clientFilesFolders?.files.filter((file) => file.isApproved === true)
            );
          }}
          className="image-gallery-header-options-item"
        >
          <img width="30" height="30" src={approved} alt="approved" />
          <p id="image-gallery-header-options-item-text">Approved</p>
        </div>
        <div
          onClick={() => {
            setImagesArray(
              clientFilesFolders?.files.filter((file) => file.isImportant === true)
            );
          }}
          className="image-gallery-header-options-item"
        >
          <img width="30" height="30" src={important} alt="important" />
          <p id="image-gallery-header-options-item-text">Important</p>
        </div>
        <div
          onClick={() => {
            setImagesArray(clientFilesFolders?.files);
          }}
          className="image-gallery-header-options-item"
        >
          <img width="30" height="30" src={viewall} alt="view all" />
          <p id="image-gallery-header-options-item-text">View All</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="client-portal-main">
    <div className="client-portal-theme theme1">
      <Header />
      <div className="client-banner"
        style={{
          backgroundImage: `url(${firstFile?.url ? encodeURI(firstFile?.url) : DefaultClientBackground})`,
        }}
      >
        <div className="client-banner-overlay"></div>
        <span className="client-banner-text1">
          Welcome!
        </span>
        <span className="client-banner-text2">
          {clientDetails?.name}
        </span>
      </div>

      <ClientTabs activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab == 'files' ?
        (<>
          <ImageGalleryHeader />
          {clientFilesFolders?.files.length > 0 || clientFilesFolders?.folders.length > 0 ? <div>
            {
              clientFilesFolders?.folders.length > 0 &&
              <span style={{ fontFamily: "Nunito", marginBottom:"10px", display:"block" }}>Folder(s) - {clientFilesFolders?.folders.length}</span>
            }
            <div 
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="client-side-folders" style={{ display: "flex", flexDirection: "row", flexWrap:"wrap" }}>
                {clientFilesFolders &&
                  clientFilesFolders?.folders.map((folder, index) => (
                    <FolderButtons index={index} folder={folder} openFolder={true} />
                  ))}
              </div>
              {clientFilesFolders && clientFilesFolders?.files.length > 0 && (
                <ImageGallery FilesArray={ImageArray} deletedImagesArray={null}
                  deletedFile={true} Custumer={true} rootFolder={RootFolder} />
              )}
            </div>
            {/* {
              (clienttrashFilesFolders?.folders.length > 0 || clienttrashFilesFolders?.files.length > 0) &&
              <>
                <h1 style={{ fontSize: '20px' }} id="client-files-folders-container-title">
                  Deleted Files / Folders
                </h1>

              </>
            } */}

          </div> :
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px' }}>
              <img draggable={false} src={noFiles} alt="no files found" id="no-files-img" />
            </div>
          }
        </>
        ) : (
          <>
            {clienttrashFilesFolders?.folders.length > 0 || clienttrashFilesFolders?.files.length > 0 ?
              <div>
                {
                  clienttrashFilesFolders?.folders.length > 0 &&
                  <span style={{ fontFamily: "Nunito" }}>Folder(s) - {clienttrashFilesFolders?.folders.length}</span>
                }
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  {clienttrashFilesFolders?.folders.length > 0 &&
                    <div style={{ display: "flex", flexDirection: "row", marginTop: "20px", }}>
                      {clienttrashFilesFolders?.folders.map((folder, index) => (
                        <FolderButtons index={index} folder={folder} openFolder={false} />
                      ))}
                    </div>
                  }
                  {clienttrashFilesFolders && clienttrashFilesFolders?.files.length > 0 && (
                    <ImageGallery FilesArray={null} deletedImagesArray={DeletedFileArray}
                      deletedFile={false} Custumer={true} rootFolder={RootFolder} />
                  )}
                </div>
              </div> :
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px' }}>
                <img draggable={false} src={noFiles} alt="no files found" id="no-files-img" />
              </div>
            }
          </>
        )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={logo}
          style={{ height: "3rem" }}
          alt="Logo"
        />
      </div>
    </div>
    </div>
  );
};

export default ClientDashboard;
