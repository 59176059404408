import React from "react";
import { Watch } from  'react-loader-spinner'

const LoaderScreen = ({show}) => {
    return(
        <div style={{ 
            position: 'fixed', 
            top: 0, 
            left: 0, 
            width: '100vw', 
            height: '100vh', 
            display: show ? 'flex' : 'none',
            justifyContent: 'center', 
            alignItems: 'center', 
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            pointerEvents: 'auto', 
            zIndex: 9999,
        }}>
            <Watch
                height="80"
                width="80"
                radius="48"
                color="#fff"
                ariaLabel="watch-loading"
                wrapperStyle={{ pointerEvents: 'none' }}
                wrapperClassName=""
                visible={true}
            />
        </div>
    );
};

export default LoaderScreen;