import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function
    () {

        const navigate=useNavigate()
    return (
        <div style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <img src={require('../../assets/images/Group6800.png')} style={{ height: '220px', width: '260px' }} alt="home background" />
            <span style={{ fontSize: '100px', color: '#3F414E', fontWeight: 'bold',fontFamily:'Nunito' }}>500</span>
            <span style={{ fontSize: '30px', color: '#E8505B', fontWeight: 'bold',fontFamily:'Nunito',marginTop:'5%' }}>Oops! This Page is Not Working.</span>
            <span style={{ fontSize: '20px', color: '#3F414E', fontWeight: 'bold',fontFamily:'Nunito',marginTop:'20px' }}>The requested is Internal Server Error.</span>
            <Button onClick={()=>navigate('/')} style={{backgroundColor:'#E8505B', padding:'10px', marginTop:'20px', borderRadius:'20px', paddingLeft:'35px',paddingRight:'35px'}}>
                <span style={{color:'#fff', fontWeight:'bold',fontFamily:'Nunito'}}>Go To Home page</span>
            </Button>
            <img src={require('../../assets/images/GSPro-Logo.png')} style={{ height: '50px', width: '260px', marginTop:'50px' }} alt="Logo" />
            
        </div>
    )
}
