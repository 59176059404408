import React, { useContext, useEffect, useState } from "react";
import "./imageGalleryStyles.css";
import comments from "../../assets/images/comments.png";
import viewall from "../../assets/images/viewall.png";
import important from "../../assets/images/important.png";
import approved from "../../assets/images/approved.png";
import { Checkbox, Modal, Pagination } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckBox, Delete, DeleteOutline, RestoreOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  deleteMultipleFilesFromTrash,
  moveMultipleFilesToTrash,
  restoremultiplefile
} from "../../redux/actions/userAction";
import ReactPlayer from 'react-player';
import HeadPhoneImage from "../../assets/images/headphone.png";
import AudioIcon from "../../assets/images/AudioIcon.png";
import VideoIcon from "../../assets/images/VideoIcon.png";
import { moveMultipleFilesToTrashCustomer } from "../../redux/actions/clientActions";
import { restoreclientmultiplefile } from "../../redux/actions/clientActions";
import ImageModal from "./ImageModal";
import { LoadingContext } from "../../pages/Home/ContextProvider";
import { deleteclientmultiplefile } from "../../redux/actions/clientActions";
import { Button } from "@mui/material";
import emptyFiles from '../../assets/images/emptyfiles.png'

const ImageGallery = ({
  FilesArray,
  deletedImagesArray,
  deletedFile,
  Custumer,
  rootFolder
}) => {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = window.location.pathname;
  // const data = location?.state?.data;

  const [selectFilesPressed, setSelectFilesPressed] = useState(false);
  const [selectDeletedFilesPressed, setSelectDeletedFilesPressed] =
    useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openTrashWarningModal, setOpenTrashWarningModal] = useState(false);
  const [openDeleteWarningModal, setOpenDeleteWarningModal] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [RestoreModal, setRestoreModal] = useState(false)
  const [RestoreButton, setRestoreButton] = useState(false)
  const [DeleteButton, setDeleteButton] = useState(false)

  const handleSelectFile = (item) => {
    setSelectedFiles((prev) => {
      if (prev?.includes(item)) {
        return prev?.filter((i) => i !== item);
      } else {
        return [...prev, item];
      }
    });
  };


  useEffect(() => {
    setSelectedFiles([]);
  }, [selectFilesPressed, selectDeletedFilesPressed]);

  const ImageItems = ({ item, index }) => {
    const [fileType, setFileType] = useState(item?.mimeType?.split("/")[0]);
    return (
      <div id="image-gallery-img-container" style={{ cursor: 'pointer', }}>
        {selectFilesPressed && (
          <div
            id="image-gallery-img-overlay"
            onClick={() => handleSelectFile(item?._id)}
          >
            {selectedFiles?.includes(item?._id) && (
              <CheckBox id="image-gallery-img-checkbox" />
            )}
          </div>
        )}
        {item?.comments?.length > 0 && (
          <img
            src={comments}
            alt="comments"
            id="image-gallery-comments-icon"
            className="image-gallery-img-icon"
          />
        )}
        <div className="image-gallery-img-icons-container">
          {item?.isApproved && (
            <img
              src={approved}
              alt="approved"
              id="image-gallery-approved-icon"
              className="image-gallery-img-icon"
            />
          )}
          {item?.isImportant && (
            <img
              src={important}
              alt="important"
              id="image-gallery-important-icon"
              className="image-gallery-img-icon"
            />
          )}
        </div>
        {
          fileType === "image" ?
            <img
              draggable={false}
              onClick={() => {
                if (
                  path === "/Clientdashboard" ||
                  path === "/ClientDashboard" ||
                  path === "/clientdashboard" ||
                  path === "/clientDashboard" ||
                  path === "/Clientdashboard/folder" ||
                  path === "/ClientDashboard/folder" ||
                  path === "/clientdashboard/folder" ||
                  path === "/clientDashboard/folder"
                ) {
                  navigate(`/Clientdashboard/image`, {
                    state: { ImageArray: FilesArray, index: index },
                  });
                } else {
                  navigate(`/dashboard`, {
                    state: { ImageArray: FilesArray, index: index },
                  });
                }
              }}
              // src={item?.url}
              src={item?.url}
              alt="gallery-item"
              id="image-gallery-img"
              style={{
                objectFit: "contain",
                height: "200px",
                width: "100%",
              }}
            />
            : fileType === "video" ?
              <div
                onClick={() => {
                  if (
                    path === "/Clientdashboard" ||
                    path === "/ClientDashboard" ||
                    path === "/clientdashboard" ||
                    path === "/clientDashboard" ||
                    path === "/Clientdashboard/folder" ||
                    path === "/ClientDashboard/folder" ||
                    path === "/clientdashboard/folder" ||
                    path === "/clientDashboard/folder"
                  ) {
                    navigate(`/Clientdashboard/image`, {
                      state: { ImageArray: FilesArray, index: index },
                    });
                  } else {
                    navigate(`/dashboard`, {
                      state: { ImageArray: FilesArray, index: index },
                    });
                  }
                }}
              >
                <ReactPlayer url={item?.url} width={'100%'} height={'200px'} />
              </div>
              : fileType === "audio" ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '250px', height: '200px', backgroundColor: "#F9F6F1" }}
                  onClick={() => {
                    if (
                      path === "/Clientdashboard" ||
                      path === "/ClientDashboard" ||
                      path === "/clientdashboard" ||
                      path === "/clientDashboard" ||
                      path === "/Clientdashboard/folder" ||
                      path === "/ClientDashboard/folder" ||
                      path === "/clientdashboard/folder" ||
                      path === "/clientDashboard/folder"
                    ) {
                      navigate(`/Clientdashboard/image`, {
                        state: { ImageArray: FilesArray, index: index },
                      });
                    } else {
                      navigate(`/dashboard`, {
                        state: { ImageArray: FilesArray, index: index },
                      });
                    }
                  }}
                >
                  <img draggable={false} src={HeadPhoneImage} style={{ width: '5rem', height: '5rem' }} />
                </div>
                : <></>
        }
        {
          fileType === "audio" || fileType === "video" ?
            <div className="image-gallery-audio-container">
              <img
                draggable={false}
                src={fileType === "audio" ? AudioIcon : VideoIcon}
                alt="audio"
                id="image-gallery-approved-icon"
                className="image-gallery-audio-icon"
              />
            </div>
            : <></>
        }
      </div>
    );
  };

  const DeletedImageItems = ({ item, index }) => {
    const [fileType, setFileType] = useState(item?.mimeType?.split("/")[0]);
    return (
      <div id="image-gallery-img-container" style={{ cursor: 'pointer' }}>
        {selectFilesPressed && (
          <div
            id="image-gallery-img-overlay"
            onClick={() => handleSelectFile(item?._id)}
          >
            {selectedFiles?.includes(item?._id) && (
              <CheckBox id="image-gallery-img-checkbox" />
            )}
          </div>
        )}
        {item?.comments?.length > 0 && (
          <img
            src={comments}
            alt="comments"
            id="image-gallery-comments-icon"
            className="image-gallery-img-icon"
          />
        )}
        <div className="image-gallery-img-icons-container">
          {item?.isApproved && (
            <img
              src={approved}
              alt="approved"
              id="image-gallery-approved-icon"
              className="image-gallery-img-icon"
            />
          )}
          {item?.isImportant && (
            <img
              src={important}
              alt="important"
              id="image-gallery-important-icon"
              className="image-gallery-img-icon"
            />
          )}
        </div>
        {
          fileType === "image" ?
            <img
              draggable={false}
              // onClick={() => {
              //   if (
              //     path === "/Clientdashboard" ||
              //     path === "/ClientDashboard" ||
              //     path === "/clientdashboard" ||
              //     path === "/clientDashboard" ||
              //     path === "/Clientdashboard/folder" ||
              //     path === "/ClientDashboard/folder" ||
              //     path === "/clientdashboard/folder" ||
              //     path === "/clientDashboard/folder"
              //   ) {
              //     navigate(`/Clientdashboard/image`, {
              //       state: { ImageArray: FilesArray, index: index },
              //     });
              //   } else {
              //     navigate(`/dashboard`, {
              //       state: { ImageArray: FilesArray, index: index },
              //     });
              //   }
              // }}
              // src={item?.url}
              src={item?.url}
              alt="gallery-item"
              id="image-gallery-img"
              style={{
                objectFit: "contain",
                height: "200px",
                width: "100%",
              }}
            />
            : fileType === "video" ?
              <div
              // onClick={() => {
              //   if (
              //     path === "/Clientdashboard" ||
              //     path === "/ClientDashboard" ||
              //     path === "/clientdashboard" ||
              //     path === "/clientDashboard" ||
              //     path === "/Clientdashboard/folder" ||
              //     path === "/ClientDashboard/folder" ||
              //     path === "/clientdashboard/folder" ||
              //     path === "/clientDashboard/folder"
              //   ) {
              //     navigate(`/Clientdashboard/image`, {
              //       state: { ImageArray: FilesArray, index: index },
              //     });
              //   } else {
              //     navigate(`/dashboard`, {
              //       state: { ImageArray: FilesArray, index: index },
              //     });
              //   }
              // }}
              >
                <ReactPlayer url={item?.url} width={'100%'} height={'200px'} />
              </div>
              : fileType === "audio" ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '250px', height: '200px', backgroundColor: "#F9F6F1" }}
                // onClick={() => {
                //   if (
                //     path === "/Clientdashboard" ||
                //     path === "/ClientDashboard" ||
                //     path === "/clientdashboard" ||
                //     path === "/clientDashboard" ||
                //     path === "/Clientdashboard/folder" ||
                //     path === "/ClientDashboard/folder" ||
                //     path === "/clientdashboard/folder" ||
                //     path === "/clientDashboard/folder"
                //   ) {
                //     navigate(`/Clientdashboard/image`, {
                //       state: { ImageArray: FilesArray, index: index },
                //     });
                //   } else {
                //     navigate(`/dashboard`, {
                //       state: { ImageArray: FilesArray, index: index },
                //     });
                //   }
                // }}
                >
                  <img src={HeadPhoneImage} style={{ width: '5rem', height: '5rem' }} />
                </div>
                : <></>
        }
        {
          fileType === "audio" || fileType === "video" ?
            <div className="image-gallery-audio-container">
              <img
                src={fileType === "audio" ? AudioIcon : VideoIcon}
                alt="audio"
                id="image-gallery-approved-icon"
                className="image-gallery-audio-icon"
              />
            </div>
            : <></>
        }
      </div>
    );
  }

  const images = [
    "https://plus.unsplash.com/premium_photo-1683290222216-a9fab340cbf8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1964&q=80",
    "https://images.unsplash.com/photo-1519741497674-611481863552?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
    "https://images.unsplash.com/photo-1583939003579-730e3918a45a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",
    "https://images.unsplash.com/photo-1465495976277-4387d4b0b4c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
    "https://images.unsplash.com/photo-1529636798458-92182e662485?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80",
    "https://images.unsplash.com/photo-1607190074257-dd4b7af0309f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",
    "https://images.unsplash.com/photo-1606216794074-735e91aa2c92?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",
    "https://images.unsplash.com/photo-1591604466107-ec97de577aff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80",
  ];
  const [imagesArray, setImagesArray] = useState([]);
  const [activeFilesPage, setActiveFilesPage] = useState(1);
  const [deletedFilesPage, setDeletedFilesPage] = useState(1);

  useEffect(() => {
    setImagesArray(FilesArray?.filter((item) => !item?.isTrashed));
    setCustomerId(FilesArray?.[0]?.customerId);
    // setActiveFilesPage(1);
  }, [FilesArray]);
  useEffect(() => {
    setCustomerId(deletedImagesArray?.[0]?.customerId);
    // setDeletedFilesPage(1);
  }, [deletedImagesArray]);



  const MoveToTrashWarningModal = () => (
    <div className="image-gallery-modal">
      <Modal
        open={openTrashWarningModal}
        onClose={() => setOpenTrashWarningModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="delete-confirm-popup">
          <p style={{ color: '#000' }}>
            Are you sure you want to move {selectedFiles?.length} file(s) to
            Trash?
          </p>
          <Button
            onClick={() => setOpenTrashWarningModal(false)}
            style={{ position: 'absolute', right: 0, top: 0 }}
          >
            <span style={{ fontFamily: 'Nunito', fontWeight: 'bold', fontSize: '18px', color: '#000' }}>x</span>
          </Button>
          <div>
            <button
              style={{ color: '#000', padding: '5px', fontFamily: 'Nunito', backgroundColor: '#5DCE7B', color: '#000', width: '80px', textTransform: 'capitalize', borderRadius: '20px', marginTop: '10px' }}
              onClick={() => {
                increaseLoadingCounter()
                {
                  Custumer ?
                    dispatch(moveMultipleFilesToTrashCustomer(selectedFiles)).then(() => {
                      decreaseLoadingCounter()
                    })
                    :
                    dispatch(moveMultipleFilesToTrash(FilesArray?.[0]?.customerId, selectedFiles, FilesArray?.[0]?.parent)).then(() => {
                      decreaseLoadingCounter()
                    });
                }
                setOpenTrashWarningModal(false);
                setSelectFilesPressed((prev) => !prev);
                setSelectDeletedFilesPressed(false);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
  const RestoreFileModal = () => (
    <div className="image-gallery-modal">
      <Modal
        open={RestoreModal}
        onClose={() => setRestoreModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: '#fff',
          border: "1px solid gray",
          boxShadow: 24,
          p: 4,
          borderRadius: '20px',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '20px',
          alignItems: 'center',
          height: '200px'
        }}>
          <p style={{ color: '#000' }}>
            Are you sure you want to Restore {selectedFiles?.length} file(s) from
            Trash?
          </p>
          <Button
            onClick={() => setRestoreModal(false)}
            style={{ position: 'absolute', right: 0, top: 0 }}
          >
            <span style={{ fontFamily: 'Nunito', fontWeight: 'bold', fontSize: '18px', color: '#000' }}>x</span>
          </Button>
          <div>

            <button
              style={{ color: '#000', padding: '5px', fontFamily: 'Nunito', backgroundColor: '#5DCE7B', color: '#000', width: '80px', textTransform: 'capitalize', borderRadius: '20px', marginTop: '10px' }}
              onClick={() => {
                increaseLoadingCounter();
                {
                  Custumer ?
                    dispatch(restoreclientmultiplefile(selectedFiles, rootFolder)).then(() => {
                      decreaseLoadingCounter()
                    }) :
                    dispatch(restoremultiplefile(deletedImagesArray?.[0]?.customerId, selectedFiles, rootFolder)).then(() => {
                      decreaseLoadingCounter()
                    })
                }
                setRestoreModal(false)
                setSelectFilesPressed((prev) => !prev);
                setSelectDeletedFilesPressed(false);
                setRestoreButton(true)
                setDeleteButton(false)
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );

  const DeleteWarningModal = () => (
    <div className="image-gallery-modal">
      <Modal
        open={openDeleteWarningModal}
        onClose={() => setOpenDeleteWarningModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: '#fff',
          border: "1px solid gray",
          boxShadow: 24,
          p: 4,
          borderRadius: '20px',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '20px',
          alignItems: 'center',
          height: '200px'
        }}>
          <p style={{ color: '#000' }}>
            Are you sure you want to delete the {selectedFiles?.length} file(s)?
          </p>
          <Button
            onClick={() => setOpenDeleteWarningModal(false)}
            style={{ position: 'absolute', right: 0, top: 0 }}
          >
            <span style={{ fontFamily: 'Nunito', fontWeight: 'bold', fontSize: '18px', color: '#000' }}>x</span>
          </Button>
          <div >
            <button
              style={{ color: '#000', padding: '5px', fontFamily: 'Nunito', backgroundColor: '#5DCE7B', color: '#000', width: '80px', textTransform: 'capitalize', borderRadius: '20px', marginTop: '10px' }}
              onClick={() => {
                increaseLoadingCounter()
                if (Custumer) {
                  dispatch(deleteclientmultiplefile(selectedFiles)).then(() => {
                    decreaseLoadingCounter()
                  });
                }
                else {
                  dispatch(deleteMultipleFilesFromTrash(deletedImagesArray?.[0]?.customerId, selectedFiles, rootFolder)).then(() => {
                    decreaseLoadingCounter()
                  });
                }
                setOpenDeleteWarningModal(false)
                setSelectFilesPressed((prev) => !prev);
                setSelectDeletedFilesPressed(false);
                setDeleteButton(true)
                setRestoreButton(false)
              }}
            >
              yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );

  return (
    <div style={{width:"100%"}}>
      <MoveToTrashWarningModal />
      <DeleteWarningModal />

      {deletedFile ?
        <>
          <div className="image-gallery-sub-header">
            <p id="image-gallery-sub-header-text">
              File(s) - {imagesArray?.length}
            </p>
            {selectedFiles?.length > 0 && selectFilesPressed && (
              <button
                className="image-gallery-sub-header-delete-btn"
                id="image-gallery-sub-header-text-white"
                onClick={() => setOpenTrashWarningModal(true)}
              >
                <DeleteOutline id="image-gallery-delete-icon" />
                Move Selected Files to Trash
              </button>
            )}
            <button
              className="image-gallery-sub-header-btn"
              id="image-gallery-sub-header-text"
              onClick={() => {
                setSelectFilesPressed((prev) => !prev);
                setSelectDeletedFilesPressed(false);
              }}
            >
              <DeleteOutline id="image-gallery-delete-icon" />
              
              {selectFilesPressed ? "Cancle" : "Move Files to Trash"}
            </button>
          </div>
          <div id="image-gallery-grid" className="image-masonry-main masonry-grid ">
            {
              FilesArray?.length > 0 ?
                imagesArray?.slice((activeFilesPage - 1) * 20, activeFilesPage * 20).map((item, index) => (
                  <ImageModal className="item" shouldOpen={true} key={index} FilesArray={FilesArray} item={item} index={index + (activeFilesPage - 1) * 20} selectFilesPressed={selectFilesPressed} selectedFiles={selectedFiles} handleSelectFile={handleSelectFile} comments={comments} approved={approved} important={important} />
                )) :
                <div className="no-files-image">
                  <img draggable={false} src={emptyFiles} alt="no files found" id="no-files-img" style={{minWidth:"500px"}} />
                </div>
            }
          </div>
          <div id="image-gallery-pagination-container">
            <p id="image-gallery-pagination-container-text">
              Showing 1 to {FilesArray?.length > 10 ? 10 : FilesArray?.length} of{" "}
              {FilesArray?.length} entries
            </p>
            <Pagination
              key={FilesArray?.length}
              count={Math.ceil(FilesArray?.length / 20)}
              showFirstButton
              showLastButton
              id="image-gallery-pagination-btn"
              onChange={(e, value) => setActiveFilesPage(value)}
            />
          </div>
        </> :
        <>

          <div className="image-gallery-sub-header">
            <p id="image-gallery-sub-header-text">
              Deleted File(s) - {deletedImagesArray?.length}
            </p>
            {selectedFiles?.length > 0 && selectFilesPressed && DeleteButton && (
              <button
                className="image-gallery-sub-header-delete-btn"
                id="image-gallery-sub-header-text-white"
                onClick={() => setOpenDeleteWarningModal(true)}
              >
                <DeleteOutline id="image-gallery-delete-icon" />
                Delete Selected Files
              </button>
            )}
            {selectedFiles?.length > 0 && selectFilesPressed && RestoreButton && (
              <button
                className="image-gallery-sub-header-delete-btn"
                id="image-gallery-sub-header-text-white"
                onClick={() => setRestoreModal(true)}
              >
                <RestoreOutlined id="image-gallery-delete-icon" />
                Restore File
              </button>
            )}
            <RestoreFileModal />
            <button
              className="image-gallery-sub-header-btn"
              id="image-gallery-sub-header-text"
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                setSelectFilesPressed((prev) => !prev);
                setSelectDeletedFilesPressed(false);
                setDeleteButton(true)
                setRestoreButton(false)
              }}
            >
              <DeleteOutline id="image-gallery-delete-icon" />
              Delete Files
            </button>
            <button
              className="image-gallery-sub-header-btn"
              id="image-gallery-sub-header-text"
              onClick={() => {
                setSelectFilesPressed((prev) => !prev);
                setSelectDeletedFilesPressed(false);
                setRestoreButton(true)
                setDeleteButton(false)
              }}
            >
              <RestoreOutlined id="image-gallery-delete-icon" />
              Restore File
            </button>
          </div>
          <div id="image-gallery-grid" className="image-masonry-main masonry-grid">
            {deletedImagesArray
              ?.slice((deletedFilesPage - 1) * 20, deletedFilesPage * 20)
              .map((item, index) => (
                <ImageModal shouldOpen={false} key={index} FilesArray={deletedImagesArray} item={item} index={index + (deletedFilesPage - 1) * 20} selectFilesPressed={selectFilesPressed} selectedFiles={selectedFiles} handleSelectFile={handleSelectFile} comments={comments} approved={approved} important={important} />
              ))}
          </div>
          <div id="image-gallery-pagination-container">
            <p id="image-gallery-pagination-container-text">
              Showing 1 to {deletedImagesArray?.length > 10 ? 10 : deletedImagesArray?.length} of{" "}
              {deletedImagesArray?.length} entries
            </p>
            <Pagination
              key={deletedImagesArray?.length}
              count={Math.ceil(deletedImagesArray?.length / 20)}
              showFirstButton
              showLastButton
              id="image-gallery-pagination-btn"
              onChange={(e, value) => setDeletedFilesPage(value)}
            />
          </div>
        </>
      }
    </div>
  );
};



export default ImageGallery;
