import { Button } from "@mui/material";
import React from "react";
import logo from "../../assets/images/Logo21.png";
import Cookies from "js-cookie";

export default function Header() {
  const handleLogout = () => {
    Cookies.remove("client_access_token");
    Cookies.remove("clientId");
    window.location.reload();
  };

  return (
    <div className="client-header"
      style={{
        display: "flex",
        width: '100%',
        justifyContent: "space-between",
        boxShadow: "0 1px 30px 0 rgba(145,145,145,0.2)",
        position:"fixed",
        left:"0",
        top:"0",
        zIndex:"20",
        backdropFilter:"blur(50px)",
        backgroundColor:"rgba(255,255,255,.8)",
      }}
    >
      <img
        src={logo}
        style={{ height: "3rem", margin: "10px" }}
        alt="Logo"
      />
      <Button style={{ margin: "10px" }} onClick={handleLogout}>
        <span>Logout</span>
      </Button>
    </div>
  );
}
