import React, { useContext } from "react";
import "./clientsStyles.css";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loginClient } from "../../redux/actions/clientActions";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useState } from "react";
import toast from "react-hot-toast";
import jwtDecode from "jwt-decode";
import { LoadingContext } from "../Home/ContextProvider";

export default function ClientLogin() {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const { clientLogin } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clientDetails, setClientDetails] = React.useState({
    username: "",
    password: "",
  });
  const [usernameError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const tokenQuery = new URLSearchParams(window.location.search);
  const [id, setId] = React.useState("");

  useEffect(() => {
    if (tokenQuery.get("userId")) {
      setId(tokenQuery.get("userId")); 
      Cookies.set("clientUserId",tokenQuery.get("userId"))    
    } else return;
  }, [tokenQuery]);

  useEffect(() => {
    const previousToken = Cookies.get("client_access_token");
    if(previousToken){
      try {
        const decodedToken = jwtDecode(previousToken);
        if (decodedToken?.exp * 1000 >= Date.now()) navigate("/Clientdashboard");
      } catch (error) {}
    }
  }, [clientLogin]);

  const onPressLogin = () => {
    if (clientDetails.username === "" || clientDetails.password === "") {
      toast.error("Please enter all the details");
    } else if (clientDetails.password.length < 6) {
      setEmailError(false);
      setPasswordError(true);
    } else {
      setEmailError(false);
      setPasswordError(false);
      increaseLoadingCounter();
      dispatch(loginClient(clientDetails, id, navigate)).then(()=> {
        decreaseLoadingCounter();
      })
    }
  };

  return (
    <div className="client-login-main">
    <div className="client-login-innrer">
    <div className="client-login-left">
      <div className="client-login-left-inner">
      <img
        src={require("../../assets/images/ClintLoginleftImage.png")}
        alt="Logo"
        style={{width:"100%"}}
      />
      </div>
    </div>
    <div className="client-login-right">
    <div className="client-login-formmain">
      <h3 style={{ fontFamily: "Nunito" }}>Welcome</h3>
      <span style={{ color: "#A1A4B2", margin: 0 }}>LOGIN</span>
      <input
        type="text"
        placeholder="Username"
        className="client-login-form-input"
        value={clientDetails.username}
        onChange={(e) =>
          setClientDetails({ ...clientDetails, username: e.target.value })
        }
      />
      {usernameError && (
        <span
          style={{
            color: "red",
          }}
        >
          Please enter a valid username
        </span>
      )}
      <input
        type="password"
        placeholder="Password"
        className="client-login-form-input"
        value={clientDetails.password}
        onChange={(e) =>
          setClientDetails({ ...clientDetails, password: e.target.value })
        }
      />
      {passwordError && (
        <span
          style={{
            color: "red",
          }}
        >
          Please enter a valid password
        </span>
      )}
      <Button
        style={{
          backgroundColor: "#FA6E5A",
          paddingLeft: "13.5%",
          paddingRight: "13.5%",
          borderRadius: "50px",
          paddingTop: "15px",
          paddingBottom: "15px",
          marginTop: "20px",
        }}
        onClick={onPressLogin}
      >
        <p
          className="p"
          style={{
            fontSize: "13px",
            fontWeight: "normal",
            fontFamily: "Nunito",
          }}
        >
          LOG IN
        </p>
      </Button>

      {/* <Button style={{ marginTop: "20px", textTransform: "capitalize" }}>
        <p
          className="p"
          style={{
            color: "#3F414E",
            fontWeight: "normal",
            fontFamily: "Nunito",
          }}
        >
          Forgot Password?
        </p>
      </Button> */}

     </div>
    </div>
    </div>
    <div className="client-login-footer"> 
    <span style={{ marginTop: "auto", fontFamily: "Nunito" }}>
        Powered By
      </span>
      <a href={"/"}>
      <img
        src={require("../../assets/images/GSProLogoW.png")}
        alt="Logo"
        style={{maxWidth:"250px"}}
      /></a>
      </div>
    </div>
  );
}
