import React from "react";
import "./adminHeaderStyles.css";
import logo from "../../assets/images/GSPro-Logo.svg";
import { Menu, MenuItem } from "@mui/material";
import User from "../../assets/images/Group3.png";
import Cookies from "js-cookie";

const AdminHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove("admin_access_token");
    Cookies.remove("adminId");
    handleClose();
    window.location.reload();
  };

  return (
    <div className="admin-header">
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <img
            style={{ height: "20px", marginRight: "5px" }}
            src={User}
            alt="user"
          />
          <span style={{ color: "#000" }}>{Cookies.get("adminUsername")}</span>
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              backgroundColor: "#FA6E5A",
              padding: "5px",
              paddingLeft: "35%",
              paddingRight: "35%",
              borderRadius: "20px",
            }}
          >
            Logout
          </span>
        </MenuItem>
      </Menu>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <img
          src={logo}
          alt="google"
          style={{
            height: "3rem",
          }}
        />
      </div>
      <div className="admin-header-title-btns-container">
        <div>
          <p id="welcome-text">
            Welcome Back! <span id="welcome-username">Admin</span>
          </p>
        </div>
        <div
          style={{
            marginRight: "20px",
            display: "flex",
            gap: "15px",
          }}
        >
          <button className="admin-header-btn">
            <img
              id="admin-header-btn-icon"
              src="https://img.icons8.com/fluency-systems-regular/48/A0A3B1/crescent-moon.png"
              alt="crescent-moon"
            />
          </button>
          <button className="admin-header-btn" onClick={handleClick}>
            <img
              id="admin-header-btn-icon"
              src="https://img.icons8.com/fluency-systems-regular/48/A0A3B1/user--v1.png"
              alt="night-mode"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
