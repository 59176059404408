import React, { useEffect, useState } from "react";
import "./adminSidebarStyles.css";
import { LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarOption } from "../../redux/actions/sidebarAction";
import { useNavigate } from "react-router-dom";
import { getAllUsers } from "../../redux/actions/adminActions";

const AdminSidebar = () => {
  const { sidebarOption } = useSelector((state) => state.sidebar);
  const { adminCreateUser, adminDeleteUser, adminUpdateUserStatus, adminUpdateUserPlan } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(
    sidebarOption || "dashboard"
  );
  const navigate = useNavigate();

  const handleItemSelection = (item) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, [adminCreateUser, adminDeleteUser, adminUpdateUserStatus, adminUpdateUserPlan]);

  useEffect(() => {
    setSelectedItem(sidebarOption || "dashboard");
  }, [sidebarOption]);

  return (
    <div className="admin-sidebar">
      <div className="admin-sidebar-items-container">
        <button
          className={
            selectedItem === "dashboard"
              ? "sidebar-item sidebar-item-selected"
              : "sidebar-item"
          }
          onClick={() => {
            handleItemSelection("dashboard");
            dispatch(setSidebarOption("dashboard"));
            if (window.location.pathname !== "/admin-dashboard") {
              navigate("/admin-dashboard");
            }
          }}
        >
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/external-tanah-basah-detailed-outline-tanah-basah/48/external-dashboard-user-interface-tanah-basah-detailed-outline-tanah-basah.png"
            alt="guest-male"
          />
          <p style={{ fontSize: '0.9rem',margin: 0, color: "#000" }}>Dashboard</p>
        </button>
        <button
          className={
            selectedItem === "users"
              ? "sidebar-item sidebar-item-selected"
              : "sidebar-item"
          }
          onClick={() => {
            handleItemSelection("users");
            dispatch(setSidebarOption("users"));
            if (window.location.pathname !== "/admin-dashboard") {
              navigate("/admin-dashboard");
            }
          }}
        >
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/fluency-systems-regular/48/guest-male.png"
            alt="guest-male"
          />
          <p style={{ fontSize: '0.9rem',margin: 0, color: "#000" }}>Users</p>
        </button>
        <button
          className={
            selectedItem === "inactive"
              ? "sidebar-item sidebar-item-selected"
              : "sidebar-item"
          }
          onClick={() => {
            handleItemSelection("inactive");
            dispatch(setSidebarOption("inactive"));
            if (window.location.pathname !== "/admin-dashboard") {
              navigate("/admin-dashboard");
            }
          }}
        >
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/ios-glyphs/30/cancel-2.png"
            alt="cancel-2"
          />
          <p style={{ fontSize: '0.9rem',margin: 0, color: "#000" }}>In-active Users</p>
        </button>
        <button
          className={
            selectedItem === "settings"
              ? "sidebar-item sidebar-item-selected"
              : "sidebar-item"
          }
          onClick={() => {
            handleItemSelection("settings");
            dispatch(setSidebarOption("settings"));
            if (window.location.pathname !== "/admin-dashboard") {
              navigate("/admin-dashboard");
            }
          }}
        >
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/windows/32/checked-user-male--v1.png"
            alt="checked-user-male--v1"
          />
          <p style={{ fontSize: '0.9rem',margin: 0, color: "#000" }}>Settings</p>
        </button>
      </div>
      <span style={{color: '#FA6E5A', position: 'absolute', bottom: '0'}}>© The Smart Trunk</span>
    </div>
  );
};

export default AdminSidebar;
