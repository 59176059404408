import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Button, TextField } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import moment from "moment/moment";
import { setSidebarOption } from "../../redux/actions/sidebarAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getAllUsers } from "../../redux/actions/adminActions";
import { backend_api } from "../../redux/store";
import toast from "react-hot-toast";
import { LoadingContext } from "../Home/ContextProvider";

export default function AdminDashboardHome() {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const { adminAllUsers } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [CreateNewUser, setCreateNewUser] = useState(true);
  const [plan, setPlan] = useState("");
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [statistics, setStatistics] = useState({});

  useEffect(() => {
    getAdminStats();
  }, []);

  useEffect(() => {
    if (adminAllUsers?.users) {
      setAllUsers(adminAllUsers?.users);
    }
  }, [adminAllUsers]);

  const handleChange = (event) => {
    setPlan(event.target.value);
  };

  const createUser = async () => {
    if (userDetails.password.length < 6) {
      alert("Password should be atleast 6 characters long");
      return;
    }
    if (userDetails.name === "" || userDetails.email === "") {
      alert("Please enter all the details");
      return;
    }
    if (plan === "") {
      alert("Please select a plan");
      return;
    }



    dispatch({
      type: "adminCreateUserLoading",
    });
    increaseLoadingCounter();
    await axios.post(
      `${backend_api}/api/admin/createUser`,
      {
        name: userDetails.name,
        email: userDetails.email,
        password: userDetails.password,
        planType: plan,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("admin_access_token")}`,
        },
      }
    ).then(async (res) => {
      dispatch({
        type: "adminCreateUserSuccess",
        payload: res.data,
      });
      toast.success(res.data.message);
      setCreateNewUser(true);
    }).catch((err) => {
      dispatch({
        type: "adminCreateUserFailure",
        payload: err.response.data.message,
      });
      if (err.response.status === 401) window.location.href = "/error404";
      return toast.error(err.response.data.message || "Something went wrong");
    }).finally(() => {
      decreaseLoadingCounter();
    })
  };

  const getAdminStats = async () => {
    try {
      increaseLoadingCounter();
      const response = await axios.get(
        `${backend_api}/api/admin/getStatistics`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get("admin_access_token")}`,
          },
        }
      );
      if (response.status === 200) {
        setStatistics(response.data?.data);
      }
    } catch (err) {
      if(err.response.status === 401){
        Cookies.remove("admin_access_token");
        window.location.href = "/error404";
      }
    } finally {
      decreaseLoadingCounter();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "20px",
        paddingRight: "20px",
        paddingLeft: "20px",
        width: "100%",
        height: "100%",
      }}
    >
      {CreateNewUser ? (
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "#3F414E",
              fontFamily: "Nunito",
              marginLeft: "15px",
            }}
          >
            Dashboard
          </span>
          <div style={{ display: "flex", margin: "15px" }}>
            <div
              style={{
                border: "1px solid #FA6E5Aed",
                paddingTop: 0,
                borderRadius: "15px",
                marginRight: "15px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#F8E8E6",
                  marginTop: 0,
                  borderTopRightRadius: "15px",
                  borderTopLeftRadius: "15px",
                  height: "110px",
                  width: "140px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <p
                  style={{
                    color: "#3F414E",
                    fontFamily: "Nunito",
                    marginTop: 0,
                    marginBottom: 0,
                    fontSize: "30px",
                  }}
                >
                  {statistics?.usersCount || 0}
                </p>
              </div>
              <div>
                <p
                  style={{
                    color: "#3F414E",
                    fontFamily: "Nunito",
                    marginTop: "3px",
                    marginBottom: "3px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  Clients
                </p>
              </div>
            </div>
            <div
              style={{
                border: "1px solid #FA6E5Aed",
                paddingTop: 0,
                borderRadius: "15px",
                marginRight: "15px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#F8E8E6",
                  marginTop: 0,
                  borderTopRightRadius: "15px",
                  borderTopLeftRadius: "15px",
                  height: "110px",
                  width: "140px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <p
                  style={{
                    color: "#3F414E",
                    fontFamily: "Nunito",
                    marginTop: 0,
                    marginBottom: 0,
                    fontSize: "30px",
                  }}
                >
                  {statistics?.customersCount || 0}
                </p>
              </div>
              <div>
                <p
                  style={{
                    color: "#3F414E",
                    fontFamily: "Nunito",
                    marginTop: "3px",
                    marginBottom: "3px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  Customers
                </p>
              </div>
            </div>
            <div
              style={{
                border: "1px solid #FA6E5Aed",
                paddingTop: 0,
                borderRadius: "15px",
                marginRight: "15px",
              }}
            >
              <div
                style={{
                  flexDirection: "column",
                  backgroundColor: "#F8E8E6",
                  marginTop: 0,
                  borderTopRightRadius: "15px",
                  borderTopLeftRadius: "15px",
                  height: "110px",
                  width: "140px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <span
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require("../../assets/images/Vector(8).png")}
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                      marginBottom: "2px",
                    }}
                    alt="Logo"
                  />
                  <p
                    style={{
                      color: "#3F414E",
                      fontFamily: "Nunito",
                      marginTop: 0,
                      marginBottom: 0,
                      fontSize: "25px",
                    }}
                  >
                    {statistics?.filesCount || 0}
                  </p>
                </span>
                <span
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require("../../assets/images/Vector(9).png")}
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                      marginBottom: "2px",
                    }}
                    alt="Logo"
                  />
                  <p
                    style={{
                      color: "#3F414E",
                      fontFamily: "Nunito",
                      marginTop: 0,
                      marginBottom: 0,
                      fontSize: "25px",
                    }}
                  >
                    {statistics?.foldersCount || 0}
                  </p>
                </span>
              </div>
              <div>
                <p
                  style={{
                    color: "#3F414E",
                    fontFamily: "Nunito",
                    marginTop: "3px",
                    marginBottom: "3px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  Files & Folders
                </p>
              </div>
            </div>
            <div
              style={{
                border: "1px solid #FA6E5Aed",
                paddingTop: 0,
                borderRadius: "15px",
                marginRight: "15px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FA6E5Aed",
                  marginTop: 0,
                  borderTopRightRadius: "15px",
                  borderTopLeftRadius: "15px",
                  height: "110px",
                  width: "140px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Nunito",
                    marginTop: 0,
                    marginBottom: 0,
                    fontSize: "30px",
                  }}
                >
                  $25K
                </p>
              </div>
              <div>
                <p
                  style={{
                    color: "#3F414E",
                    fontFamily: "Nunito",
                    marginTop: "3px",
                    marginBottom: "3px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  Revenue
                </p>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", margin: "15px", width: "100%" }}>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#3F414E",
                fontFamily: "Nunito",
              }}
            >
              Latest Users
            </span>
            <Button
              onClick={() => {
                setCreateNewUser(false);
              }}
              style={{
                display: "flex",
                backgroundColor: "#FA6E5A",
                marginLeft: "auto",
                borderRadius: "100px",
                paddingLeft: "30px",
                paddingRight: "30px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                  color: "#fff",
                  fontFamily: "Nunito",
                }}
              >
                + Add New User
              </span>
            </Button>
          </div>
          <div>
            {allUsers.length > 0 &&
              allUsers?.map((user, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid #E9E9E9",
                    cursor: "pointer",
                    marginBottom: "10px",
                  }}
                  onClick={() => {
                    dispatch(setSidebarOption("users"));

                    navigate(`/admin/user-details?userId=${user?._id}`);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                    }}
                  >
                    <span
                      style={{
                        color: "#3F414E",
                        fontFamily: "Nunito",
                        width: "200px",
                      }}
                    >
                      {user?.name}
                    </span>
                    <span
                      style={{
                        color: "#FA6E5A",
                        fontFamily: "Nunito",
                        fontSize: "14px",
                        fontWeight: 800,
                        textTransform: "uppercase",
                      }}
                    >
                      {user?.subscriptionType}
                    </span>
                    {user?.status === "active" ? (
                      <span
                        style={{
                          color: "#5DCE7B",
                          fontFamily: "Nunito",
                          fontSize: "14px",
                          fontWeight: 800,
                          marginLeft: "auto",
                          textTransform: "uppercase",
                        }}
                      >
                        Active
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "#FF6A6A",
                          fontFamily: "Nunito",
                          fontSize: "14px",
                          fontWeight: 800,
                          marginLeft: "auto",
                          textTransform: "uppercase",
                        }}
                      >
                        Inactive
                      </span>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#FFF6F4",
                      padding: "5px",
                    }}
                  >
                    <span
                      style={{
                        color: "#3F414E",
                        fontFamily: "Nunito",
                        fontSize: "10px",
                      }}
                    >
                      Created Date :{" "}
                      {moment(user?.createdAt).format("DD MMM YYYY")}
                    </span>
                    <Button
                      style={{
                        textTransform: "capitalize",
                        marginLeft: "auto",
                      }}
                    >
                      <img
                        src={require("../../assets/images/icon _edit 2_.png")}
                        style={{
                          height: "15px",
                          width: "15px",
                          marginRight: "5px",
                          marginBottom: "2px",
                        }}
                        alt="Logo"
                      />
                      <span
                        style={{
                          borderBottom: "2px solid #0094FF",
                          padding: 0,
                          color: "#0094FF",
                          fontSize: "12px",
                        }}
                      >
                        Edit
                      </span>
                    </Button>
                    <Button
                      style={{ textTransform: "capitalize", marginLeft: "8px" }}
                    >
                      <img
                        src={require("../../assets/images/icon _trash_.png")}
                        style={{
                          height: "15px",
                          width: "15px",
                          marginRight: "5px",
                          marginBottom: "2px",
                        }}
                        alt="Logo"
                      />
                      <span
                        style={{
                          borderBottom: "2px solid #FF6A6A",
                          padding: 0,
                          color: "#FF6A6A",
                          fontSize: "12px",
                        }}
                      >
                        Delete
                      </span>
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            marginBottom: "100px",
            marginTop: "20px",
            flexDirection: "column",
            display: "flex",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#F8E8E6",
              width: "400px",
              padding: "50px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <KeyboardBackspaceOutlinedIcon
                style={{ color: "#3F414E" }}
                onClick={() => {
                  setCreateNewUser(true);
                }}
              />
              <span
                style={{
                  fontFamily: "Nunito",
                  fontSize: "20px",
                  marginLeft: "25%",
                  color: "#3F414E",
                  fontWeight: "bold",
                }}
              >
                Create New User
              </span>
            </div>
            <span
              style={{
                fontFamily: "Nunito",
                fontSize: "15px",
                color: "#3F414E",
              }}
            >
              Client Name
            </span>
            <input
              style={{
                borderStyle: "none",
                width: "90%",
                padding: "20px",
                borderRadius: "10px",
                marginTop: "5px",
              }}
              type="text"
              placeholder="Client Name"
              value={userDetails.name}
              onChange={(e) => {
                setUserDetails({
                  ...userDetails,
                  name: e.target.value,
                });
              }}
            />
            <span
              style={{
                fontFamily: "Nunito",
                fontSize: "15px",
                color: "#3F414E",
                marginTop: "30px",
              }}
            >
              Login Details
            </span>
            <input
              style={{
                borderStyle: "none",
                width: "90%",
                padding: "20px",
                borderRadius: "10px",
                marginTop: "5px",
              }}
              type="text"
              placeholder="Email"
              value={userDetails.email}
              onChange={(e) => {
                setUserDetails({
                  ...userDetails,
                  email: e.target.value,
                });
              }}
            />
            <input
              style={{
                borderStyle: "none",
                width: "90%",
                padding: "20px",
                borderRadius: "10px",
                marginTop: "15px",
              }}
              type="password"
              placeholder="Password"
              value={userDetails.password}
              onChange={(e) => {
                setUserDetails({
                  ...userDetails,
                  password: e.target.value,
                });
              }}
            />
            <span
              style={{
                fontFamily: "Nunito",
                fontSize: "15px",
                color: "#3F414E",
                marginTop: "30px",
              }}
            >
              Subscription
            </span>
            <Box
              sx={{
                minWidth: 120,
                borderStyle: "none",
                backgroundColor: "#fff",
                marginTop: "10px",
              }}
            >
              <FormControl fullWidth style={{ borderStyle: "none" }}>
                <TextField
                  select
                  value={plan}
                  label="Plan"
                  onChange={handleChange}
                  style={{
                    borderStyle: "none",
                    color: "#3F414E",
                    border: "none",
                    outline: "none",
                  }}
                >
                  <MenuItem value={"free"}>Free</MenuItem>
                  <MenuItem value={"lite"}>Lite</MenuItem>
                  <MenuItem value={"pro"}>Pro</MenuItem>
                </TextField>
              </FormControl>
            </Box>

            <Button
              onClick={() => {
                createUser();
              }}
              style={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                backgroundColor: "#FA6E5A",
                borderRadius: "100px",
                paddingLeft: "30px",
                paddingRight: "30px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                  color: "#fff",
                  fontFamily: "Nunito",
                  margin: "10px",
                }}
              >
                + Add New User
              </span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
