import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../../components/AdminHeader/AdminHeader";
import AdminSidebar from "../../../components/AdminSidebar/AdminSidebar";
import "./adminSettingsStyles.css";
import {
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { backend_api } from "../../../redux/store";
import { LoadingContext } from "../../Home/ContextProvider";

const AdminSettingsTopbar = ({ setSearchText, searchText, onUpdate }) => (
  <div id="admin-settings-top-container">
    <h1 id="admin-settings-top-container-title">Settings</h1>
    <div id="admin-settings-searchbar-container">
      <input
        type="text"
        id="admin-settings-searchbar"
        placeholder="Search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <img
        src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/64/5DCE7B/external-search-twitter-flatart-icons-outline-flatarticons.png"
        alt="external-search-twitter-flatart-icons-outline-flatarticons"
        id="admin-settings-searchbar-icon"
      />
      <button id="admin-settings-searchbar-btn">Search</button>
    </div>
    <button onClick={onUpdate} id="admin-settings-update-btn">
      Update
    </button>
  </div>
);

const AdminStorageSettings = ({
  storage,
  setStorage,
  incrementStorage,
  decrementStorage,
}) => (
  <Accordion id="admin-settings-component-container">
    <AccordionSummary id="admin-settings-component-container-top">
      <img
        width="20"
        height="20"
        src="https://img.icons8.com/material-rounded/48/22242E/sort-down.png"
        alt="sort-down"
      />
      <h1 id="admin-settings-component-container-title">Storage</h1>
    </AccordionSummary>
    <div id="admin-settings-component-container-bottom">
      <div id="admin-settings-component-container-bottom-item-container">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Free</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-right">
          <div id="admin-settings-limit-container">
            <div id="admin-settings-limit-container-left">
              <button
                onClick={() => decrementStorage("free")}
                id="admin-settings-limit-container-left-btn"
              >
                -
              </button>
              <p id="admin-settings-component-container-text-small">
                {storage.free.storage}
              </p>
              <button
                onClick={() => incrementStorage("free")}
                id="admin-settings-limit-container-left-btn"
              >
                +
              </button>
            </div>
            <div id="admin-settings-limit-container-right">
              <select
                name="storage"
                onChange={(e) =>
                  setStorage({
                    ...storage,
                    free: {
                      ...storage.free,
                      storageUnit: e.target.value,
                    },
                  })
                }
                value={storage.free.storageUnit}
                id="storage-dropdown"
              >
                <option value="GB" id="storage-dropdown-item">
                  GB
                </option>
                <option value="MB" id="storage-dropdown-item">
                  MB
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div id="admin-settings-component-container-bottom-item-container">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text-lite">Lite</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-right">
          <div id="admin-settings-limit-container">
            <div id="admin-settings-limit-container-left">
              <button
                onClick={() => decrementStorage("lite")}
                id="admin-settings-limit-container-left-btn"
              >
                -
              </button>
              <p id="admin-settings-component-container-text-small">
                {storage.lite?.storage}
              </p>
              <button
                onClick={() => incrementStorage("lite")}
                id="admin-settings-limit-container-left-btn"
              >
                +
              </button>
            </div>
            <div id="admin-settings-limit-container-right">
              <select
                name="storage"
                onChange={(e) =>
                  setStorage({
                    ...storage,
                    lite: {
                      ...storage.lite,
                      storageUnit: e.target.value,
                    },
                  })
                }
                value={storage.lite.storageUnit}
                id="storage-dropdown"
              >
                <option value="GB" id="storage-dropdown-item">
                  GB
                </option>
                <option value="MB" id="storage-dropdown-item">
                  MB
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div id="admin-settings-component-container-bottom-item-container">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text-pro">Pro</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-right">
          <div id="admin-settings-limit-container">
            <div id="admin-settings-limit-container-left">
              <button
                onClick={() => decrementStorage("pro")}
                id="admin-settings-limit-container-left-btn"
              >
                -
              </button>
              <p id="admin-settings-component-container-text-small">
                {storage.pro.storage}
              </p>
              <button
                onClick={() => incrementStorage("pro")}
                id="admin-settings-limit-container-left-btn"
              >
                +
              </button>
            </div>
            <div id="admin-settings-limit-container-right">
              <select
                name="storage"
                onChange={(e) => {
                  setStorage({
                    ...storage,
                    pro: {
                      ...storage.pro,
                      storageUnit: e.target.value,
                    },
                  });
                }}
                value={storage.pro.storageUnit}
                id="storage-dropdown"
              >
                <option value="GB" id="storage-dropdown-item">
                  GB
                </option>
                <option value="MB" id="storage-dropdown-item">
                  MB
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Accordion>
);

const AdminClientCreationSettings = ({
  clientCreation,
  setClientCreation,
  incrementUsers,
  decrementUsers,
}) => (
  <Accordion id="admin-settings-component-container">
    <AccordionSummary id="admin-settings-component-container-top">
      <img
        width="20"
        height="20"
        src="https://img.icons8.com/material-rounded/48/22242E/sort-down.png"
        alt="sort-down"
      />
      <h1 id="admin-settings-component-container-title">Client Creation</h1>
    </AccordionSummary>
    <div id="admin-settings-component-container-bottom">
      <div id="admin-settings-component-container-bottom-item-container">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Free</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-right">
          <div id="admin-settings-limit-container">
            <div id="admin-settings-limit-container-left">
              <button
                onClick={() => decrementUsers("free")}
                id="admin-settings-limit-container-left-btn"
              >
                -
              </button>
              <p id="admin-settings-component-container-text-small">
                {clientCreation.free.users}
              </p>
              <button
                onClick={() => incrementUsers("free")}
                id="admin-settings-limit-container-left-btn"
              >
                +
              </button>
            </div>
            <div id="admin-settings-limit-container-right">
              <p id="admin-settings-component-container-text-small">Users</p>
            </div>
          </div>
        </div>
      </div>
      <div id="admin-settings-component-container-bottom-item-container">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text-lite">Lite</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-right">
          <div id="admin-settings-limit-container">
            <div id="admin-settings-limit-container-left">
              <button
                onClick={() => decrementUsers("lite")}
                id="admin-settings-limit-container-left-btn"
              >
                -
              </button>
              <p id="admin-settings-component-container-text-small">
                {clientCreation.lite.users}
              </p>
              <button
                onClick={() => incrementUsers("lite")}
                id="admin-settings-limit-container-left-btn"
              >
                +
              </button>
            </div>
            <div id="admin-settings-limit-container-right">
              <p id="admin-settings-component-container-text-small">Users</p>
            </div>
          </div>
        </div>
      </div>
      <div id="admin-settings-component-container-bottom-item-container">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text-pro">Pro</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-right">
          <div id="admin-settings-limit-container">
            <div id="admin-settings-limit-container-left">
              <button
                onClick={() => decrementUsers("pro")}
                id="admin-settings-limit-container-left-btn"
              >
                -
              </button>
              <p id="admin-settings-component-container-text-small">
                {clientCreation.pro.users}
              </p>
              <button
                onClick={() => incrementUsers("pro")}
                id="admin-settings-limit-container-left-btn"
              >
                +
              </button>
            </div>
            <div id="admin-settings-limit-container-right">
              <p id="admin-settings-component-container-text-small">Users</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Accordion>
);

const AdminClientPermissionsSettings = ({
  checkboxCollection,
  setCheckboxCollection,
}) => (
  <Accordion id="admin-settings-component-container">
    <AccordionSummary id="admin-settings-component-container-top">
      <img
        width="20"
        height="20"
        src="https://img.icons8.com/material-rounded/48/22242E/sort-down.png"
        alt="sort-down"
      />
      <h1 id="admin-settings-component-container-title">Client Permissions</h1>
    </AccordionSummary>
    <div id="admin-settings-component-container-bottom-checkboxes">
      <div id="admin-settings-component-container-bottom-item-container-checkboxes">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Free</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-checkboxes-right">
          <div id="admin-settings-all-checkboxes-container">
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.clientPermissions.free.selectAll}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    clientPermissions: {
                      ...checkboxCollection.clientPermissions,
                      free: {
                        ...checkboxCollection.clientPermissions.free,
                        selectAll: e.target.checked,
                        view: e.target.checked,
                        restore: e.target.checked,
                        delete: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Select All</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.clientPermissions.free.view}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    clientPermissions: {
                      ...checkboxCollection.clientPermissions,
                      free: {
                        ...checkboxCollection.clientPermissions.free,
                        view: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">View</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.clientPermissions.free.restore}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    clientPermissions: {
                      ...checkboxCollection.clientPermissions,
                      free: {
                        ...checkboxCollection.clientPermissions.free,
                        restore: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Restore</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.clientPermissions.free.delete}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    clientPermissions: {
                      ...checkboxCollection.clientPermissions,
                      free: {
                        ...checkboxCollection.clientPermissions.free,
                        delete: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Delete</p>
            </div>
          </div>
        </div>
      </div>
      <div id="admin-settings-component-container-bottom-item-container-checkboxes">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Lite</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-checkboxes-right">
          <div id="admin-settings-all-checkboxes-container">
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.clientPermissions.lite.selectAll}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    clientPermissions: {
                      ...checkboxCollection.clientPermissions,
                      lite: {
                        ...checkboxCollection.clientPermissions.lite,
                        selectAll: e.target.checked,
                        view: e.target.checked,
                        restore: e.target.checked,
                        delete: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Select All</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.clientPermissions.lite.view}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    clientPermissions: {
                      ...checkboxCollection.clientPermissions,
                      lite: {
                        ...checkboxCollection.clientPermissions.lite,
                        view: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">View</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.clientPermissions.lite.restore}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    clientPermissions: {
                      ...checkboxCollection.clientPermissions,
                      lite: {
                        ...checkboxCollection.clientPermissions.lite,
                        restore: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Restore</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.clientPermissions.lite.delete}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    clientPermissions: {
                      ...checkboxCollection.clientPermissions,
                      lite: {
                        ...checkboxCollection.clientPermissions.lite,
                        delete: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Delete</p>
            </div>
          </div>
        </div>
      </div>
      <div id="admin-settings-component-container-bottom-item-container-checkboxes">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Pro</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-checkboxes-right">
          <div id="admin-settings-all-checkboxes-container">
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.clientPermissions.pro.selectAll}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    clientPermissions: {
                      ...checkboxCollection.clientPermissions,
                      pro: {
                        ...checkboxCollection.clientPermissions.pro,
                        selectAll: e.target.checked,
                        view: e.target.checked,
                        restore: e.target.checked,
                        delete: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Select All</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.clientPermissions.pro.view}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    clientPermissions: {
                      ...checkboxCollection.clientPermissions,
                      pro: {
                        ...checkboxCollection.clientPermissions.pro,
                        view: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">View</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.clientPermissions.pro.restore}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    clientPermissions: {
                      ...checkboxCollection.clientPermissions,
                      pro: {
                        ...checkboxCollection.clientPermissions.pro,
                        restore: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Restore</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.clientPermissions.pro.delete}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    clientPermissions: {
                      ...checkboxCollection.clientPermissions,
                      pro: {
                        ...checkboxCollection.clientPermissions.pro,
                        delete: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Delete</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Accordion>
);

const AdminUserPermissionsSettings = ({
  checkboxCollection,
  setCheckboxCollection,
}) => (
  <Accordion id="admin-settings-component-container">
    <AccordionSummary id="admin-settings-component-container-top">
      <img
        width="20"
        height="20"
        src="https://img.icons8.com/material-rounded/48/22242E/sort-down.png"
        alt="sort-down"
      />
      <h1 id="admin-settings-component-container-title">User Permissions</h1>
    </AccordionSummary>
    <div id="admin-settings-component-container-bottom-checkboxes">
      <div id="admin-settings-component-container-bottom-item-container-checkboxes">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Free</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-checkboxes-right">
          <div id="admin-settings-all-checkboxes-container">
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.free.selectAll}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      free: {
                        ...checkboxCollection.userPermissions.free,
                        selectAll: e.target.checked,
                        view: e.target.checked,
                        upload: e.target.checked,
                        download: e.target.checked,
                        restore: e.target.checked,
                        delete: e.target.checked,
                        activateCustomer: e.target.checked,
                        deleteCustomer: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Select All</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.free.view}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      free: {
                        ...checkboxCollection.userPermissions.free,
                        view: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">View</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.free.upload}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      free: {
                        ...checkboxCollection.userPermissions.free,
                        upload: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Upload</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.free.download}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      free: {
                        ...checkboxCollection.userPermissions.free,
                        download: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Download</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.free.restore}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      free: {
                        ...checkboxCollection.userPermissions.free,
                        restore: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Restore</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.free.delete}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      free: {
                        ...checkboxCollection.userPermissions.free,
                        delete: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Delete</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={
                  checkboxCollection.userPermissions.free.activateCustomer
                }
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      free: {
                        ...checkboxCollection.userPermissions.free,
                        activateCustomer: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Activate Customer</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.free.deleteCustomer}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      free: {
                        ...checkboxCollection.userPermissions.free,
                        deleteCustomer: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Delete Customer</p>
            </div>
          </div>
        </div>
      </div>
      <div id="admin-settings-component-container-bottom-item-container-checkboxes">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Lite</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-checkboxes-right">
          <div id="admin-settings-all-checkboxes-container">
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.lite.selectAll}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      lite: {
                        ...checkboxCollection.userPermissions.lite,
                        selectAll: e.target.checked,
                        view: e.target.checked,
                        upload: e.target.checked,
                        download: e.target.checked,
                        restore: e.target.checked,
                        delete: e.target.checked,
                        activateCustomer: e.target.checked,
                        deleteCustomer: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Select All</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.lite.view}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      lite: {
                        ...checkboxCollection.userPermissions.lite,
                        view: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">View</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.lite.upload}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      lite: {
                        ...checkboxCollection.userPermissions.lite,
                        upload: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Upload</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.lite.download}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      lite: {
                        ...checkboxCollection.userPermissions.lite,
                        download: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Download</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.lite.restore}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      lite: {
                        ...checkboxCollection.userPermissions.lite,
                        restore: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Restore</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.lite.delete}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      lite: {
                        ...checkboxCollection.userPermissions.lite,
                        delete: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Delete</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={
                  checkboxCollection.userPermissions.lite.activateCustomer
                }
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      lite: {
                        ...checkboxCollection.userPermissions.lite,
                        activateCustomer: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Activate Customer</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.lite.deleteCustomer}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      lite: {
                        ...checkboxCollection.userPermissions.lite,
                        deleteCustomer: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Delete Customer</p>
            </div>
          </div>
        </div>
      </div>
      <div id="admin-settings-component-container-bottom-item-container-checkboxes">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Pro</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-checkboxes-right">
          <div id="admin-settings-all-checkboxes-container">
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.pro.selectAll}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      pro: {
                        ...checkboxCollection.userPermissions.pro,
                        selectAll: e.target.checked,
                        view: e.target.checked,
                        upload: e.target.checked,
                        download: e.target.checked,
                        restore: e.target.checked,
                        delete: e.target.checked,
                        activateCustomer: e.target.checked,
                        deleteCustomer: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Select All</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.pro.view}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      pro: {
                        ...checkboxCollection.userPermissions.pro,
                        view: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">View</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.pro.upload}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      pro: {
                        ...checkboxCollection.userPermissions.pro,
                        upload: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Upload</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.pro.download}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      pro: {
                        ...checkboxCollection.userPermissions.pro,
                        download: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Download</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.pro.restore}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      pro: {
                        ...checkboxCollection.userPermissions.pro,
                        restore: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Restore</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.pro.delete}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      pro: {
                        ...checkboxCollection.userPermissions.pro,
                        delete: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Delete</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={
                  checkboxCollection.userPermissions.pro.activateCustomer
                }
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      pro: {
                        ...checkboxCollection.userPermissions.pro,
                        activateCustomer: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Activate Customer</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.userPermissions.pro.deleteCustomer}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    userPermissions: {
                      ...checkboxCollection.userPermissions,
                      pro: {
                        ...checkboxCollection.userPermissions.pro,
                        deleteCustomer: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Delete Customer</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Accordion>
);

const AdminFileTypesSettings = ({
  checkboxCollection,
  setCheckboxCollection,
}) => (
  <Accordion id="admin-settings-component-container">
    <AccordionSummary id="admin-settings-component-container-top">
      <img
        width="20"
        height="20"
        src="https://img.icons8.com/material-rounded/48/22242E/sort-down.png"
        alt="sort-down"
      />
      <h1 id="admin-settings-component-container-title">File Types</h1>
    </AccordionSummary>
    <div id="admin-settings-component-container-bottom-checkboxes">
      <div id="admin-settings-component-container-bottom-item-container-checkboxes">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Free</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-checkboxes-right">
          <div id="admin-settings-all-checkboxes-container">
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.free.selectAll}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      free: {
                        ...checkboxCollection.fileTypes.free,
                        selectAll: e.target.checked,
                        folder: e.target.checked,
                        image: e.target.checked,
                        video: e.target.checked,
                        audio: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Select All</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.free.folder}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      free: {
                        ...checkboxCollection.fileTypes.free,
                        folder: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Folders</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.free.image}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      free: {
                        ...checkboxCollection.fileTypes.free,
                        image: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Images</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.free.video}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      free: {
                        ...checkboxCollection.fileTypes.free,
                        video: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Videos</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.free.audio}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      free: {
                        ...checkboxCollection.fileTypes.free,
                        audio: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Audio</p>
            </div>
          </div>
        </div>
      </div>
      <div id="admin-settings-component-container-bottom-item-container-checkboxes">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Lite</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-checkboxes-right">
          <div id="admin-settings-all-checkboxes-container">
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.lite.selectAll}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      lite: {
                        ...checkboxCollection.fileTypes.lite,
                        selectAll: e.target.checked,
                        folder: e.target.checked,
                        image: e.target.checked,
                        video: e.target.checked,
                        audio: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Select All</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.lite.folder}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      lite: {
                        ...checkboxCollection.fileTypes.lite,
                        folder: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Folders</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.lite.image}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      lite: {
                        ...checkboxCollection.fileTypes.lite,
                        image: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Images</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.lite.video}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      lite: {
                        ...checkboxCollection.fileTypes.lite,
                        video: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Videos</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.lite.audio}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      lite: {
                        ...checkboxCollection.fileTypes.lite,
                        audio: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Audio</p>
            </div>
          </div>
        </div>
      </div>
      <div id="admin-settings-component-container-bottom-item-container-checkboxes">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Pro</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-checkboxes-right">
          <div id="admin-settings-all-checkboxes-container">
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.pro.selectAll}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      pro: {
                        ...checkboxCollection.fileTypes.pro,
                        selectAll: e.target.checked,
                        folder: e.target.checked,
                        image: e.target.checked,
                        video: e.target.checked,
                        audio: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Select All</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.pro.folder}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      pro: {
                        ...checkboxCollection.fileTypes.pro,
                        folder: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Folders</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.pro.image}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      pro: {
                        ...checkboxCollection.fileTypes.pro,
                        image: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Images</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.pro.video}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      pro: {
                        ...checkboxCollection.fileTypes.pro,
                        video: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Videos</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.fileTypes.pro.audio}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    fileTypes: {
                      ...checkboxCollection.fileTypes,
                      pro: {
                        ...checkboxCollection.fileTypes.pro,
                        audio: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Audio</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Accordion>
);

const AdminReviewsSettings = ({
  checkboxCollection,
  setCheckboxCollection,
}) => (
  <Accordion id="admin-settings-component-container">
    <AccordionSummary id="admin-settings-component-container-top">
      <img
        width="20"
        height="20"
        src="https://img.icons8.com/material-rounded/48/22242E/sort-down.png"
        alt="sort-down"
      />
      <h1 id="admin-settings-component-container-title">Reviews</h1>
    </AccordionSummary>
    <div id="admin-settings-component-container-bottom-checkboxes">
      <div id="admin-settings-component-container-bottom-item-container-checkboxes">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Free</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-checkboxes-right">
          <div id="admin-settings-all-checkboxes-container">
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.reviews.free.selectAll}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    reviews: {
                      ...checkboxCollection.reviews,
                      free: {
                        ...checkboxCollection.reviews.free,
                        selectAll: e.target.checked,
                        comments: e.target.checked,
                        important: e.target.checked,
                        approval: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Select All</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.reviews.free.comments}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    reviews: {
                      ...checkboxCollection.reviews,
                      free: {
                        ...checkboxCollection.reviews.free,
                        comments: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Comments</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.reviews.free.important}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    reviews: {
                      ...checkboxCollection.reviews,
                      free: {
                        ...checkboxCollection.reviews.free,
                        important: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Important</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.reviews.free.approval}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    reviews: {
                      ...checkboxCollection.reviews,
                      free: {
                        ...checkboxCollection.reviews.free,
                        approval: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Approval</p>
            </div>
          </div>
        </div>
      </div>
      <div id="admin-settings-component-container-bottom-item-container-checkboxes">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Lite</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-checkboxes-right">
          <div id="admin-settings-all-checkboxes-container">
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.reviews.lite.selectAll}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    reviews: {
                      ...checkboxCollection.reviews,
                      lite: {
                        ...checkboxCollection.reviews.lite,
                        selectAll: e.target.checked,
                        comments: e.target.checked,
                        important: e.target.checked,
                        approval: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Select All</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.reviews.lite.comments}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    reviews: {
                      ...checkboxCollection.reviews,
                      lite: {
                        ...checkboxCollection.reviews.lite,
                        comments: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Comments</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.reviews.lite.important}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    reviews: {
                      ...checkboxCollection.reviews,
                      lite: {
                        ...checkboxCollection.reviews.lite,
                        important: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Important</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.reviews.lite.approval}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    reviews: {
                      ...checkboxCollection.reviews,
                      lite: {
                        ...checkboxCollection.reviews.lite,
                        approval: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Approval</p>
            </div>
          </div>
        </div>
      </div>
      <div id="admin-settings-component-container-bottom-item-container-checkboxes">
        <div id="admin-settings-component-container-bottom-item-container-left">
          <p id="admin-settings-component-container-text">Pro</p>
        </div>
        <div id="admin-settings-component-container-bottom-item-container-checkboxes-right">
          <div id="admin-settings-all-checkboxes-container">
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.reviews.pro.selectAll}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    reviews: {
                      ...checkboxCollection.reviews,
                      pro: {
                        ...checkboxCollection.reviews.pro,
                        selectAll: e.target.checked,
                        comments: e.target.checked,
                        important: e.target.checked,
                        approval: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Select All</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.reviews.pro.comments}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    reviews: {
                      ...checkboxCollection.reviews,
                      pro: {
                        ...checkboxCollection.reviews.pro,
                        comments: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Comments</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.reviews.pro.important}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    reviews: {
                      ...checkboxCollection.reviews,
                      pro: {
                        ...checkboxCollection.reviews.pro,
                        important: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Important</p>
            </div>
            <div id="admin-settings-checkbox-container">
              <Checkbox
                checked={checkboxCollection.reviews.pro.approval}
                onChange={(e) => {
                  setCheckboxCollection({
                    ...checkboxCollection,
                    reviews: {
                      ...checkboxCollection.reviews,
                      pro: {
                        ...checkboxCollection.reviews.pro,
                        approval: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <p id="admin-settings-checkbox-label">Approval</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Accordion>
);

const AdminSettings = () => {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const componentsList = [
    "Storage",
    "Client Creation",
    "Client Permissions",
    "User Permissions",
    "File Types",
    "Reviews",
  ];
  const [searchText, setSearchText] = useState("");
  const [filteredComponents, setFilteredComponents] = useState([]);
  const [settings, setSettings] = useState({});
  const [storage, setStorage] = useState({
    free: {
      storage: 0,
      storageUnit: "GB",
    },
    lite: {
      storage: 0,
      storageUnit: "GB",
    },
    pro: {
      storage: 0,
      storageUnit: "GB",
    },
  });

  const [clientCreation, setClientCreation] = useState({
    free: {
      users: 0,
    },
    lite: {
      users: 0,
    },
    pro: {
      users: 0,
    },
  });

  const [checkboxCollection, setCheckboxCollection] = useState({
    clientPermissions: {
      free: {
        selectAll: true,
        view: true,
        restore: true,
        delete: true,
      },
      lite: {
        selectAll: true,
        view: true,
        restore: true,
        delete: true,
      },
      pro: {
        selectAll: true,
        view: true,
        restore: true,
        delete: true,
      },
    },

    userPermissions: {
      free: {
        selectAll: true,
        view: true,
        upload: true,
        download: true,
        restore: true,
        delete: true,
        activateCustomer: true,
        deleteCustomer: true,
      },
      lite: {
        selectAll: true,
        view: true,
        upload: true,
        download: true,
        restore: true,
        delete: true,
        activateCustomer: true,
        deleteCustomer: true,
      },
      pro: {
        selectAll: true,
        view: true,
        upload: true,
        download: true,
        restore: true,
        delete: true,
        activateCustomer: true,
        deleteCustomer: true,
      },
    },

    fileTypes: {
      free: {
        selectAll: true,
        folder: true,
        image: true,
        video: true,
        audio: true,
      },
      lite: {
        selectAll: true,
        folder: true,
        image: true,
        video: true,
        audio: true,
      },
      pro: {
        selectAll: true,
        folder: true,
        image: true,
        video: true,
        audio: true,
      },
    },

    reviews: {
      free: {
        selectAll: true,
        comments: true,
        important: true,
        approval: true,
      },
      lite: {
        selectAll: true,
        comments: true,
        important: true,
        approval: true,
      },
      pro: {
        selectAll: true,
        comments: true,
        important: true,
        approval: true,
      },
    },
  });

  useEffect(() => {
    GetPlanSettings();
  }, []);

  useEffect(() => {
    filterComponents(searchText);
  }, [searchText]);



  const filterComponents = (searchText) => {
    const filteredComps = componentsList.filter((component) =>
      component.toLowerCase().includes(searchText.toLowerCase())
    );
    return setFilteredComponents(filteredComps);
  };

  const onUpdate = () => {
    UpdateSettings();
  };

  const incrementStorage = (tier) => {
    if (tier === "free") {
      setStorage({
        ...storage,
        free: {
          ...storage.free,
          storage: storage.free.storage + 1,
        },
      });
    } else if (tier === "lite") {
      setStorage({
        ...storage,
        lite: {
          ...storage.lite,
          storage: storage.lite.storage + 1,
        },
      });
    } else if (tier === "pro") {
      setStorage({
        ...storage,
        pro: {
          ...storage.pro,
          storage: storage.pro.storage + 1,
        },
      });
    }
  };

  const decrementStorage = (tier) => {
    if (tier === "free") {
      setStorage({
        ...storage,
        free: {
          ...storage.free,
          storage: storage.free.storage - 1,
        },
      });
    }
    if (tier === "lite") {
      setStorage({
        ...storage,
        lite: {
          ...storage.lite,
          storage: storage.lite.storage - 1,
        },
      });
    }
    if (tier === "pro") {
      setStorage({
        ...storage,
        pro: {
          ...storage.pro,
          storage: storage.pro.storage - 1,
        },
      });
    }
  };

  const incrementUsers = (tier) => {
    if (tier === "free") {
      setClientCreation({
        ...clientCreation,
        free: {
          ...clientCreation.free,
          users: clientCreation.free.users + 1,
        },
      });
    }
    if (tier === "lite") {
      setClientCreation({
        ...clientCreation,
        lite: {
          ...clientCreation.lite,
          users: clientCreation.lite.users + 1,
        },
      });
    }
    if (tier === "pro") {
      setClientCreation({
        ...clientCreation,
        pro: {
          ...clientCreation.pro,
          users: clientCreation.pro.users + 1,
        },
      });
    }
  };

  const decrementUsers = (tier) => {
    if (tier === "free") {
      setClientCreation({
        ...clientCreation,
        free: {
          ...clientCreation.free,
          users: clientCreation.free.users - 1,
        },
      });
    }
    if (tier === "lite") {
      setClientCreation({
        ...clientCreation,
        lite: {
          ...clientCreation.lite,
          users: clientCreation.lite.users - 1,
        },
      });
    }
    if (tier === "pro") {
      setClientCreation({
        ...clientCreation,
        pro: {
          ...clientCreation.pro,
          users: clientCreation.pro.users - 1,
        },
      });
    }
  };

  const GetPlanSettings = async () => {
    try {
      increaseLoadingCounter();
      const response = await axios.get(
        `${backend_api}/api/admin/getPlanSettings`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get("admin_access_token")}`,
          },
        }
      );

      if (response.status === 200) {
        setSettings(response.data);
        setPlanSettingsToTheStates(response.data?.planSettings);
      }
    } catch (err) {
      if(err.response.status === 401) {
        Cookies.remove("admin_access_token");
        window.location.href = "/error404";
      }
    } finally {
      decreaseLoadingCounter();
    }
  };

  const UpdateSettings = async () => {
    try {
      increaseLoadingCounter();
      const response = await axios.put(
        `${backend_api}/api/admin/updatePlanSettings`,
        {
          free: {
            price: "0",
            storageLimit: storage.free.storage, //in GB
            allowedActions: {
              view: checkboxCollection.userPermissions.free.view,
              upload: checkboxCollection.userPermissions.free.upload,
              download: checkboxCollection.userPermissions.free.download,
              restore: checkboxCollection.userPermissions.free.restore,
              delete: checkboxCollection.userPermissions.free.delete,
              activateCustomer:
                checkboxCollection.userPermissions.free.activateCustomer,
              deleteCustomer:
                checkboxCollection.userPermissions.free.deleteCustomer,
            },
            allowedFileTypes: {
              image: checkboxCollection.fileTypes.free.image,
              video: checkboxCollection.fileTypes.free.video,
              folder: checkboxCollection.fileTypes.free.folder,
              audio: checkboxCollection.fileTypes.free.audio,
            },
            customersLimit: clientCreation.free.users,
            customerPermissions: {
              view: checkboxCollection.clientPermissions.free.view,
              restore: checkboxCollection.clientPermissions.free.restore,
              delete: checkboxCollection.clientPermissions.free.delete,
            },
            reviews: {
              isApproved: checkboxCollection.reviews.free.approval,
              isImportant: checkboxCollection.reviews.free.important,
              comment: checkboxCollection.reviews.free.comments,
            },
            description: "", //optional now
          },

          lite: {
            price: "0",
            storageLimit: storage.lite.storage, //in GB
            allowedActions: {
              view: checkboxCollection.userPermissions.lite.view,
              upload: checkboxCollection.userPermissions.lite.upload,
              download: checkboxCollection.userPermissions.lite.download,
              restore: checkboxCollection.userPermissions.lite.restore,
              delete: checkboxCollection.userPermissions.lite.delete,
              activateCustomer:
                checkboxCollection.userPermissions.lite.activateCustomer,
              deleteCustomer:
                checkboxCollection.userPermissions.lite.deleteCustomer,
            },
            allowedFileTypes: {
              image: checkboxCollection.fileTypes.lite.image,
              video: checkboxCollection.fileTypes.lite.video,
              folder: checkboxCollection.fileTypes.lite.folder,
              audio: checkboxCollection.fileTypes.lite.audio,
            },
            customersLimit: clientCreation.lite.users,
            customerPermissions: {
              view: checkboxCollection.clientPermissions.lite.view,
              restore: checkboxCollection.clientPermissions.lite.restore,
              delete: checkboxCollection.clientPermissions.lite.delete,
            },
            reviews: {
              isApproved: checkboxCollection.reviews.lite.approval,
              isImportant: checkboxCollection.reviews.lite.important,
              comment: checkboxCollection.reviews.lite.comments,
            },
            description: "", //optional now
          },

          pro: {
            price: "0",
            storageLimit: storage.pro.storage, //in GB
            allowedActions: {
              view: checkboxCollection.userPermissions.pro.view,
              upload: checkboxCollection.userPermissions.pro.upload,
              download: checkboxCollection.userPermissions.pro.download,
              restore: checkboxCollection.userPermissions.pro.restore,
              delete: checkboxCollection.userPermissions.pro.delete,
              activateCustomer:
                checkboxCollection.userPermissions.pro.activateCustomer,
              deleteCustomer:
                checkboxCollection.userPermissions.pro.deleteCustomer,
            },
            allowedFileTypes: {
              image: checkboxCollection.fileTypes.pro.image,
              video: checkboxCollection.fileTypes.pro.video,
              folder: checkboxCollection.fileTypes.pro.folder,
              audio: checkboxCollection.fileTypes.pro.audio,
            },
            customersLimit: clientCreation.pro.users,
            customerPermissions: {
              view: checkboxCollection.clientPermissions.pro.view,
              restore: checkboxCollection.clientPermissions.pro.restore,
              delete: checkboxCollection.clientPermissions.pro.delete,
            },
            reviews: {
              isApproved: checkboxCollection.reviews.pro.approval,
              isImportant: checkboxCollection.reviews.pro.important,
              comment: checkboxCollection.reviews.pro.comments,
            },
            description: "", //optional now
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get("admin_access_token")}`,
          },
        }
      );


      if (response.status === 200) {
        // window.location.reload();
        toast.success("Settings Updated Successfully");
        GetPlanSettings();
      } else {
        if (
          response.status === 404 ||
          response.status === 401 ||
          response.status === 400
        ) {
          toast.error("Something went wrong");
        } else if (response.status === 500) {
          toast.error("Server Error");
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      if (
        error.response.status === 404 ||
        error.response.status === 401 ||
        error.response.status === 400
      ) {
        toast.error("Something went wrong");
      } else if (error.response.status === 500) {
        toast.error("Server Error");
      } else {
        toast.error(error.message);
      }
    } finally {
      decreaseLoadingCounter();
    }
  };

  const setPlanSettingsToTheStates = (settings) => {

    setStorage({
      free: {
        storage: settings?.free?.storageLimit / 1024 / 1024 / 1024 || 0,
      },
      lite: {
        storage: settings?.lite?.storageLimit / 1024 / 1024 / 1024 || 0,
      },
      pro: {
        storage: settings?.pro?.storageLimit / 1024 / 1024 / 1024 || 0,
      },
    });

    setClientCreation({
      free: {
        users: settings?.free?.customersLimit || 0,
      },
      lite: {
        users: settings?.lite?.customersLimit || 0,
      },
      pro: {
        users: settings?.pro?.customersLimit || 0,
      },
    });

    setCheckboxCollection({
      clientPermissions: {
        free: {
          selectAll:
            settings?.free?.customerPermissions.view &&
            settings?.free?.customerPermissions.restore &&
            settings?.free?.customerPermissions.delete,
          view: settings?.free?.customerPermissions.view,
          restore: settings?.free?.customerPermissions.restore,
          delete: settings?.free?.customerPermissions.delete,
        },
        lite: {
          selectAll:
            settings?.lite?.customerPermissions.view &&
            settings?.lite?.customerPermissions.restore &&
            settings?.lite?.customerPermissions.delete,
          view: settings?.lite?.customerPermissions.view,
          restore: settings?.lite?.customerPermissions.restore,
          delete: settings?.lite?.customerPermissions.delete,
        },
        pro: {
          selectAll:
            settings?.pro?.customerPermissions.view &&
            settings?.pro?.customerPermissions.restore &&
            settings?.pro?.customerPermissions.delete,
          view: settings?.pro?.customerPermissions.view,
          restore: settings?.pro?.customerPermissions.restore,
          delete: settings?.pro?.customerPermissions.delete,
        },
      },

      userPermissions: {
        free: {
          selectAll:
            settings?.free?.allowedActions.view &&
            settings?.free?.allowedActions.upload &&
            settings?.free?.allowedActions.download &&
            settings?.free?.allowedActions.restore &&
            settings?.free?.allowedActions.delete &&
            settings?.free?.allowedActions.activateCustomer &&
            settings?.free?.allowedActions.deleteCustomer,
          view: settings?.free?.allowedActions.view,
          upload: settings?.free?.allowedActions.upload,
          download: settings?.free?.allowedActions.download,
          restore: settings?.free?.allowedActions.restore,
          delete: settings?.free?.allowedActions.delete,
          activateCustomer: settings?.free?.allowedActions.activateCustomer,
          deleteCustomer: settings?.free?.allowedActions.deleteCustomer,
        },
        lite: {
          selectAll:
            settings?.lite?.allowedActions.view &&
            settings?.lite?.allowedActions.upload &&
            settings?.lite?.allowedActions.restore &&
            settings?.lite?.allowedActions.delete &&
            settings?.lite?.allowedActions.activateCustomer &&
            settings?.lite?.allowedActions.deleteCustomer,
          view: settings?.lite?.allowedActions.view,
          upload: settings?.lite?.allowedActions.upload,
          download: settings?.lite?.allowedActions.download,
          restore: settings?.lite?.allowedActions.restore,
          delete: settings?.lite?.allowedActions.delete,
          activateCustomer: settings?.lite?.allowedActions.activateCustomer,
          deleteCustomer: settings?.lite?.allowedActions.deleteCustomer,
        },
        pro: {
          selectAll:
            settings?.pro?.allowedActions.view &&
            settings?.pro?.allowedActions.upload &&
            settings?.pro?.allowedActions.restore &&
            settings?.pro?.allowedActions.delete &&
            settings?.pro?.allowedActions.activateCustomer &&
            settings?.pro?.allowedActions.deleteCustomer,
          view: settings?.pro?.allowedActions.view,
          upload: settings?.pro?.allowedActions.upload,
          download: settings?.pro?.allowedActions.download,
          restore: settings?.pro?.allowedActions.restore,
          delete: settings?.pro?.allowedActions.delete,
          activateCustomer: settings?.pro?.allowedActions.activateCustomer,
          deleteCustomer: settings?.pro?.allowedActions.deleteCustomer,
        },
      },

      fileTypes: {
        free: {
          selectAll:
            settings?.free?.allowedFileTypes.image &&
            settings?.free?.allowedFileTypes.video &&
            settings?.free?.allowedFileTypes.audio &&
            settings?.free?.allowedFileTypes.folder,
          folder: settings?.free?.allowedFileTypes.folder,
          image: settings?.free?.allowedFileTypes.image,
          video: settings?.free?.allowedFileTypes.video,
          audio: settings?.free?.allowedFileTypes.audio,
        },
        lite: {
          selectAll:
            settings?.lite?.allowedFileTypes.image &&
            settings?.lite?.allowedFileTypes.video &&
            settings?.lite?.allowedFileTypes.audio &&
            settings?.lite?.allowedFileTypes.folder,
          folder: settings?.lite?.allowedFileTypes.folder,
          image: settings?.lite?.allowedFileTypes.image,
          video: settings?.lite?.allowedFileTypes.video,
          audio: settings?.lite?.allowedFileTypes.audio,
        },
        pro: {
          selectAll:
            settings?.pro?.allowedFileTypes.image &&
            settings?.pro?.allowedFileTypes.video &&
            settings?.pro?.allowedFileTypes.audio &&
            settings?.pro?.allowedFileTypes.folder,
          folder: settings?.pro?.allowedFileTypes.folder,
          image: settings?.pro?.allowedFileTypes.image,
          video: settings?.pro?.allowedFileTypes.video,
          audio: settings?.pro?.allowedFileTypes.audio,
        },
      },

      reviews: {
        free: {
          selectAll:
            settings?.free?.reviews.isApproved &&
            settings?.free?.reviews.isImportant &&
            settings?.free?.reviews.comment,
          comments: settings?.free?.reviews.comment,
          important: settings?.free?.reviews.isImportant,
          approval: settings?.free?.reviews.isApproved,
        },
        lite: {
          selectAll:
            settings?.lite?.reviews.isApproved &&
            settings?.lite?.reviews.isImportant &&
            settings?.lite?.reviews.comment,
          comments: settings?.lite?.reviews.comment,
          important: settings?.lite?.reviews.isImportant,
          approval: settings?.lite?.reviews.isApproved,
        },
        pro: {
          selectAll:
            settings?.pro?.reviews.isApproved &&
            settings?.pro?.reviews.isImportant &&
            settings?.pro?.reviews.comment,
          comments: settings?.pro?.reviews.comment,
          important: settings?.pro?.reviews.isImportant,
          approval: settings?.pro?.reviews.isApproved,
        },
      },
    });
  };

  // DUMMY API BODY
  // {
  //   "free": {
  //       "price": "0",
  //       "storageLimit": "1", //in GB
  //       "allowedActions": { "view": true, "upload": true, "restore": true, "delete": true },
  //       "allowedFileTypes": { "image": true, "video": false, "files": false, "folders": false, "embed": false },
  //       "customersLimit": "1",
  //       "customerPermissions": { "view": true, "download": true, "delete": true },
  //       "reviews": { "isApproved": "true", "isImportant": true, "comment": true },
  //       "description": "" //optional now
  //   },
  //   "lite": {
  //       "price": "0",
  //       "storageLimit": "10", //in GB
  //       "allowedActions": { "view": true, "upload": true, "restore": true, "delete": true },
  //       "allowedFileTypes": { "image": true, "video": false, "files": false, "folders": false, "embed": false },
  //       "customersLimit": "10",
  //       "customerPermissions": { "view": true, "download": true, "delete": true },
  //       "reviews": { "isApproved": "true", "isImportant": true, "comment": true },
  //       "description": "" //optional now
  //   },
  //   "pro": {
  //       "price": "0",
  //       "storageLimit": "50", //in GB
  //       "allowedActions": { "view": true, "upload": true, "restore": true, "delete": true },
  //       "allowedFileTypes": { "image": true, "video": false, "files": false, "folders": false, "embed": false },
  //       "customersLimit": "100",
  //       "customerPermissions": { "view": true, "download": true, "delete": true },
  //       "reviews": { "isApproved": "true", "isImportant": true, "comment": true },
  //       "description": "" //optional now
  //   }
  // }

  return (
    <>
      <AdminSettingsTopbar
        setSearchText={setSearchText}
        searchText={searchText}
        onUpdate={onUpdate}
      />
      {filteredComponents.length > 0 ? (
        <>
          {filteredComponents.map((component) =>
            component === "Storage" ? (
              <AdminStorageSettings
                incrementStorage={incrementStorage}
                decrementStorage={decrementStorage}
                storage={storage}
                setStorage={setStorage}
              />
            ) : component === "Client Creation" ? (
              <AdminClientCreationSettings
                incrementUsers={incrementUsers}
                decrementUsers={decrementUsers}
                clientCreation={clientCreation}
                setClientCreation={setClientCreation}
              />
            ) : component === "Client Permissions" ? (
              <AdminClientPermissionsSettings
                checkboxCollection={checkboxCollection}
                setCheckboxCollection={setCheckboxCollection}
              />
            ) : component === "User Permissions" ? (
              <AdminUserPermissionsSettings
                checkboxCollection={checkboxCollection}
                setCheckboxCollection={setCheckboxCollection}
              />
            ) : component === "File Types" ? (
              <AdminFileTypesSettings
                checkboxCollection={checkboxCollection}
                setCheckboxCollection={setCheckboxCollection}
              />
            ) : component === "Reviews" ? (
              <AdminReviewsSettings
                checkboxCollection={checkboxCollection}
                setCheckboxCollection={setCheckboxCollection}
              />
            ) : null
          )}
        </>
      ) : (
        <>
          <AdminStorageSettings
            incrementStorage={incrementStorage}
            decrementStorage={decrementStorage}
            storage={storage}
            setStorage={setStorage}
          />
          <AdminClientCreationSettings
            incrementUsers={incrementUsers}
            decrementUsers={decrementUsers}
            clientCreation={clientCreation}
            setClientCreation={setClientCreation}
          />
          <AdminClientPermissionsSettings
            checkboxCollection={checkboxCollection}
            setCheckboxCollection={setCheckboxCollection}
          />
          <AdminUserPermissionsSettings
            checkboxCollection={checkboxCollection}
            setCheckboxCollection={setCheckboxCollection}
          />
          <AdminFileTypesSettings
            checkboxCollection={checkboxCollection}
            setCheckboxCollection={setCheckboxCollection}
          />
          <AdminReviewsSettings
            checkboxCollection={checkboxCollection}
            setCheckboxCollection={setCheckboxCollection}
          />
        </>
      )}
    </>
  );
};

export default AdminSettings;
