import { createReducer } from "@reduxjs/toolkit";

export const clientReducer = createReducer({}, (builder) => {
  builder

    // LOADING REQUEST
    .addCase("clientLoginLoading", (state) => {
      state.loading = true;
    })
    .addCase("getFirstFileLoading", (state) => {
      state.loading = true;
    })
    .addCase("clientURLLoginLoading", (state) => {
      state.loading = true;
    })
    .addCase("clientFilesFoldersLoading", (state) => {
      state.loading = true;
    })
    .addCase("clientDetailsLoading", (state) => {
      state.loading = true;
    })
    .addCase("moveClientFileToTrashLoading", (state) => {
      state.loading = true;
    })
    .addCase("restoreFromTrashLoading", (state) => {
      state.loading = true;
    })
    .addCase("clientApproveStatusLoading", (state) => {
      state.loading = true;
    })
    .addCase("clientImportantStatusLoading", (state) => {
      state.loading = true;
    })
    .addCase("clientCommentLoading", (state) => {
      state.loading = true;
    })
    .addCase("getClientTrashFilesFoldersLoading", (state) => {
      state.loading = true;
    })
    .addCase("deleteclientmultiplefileLoading", (state) => {
      state.loading = true;
    })

    // SUCCESS REQUEST
    .addCase("clientLoginSuccess", (state, action) => {
      state.loading = false;
      state.clientLogin = action.payload;
    })
    .addCase("getFirstFileSuccess", (state, action) => {
      state.loading = false;
      state.firstFile = action.payload;
    })
    .addCase("clientURLLoginSuccess", (state, action) => {
      state.loading = false;
      state.clientURL = action.payload;
    })
    .addCase("clientFilesFoldersSuccess", (state, action) => {
      state.loading = false;
      state.clientFilesFolders = action.payload;
    })
    .addCase("clientDetailsSuccess", (state, action) => {
      state.loading = false;
      state.clientDetails = action.payload;
    })
    .addCase("moveClientFileToTrashSuccess", (state, action) => {
      state.loading = false;
      state.fileToTrash = action.payload;
    })
    .addCase("restoreFromTrashSuccess", (state, action) => {
      state.loading = false;
      state.restoreFiles = action.payload;
    })
    .addCase("clientApproveStatusSuccess", (state, action) => {
      state.loading = false;
      state.clientApproveStatus = action.payload;
    })
    .addCase("clientImportantStatusSuccess", (state, action) => {
      state.loading = false;
      state.clientImportantStatus = action.payload;
    })
    .addCase("clientCommentSuccess", (state, action) => {
      state.loading = false;
      state.clientComment = action.payload;
    })
    .addCase("getClientTrashFilesFoldersSuccess", (state, action) => {
      state.loading = false;
      state.clienttrashFilesFolders = action.payload;
    })
    .addCase("deleteclientmultiplefileSuccess", (state, action) => {
      state.loading = false;
      state.deleteFiles = action.payload;
    })

    // FAIL REQUEST
    .addCase("clientLoginFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("clientURLLoginFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("getFirstFileFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("clientFilesFoldersFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("clientDetailsFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("moveClientFileToTrashFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("restoreFromTrashFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("clientApproveStatusFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("clientImportantStatusFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("clientCommentFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("getClientTrashFilesFoldersFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("deleteclientmultiplefileFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});
