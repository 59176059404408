import React, { useEffect } from 'react'
import Header from "../../components/Header/Header";
import "./homeStyles.css";
import { Button } from '@mui/material';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const PaymentSuccessfull = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get('success');
    const  handleNavigateToHome = () => {
        navigate('/home');
    }
    useEffect(() => {
        setTimeout(() => {
            handleNavigateToHome();
        }, 10000);
    }, []);
    
    return (
        <div className="home-page">
            <Header />
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div style={{ width: '50%', height: window.innerHeight, paddingTop: '40px' }}>
                    <Button>
                        <KeyboardBackspaceOutlinedIcon style={{ color: '#3F414E' }} />
                    </Button>
                    <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '10%', flexDirection: 'column' }}>
                        {
                            success==='true'?
                                <CheckCircleOutlinedIcon style={{ color: '#5DCE7B', fontSize: '120px', }} />
                            :
                                <HighlightOffOutlinedIcon style={{ color: 'red', fontSize: '120px', }} />
                        }
                        <h1 style={{ color: success==='true'?'#5DCE7B':'red', margin: 0 }}>{success==='true'?'Success':'Failed'}</h1>
                        <h2 style={{ color: '#000', margin: 0, textAlign: 'center' }}>Your Payment is {success==='true'?'Successfull':'Failed'}</h2>
                        <Button onClick={handleNavigateToHome} style={{ margin: '20px', marginRight: '20px', marginTop: '40px', backgroundColor: '#EA4133', borderRadius: '20px', alignSelf: 'center', width: '60%' }}>
                            <p className='p' style={{ fontSize: '12px', }}>Go to Dashboard</p>
                        </Button>
                        <p style={{ fontSize: '12px', color: '#000' }}>You will be automatically redirected to home in 10 sec</p>
                    </div>

                </div>
                <div style={{flexDirection:'column',alignItems:'center',justifyContent:'center',display:'flex', width: '50%', height: window.innerHeight, backgroundColor: '#FEF2DF', }}>
                    <img src={require('../../assets/images/Logo21.png')} style={{height:'120px', width:'120px'}} alt="home background" />
                    <p style={{color:'#000', fontSize:'25px'}}>GALLERY <strong>STUDIO</strong> PRO</p>
                </div>

            </div>
        </div>
    );
};

export default PaymentSuccessfull;