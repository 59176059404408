import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import AdminSidebar from "../../components/AdminSidebar/AdminSidebar";
import AdminSettings from "./AdminSettings/AdminSettings";
import AdminClientDetails from "./AdminClientDetails/AdminClientDetails";
import { useSelector } from "react-redux";
import AdminDashboardHome from "../Admin/AdminDashboardHome";
import AdminUserList from "../Admin/AdminUserList";
import AdminInactiveUserList from "../Admin/AdminInactiveUserList";
import AdminAddUser from "../Admin/AdminAddUser";

const AdminDashboard = () => {
  const { sidebarOption } = useSelector((state) => state.sidebar);
  const path = window.location.pathname;
  const [selectedSidebarOption, setSelectedSidebarOption] =
    useState("dashboard");

  useEffect(() => {
    setSelectedSidebarOption(sidebarOption);
  }, [sidebarOption]);
  
  return (
    <div className="home-page">
      <AdminHeader />
      <AdminSidebar />
      <div id="sidebar-placeholder"></div>
      <div className="home-main-container">
        {selectedSidebarOption === "dashboard" ? (
          <AdminDashboardHome />
        ) : selectedSidebarOption === "users" ? (
          // <AdminClientDetails />
          <>
            {path === "/admin-dashboard" ? (
              <AdminUserList />
            ) : path === "/admin/user-details" ? (
              <AdminClientDetails />
            ) : (
              <AdminUserList />
            )}
          </>
        ) : selectedSidebarOption === "settings" ? (
          <AdminSettings />
        ) : selectedSidebarOption === "inactive" ? (
          <>
            {path === "/admin-dashboard" ? (
              <AdminInactiveUserList />
            ) : path === "/admin/user-details" ? (
              <AdminClientDetails />
            ) : (
              <AdminInactiveUserList />
            )}
          </>
        ) : sidebarOption === "createUser" ? (
          <AdminAddUser />
        ) : (
          <AdminDashboardHome />
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
