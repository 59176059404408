import React, { useContext, useEffect, useState } from "react";
import "./loginStyles.css";
import logo from "../../assets/images/GSPro-Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../redux/actions/userAction";
import loginImg from "../../assets/images/LoginAvatar.svg";
import { LoadingContext } from "../Home/ContextProvider";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

const Registration = () => {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const { userLogin } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const emailValidation = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onPressRegister = () => {
    if (
      userDetails.email === "" ||
      userDetails.password === "" ||
      userDetails.name === "" ||
      userDetails.confirmPassword === ""
    ) {
      toast.error("Please enter all the details");
    } else if (!emailValidation(userDetails.email)) {
      // toast.error("Please enter a valid email");
      setEmailError(true);
    } else if (userDetails.password.length < 6) {
      setEmailError(false);
      setPasswordError(true);
    } else if (userDetails.password !== userDetails.confirmPassword) {
      setEmailError(false);
      setPasswordError(false);
      toast.error("Passwords should match !!");
    } else {
      setEmailError(false);
      setPasswordError(false);
      increaseLoadingCounter();
      dispatch(registerUser(userDetails, navigate)).then(() => {
        decreaseLoadingCounter();
      });
    }
  };

  useEffect(() => {
    const previousToken = Cookies.get("access_token");
    if(previousToken){
      try {
        const decodedToken = jwtDecode(previousToken);
        if (decodedToken?.exp * 1000 >= Date.now()) navigate("/home");
      } catch (error) {}
    }
  }, [userLogin]);

  return (
    <div className="login-page">
      <div className="login-page-inner-container container-left">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <h1 id="title-text">Hi Professional, Welcome </h1>
          <h1 id="title-text">to Gallery Studio Pro</h1>
        </div>
        <p id="subtitle-text">
          Explore the app, Upload your client image and share with them for approval workflows
        </p>
        <img src={loginImg} alt="login" id="login-img" />
      </div>
      <div className="login-page-inner-container container-right">
        <div className="login-page-form-container">
          <div className="login-page-form-container-top">
            <BackButton />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img src={logo} alt="google" id="logo" style={{height: '3rem'}} />
            </div>
          </div>
          <div className="login-page-form-container-middle">
            <h1 id="welcome-back">Register</h1>
            <input
              type="text"
              placeholder="Name"
              className="login-form-input"
              value={userDetails.name}
              onChange={(e) =>
                setUserDetails({ ...userDetails, name: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Email Address"
              className="login-form-input"
              value={userDetails.email}
              onChange={(e) =>
                setUserDetails({ ...userDetails, email: e.target.value })
              }
            />
            {emailError && (
              <span style={{ color: "red" }}>Please enter a valid email</span>
            )}
            <input
              type="password"
              placeholder="Password"
              className="login-form-input"
              value={userDetails.password}
              onChange={(e) =>
                setUserDetails({ ...userDetails, password: e.target.value })
              }
            />
            {passwordError && (
              <span style={{ color: "red" }}>
                Password must be 6 characters long
              </span>
            )}
            <input
              type="password"
              placeholder="Re-type Password"
              className="login-form-input"
              value={userDetails.confirmPassword}
              onChange={(e) =>
                setUserDetails({
                  ...userDetails,
                  confirmPassword: e.target.value,
                })
              }
            />
            <button className="login-btn main-btn" onClick={onPressRegister}>
              Register
            </button>
          </div>
          <div className="login-page-form-container-bottom">
            <Link
              to="/login"
              style={{
                textDecoration: "none",
              }}
            >
              <p id="continue-with-email">Already have an account? Sign in</p>
            </Link>
            <div id="line" />
            <p className="poweredBy">Powered By : GalleryStudioPro</p>
          </div>
        </div>
      </div>
      <div id="bottom-div"></div>
    </div>
  );
};

export default Registration;
