import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./adminSettingsStyles.css";
import { Button } from "@mui/material";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { setSidebarOption } from "../../redux/actions/sidebarAction";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/actions/adminActions";

export default function AdminInactiveUserList() {
  const { adminAllUsers } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [inactiveUsers, setInactiveUsers] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const inactive = allUsers.filter((user) => user.status === "inactive");
    setInactiveUsers(inactive || []);
    setFilteredUsers(inactive || []);
  }, [allUsers]);

  useEffect(() => {
    if (adminAllUsers?.users) {
      setAllUsers(adminAllUsers?.users);
    }
  }, [adminAllUsers]);

  const filterUsers = () => {
    if (searchText) {
      const filteredUsers = inactiveUsers?.filter((user) =>
        user?.name?.toLowerCase().includes(searchText?.toLowerCase())
      );
      setFilteredUsers(filteredUsers);
    } else {
      setFilteredUsers(inactiveUsers);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "20px",
        paddingRight: "20px",
        paddingLeft: "20px",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          // min-height: '85vh',
          width: "100%",
          backgroundColor: " #fff",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <AdminSettingsTopbar
          setSearchText={setSearchText}
          searchText={searchText}
          filterUsers={filterUsers}
        />

        <div style={{ marginTop: "20px", width: "100%" }}>
          {inactiveUsers.length > 0 &&
            inactiveUsers?.slice((page - 1) * 5, page * 5).map((user, index) => (
              <div
                key={index}
                style={{
                  border: "1px solid #E9E9E9",
                  paddingTop: "3px",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/admin/user-details?userId=${user?._id}`);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                  }}
                >
                  <span
                    style={{
                      color: "#3F414E",
                      fontFamily: "Nunito",
                      width: "200px",
                    }}
                  >
                    {user?.name}
                  </span>
                  <span
                    style={{
                      color: "#FA6E5A",
                      fontFamily: "Nunito",
                      fontSize: "14px",
                      fontWeight: 800,
                      textTransform: "uppercase",
                    }}
                  >
                    {user?.subscriptionType}
                  </span>
                  <span
                    style={{
                      color: "#FA6E5A",
                      fontFamily: "Nunito",
                      fontSize: "14px",
                      fontWeight: 800,
                      marginLeft: "auto",
                      textTransform: "uppercase",
                    }}
                  >
                    {user?.status}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#FFF6F4",
                    padding: "5px",
                  }}
                >
                  <span
                    style={{
                      color: "#3F414E",
                      fontFamily: "Nunito",
                      fontSize: "10px",
                    }}
                  >
                    Created Date :{" "}
                    {moment(user?.createdAt).format("DD MMM YYYY")}
                  </span>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      marginLeft: "auto",
                    }}
                  >
                    <img
                      src={require("../../assets/images/icon _edit 2_.png")}
                      style={{
                        height: "15px",
                        width: "15px",
                        marginRight: "5px",
                        marginBottom: "2px",
                      }}
                      alt="Logo"
                    />
                    <span
                      style={{
                        borderBottom: "2px solid #0094FF",
                        padding: 0,
                        color: "#0094FF",
                        fontSize: "12px",
                      }}
                    >
                      Edit
                    </span>
                  </Button>
                  <Button
                    style={{ textTransform: "capitalize", marginLeft: "8px" }}
                  >
                    <img
                      src={require("../../assets/images/icon _trash_.png")}
                      style={{
                        height: "15px",
                        width: "15px",
                        marginRight: "5px",
                        marginBottom: "2px",
                      }}
                      alt="Logo"
                    />
                    <span
                      style={{
                        borderBottom: "2px solid #FF6A6A",
                        padding: 0,
                        color: "#FF6A6A",
                        fontSize: "12px",
                      }}
                    >
                      Delete
                    </span>
                  </Button>
                </div>
              </div>
            ))}
        </div>

        <div id="image-gallery-pagination-container">
          <p id="image-gallery-pagination-container-text">
            {(page - 1) * 5 + 1 > inactiveUsers?.length
              ? inactiveUsers?.length
              : (page - 1) * 5 + 1}{" "}
            to{" "}
            {inactiveUsers?.length > page * 5
              ? page * 5
              : inactiveUsers?.length}{" "}
            of {inactiveUsers?.length} entries
          </p>
          <Pagination
            count={Math.ceil(inactiveUsers?.length / 5)}
            showFirstButton
            showLastButton
            id="image-gallery-pagination-btn"
            onChange={(e, value) => setPage(value)}
          />
        </div>
      </div>
    </div>
  );
}

const AdminSettingsTopbar = ({ setSearchText, searchText, filterUsers }) => {
  const dispatch = useDispatch();
  return (
    <div id="admin-settings-top-container">
      <h1 id="admin-settings-top-container-title">Users</h1>
      <div id="admin-settings-searchbar-container">
        <input
          type="text"
          id="admin-settings-searchbar"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <img
          src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/64/5DCE7B/external-search-twitter-flatart-icons-outline-flatarticons.png"
          alt="external-search-twitter-flatart-icons-outline-flatarticons"
          id="admin-settings-searchbar-icon"
        />
        <button id="admin-settings-searchbar-btn" onClick={filterUsers} >Search</button>
      </div>
      <button
        id="admin-settings-update-btn"
        onClick={() => {
          dispatch(setSidebarOption("createUser"));
        }}
      >
        + Add New User
      </button>
    </div>
  );
};