import React, { useContext, useEffect, useState } from "react";
import folderImg from "../../assets/images/usericon.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { downloadDeleted, UserUpdateCustomerStatus, UserDeleteCustomer, getCostumers } from "../../redux/actions/userAction";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import { IconButton } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { getCustumerDetails } from "../../redux/actions/userAction";
import { LoadingContext } from "../Home/ContextProvider";
const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const ITEM_HEIGHT = 48;

const FolderCards = ({ customers, navigation }) => {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState(false);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setdata(customers?.customers);
  }, [customers]);
  // useEffect(() => {
  //   dispatch(getCostumers())
  // }, []);



  const Card = ({ item }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


    const navigate = useNavigate();

    const handleUpdateUserStatus = () => {
      increaseLoadingCounter();
      dispatch(UserUpdateCustomerStatus(item?._id, item?.status==='active'?false:true)).then(() => {
          decreaseLoadingCounter();
      });
    };
    const handleDeleteClient = () => {
      increaseLoadingCounter();
      dispatch(UserDeleteCustomer(item?._id, navigate)).then(() => {
        decreaseLoadingCounter();
      })
    };
    return(
      <div
        className="card"
        style={{backgroundColor:item?.status==='inactive'?'#F6F6F6':''}}
      >
        <div>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                navigation(`/clients?client-details=${item?._id}`);
                // dispatch(getCustumerDetails(item?._id,navigation))

              }}
            >
              <VisibilityOutlinedIcon style={{ marginRight: "5px" }} />
              View Details
            </MenuItem>
            <MenuItem onClick={handleUpdateUserStatus}>
              <DoDisturbAltOutlinedIcon style={{ marginRight: "5px" }} />
              {item?.status==='active'?'Block':'Unblock'} Client
            </MenuItem>
            <MenuItem onClick={handleDeleteClient}>
              <DeleteOutlineOutlinedIcon style={{ marginRight: "5px" }} />
              Delete Client
            </MenuItem>
            <MenuItem onClick={() => {dispatch(downloadDeleted(item?._id, setDownloading));}}>
              <SimCardDownloadOutlinedIcon style={{ marginRight: "5px" }} />
              {
                downloading?
                  <ClipLoader
                    color={'#000'}
                    loading={true}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                :
                  <>Download Deleted</>
              }
            </MenuItem>
          </Menu>
          <IconButton
            aria-label="more"
            id="long-button"
            // aria-controls={open ? "long-menu" : undefined}
            // aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
          >
            <MoreVertIcon onClick={handleClick} />
          </IconButton>
        </div>
        <div
          className="card-top"
          onClick={() => {
            navigation(`/clients?client-details=${item?._id}`);
            // dispatch(getCustumerDetails(item?._id,navigation))

          }}
        >
          <img src={folderImg} alt="folder" id="folder-img" />
          <p className="card-top-title" style={{color:item?.status==='inactive'?'rgba(63, 65, 78, 0.6)':''}}>{item?.name}</p>
          <p className="card-top-text" style={{color:item?.status==='inactive'?'rgba(63, 65, 78, 0.6)':''}}>
            Created Date : {moment(item?.createdAt).format("DD MMM YYYY hh:mm a")}
          </p>
        </div>
        <div className="card-bottom">
          <div className="card-bottom-left">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                id="folder-card-icon"
                src="https://img.icons8.com/small/50/folder-invoices--v1.png"
                alt="folder-invoices--v1"
              />
              <p className="card-bottom-text">{item.totalFolders}</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                id="folder-card-icon"
                src="https://img.icons8.com/ios/50/copy--v1.png"
                alt="copy--v1"
              />
              <p className="card-bottom-text">{item?.totalFiles}</p>
            </div>
          </div>
          <div className="card-bottom-right">
            <button style={{backgroundColor:item?.status==='active'?'#5dce7b':'#C5C5C5', fontWeight: 'bold'}} id="active-btn">{item?.status}</button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        marginTop: "50px",
        gap: "30px",
      }}
    >
      {data?.map((item, index) => (
        <Card item={item} key={index} />
      ))}
    </div>
  );
};

export default FolderCards;
