import React, { useContext, useState } from "react";
import "./sidebarStyles.css";
import { LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarOption, setAddClientOption } from "../../redux/actions/sidebarAction";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import socketService from "../../socket";
import { getUserStorageUsage } from "../../redux/actions/userAction";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Box from "@mui/material/Box";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Typography from "@mui/material/Typography";
import { LoadingContext } from "../../pages/Home/ContextProvider";
import { FileUploadingModalData } from "../../redux/actions/sidebarAction";

const Sidebar = () => {
  const { increaseLoadingCounter, decreaseLoadingCounter, isUploadingStarted, decreaseUploadingLoadingCounter } = useContext(LoadingContext);
  const { sidebarOption } = useSelector((state) => state.sidebar);
  const { File_Uploading_Modal_Data } = useSelector((state) => state.sidebar);
  const { user_storage_usage, uploadingFileProgress } = useSelector((state) => state.user);
  const [localUploadingFilesArray, setLocalUploadingFilesArray] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(
    sidebarOption === "clients" ||
      sidebarOption === "blocked" ||
      sidebarOption === "activities"
      ? sidebarOption
      : ""
  );
  const [totalStorage, setTotalStorage] = useState(0);
  const [storageUsage, setStorageUsage] = useState(0);

  const convertBytesToGB = (bytes) => {
    return (bytes / 1024 / 1024 / 1024).toFixed(2);
  };

  const calculatePercentage = (used, total) => {
    return (used / total) * 100;
  };

  const handleItemSelection = (item) => {
    setSelectedItem(item);
  };

  const setupSocketListeners = async () => {
    await socketService.connect();
    socketService.on("storageUpdate", (data) => {
      setStorageUsage(convertBytesToGB(data.storageUsed));
    });
  };

  useEffect(() => {
    if (uploadingFileProgress) {
      setLocalUploadingFilesArray(prevFiles => {
        const existingFileIndex = prevFiles.findIndex(file => file.id === uploadingFileProgress.id);
        if (existingFileIndex !== -1) {
          const newFiles = [...prevFiles.slice(0, existingFileIndex), uploadingFileProgress, ...prevFiles.slice(existingFileIndex + 1)];
          return newFiles;
        } else {
          const newFiles = [uploadingFileProgress, ...prevFiles];
          return newFiles;
        }
      });
      dispatch({
        type: "progressUpdateSuccess",
        payload: null,
      });
    }
  }, [uploadingFileProgress]);

  useEffect(() => {
    setupSocketListeners();
    increaseLoadingCounter();
    dispatch(getUserStorageUsage()).then(() => {
      decreaseLoadingCounter()
    });
    return () => {
      socketService.disconnect()
    }
  }, []);

  useEffect(() => {
    if (user_storage_usage) {
      setTotalStorage(convertBytesToGB(user_storage_usage?.totalStorage));
      setStorageUsage(convertBytesToGB(user_storage_usage?.storageUsed));
    }
  }, [user_storage_usage]);

  return (
    <div className="sidebar">
      {(localUploadingFilesArray?.length > 0 || isUploadingStarted) &&
        <UploadingFile data={localUploadingFilesArray} setLocalUploadingFilesArray={setLocalUploadingFilesArray} decreaseUploadingLoadingCounter={decreaseUploadingLoadingCounter} />
      }
      

      <div className="sidebar-items-container">
        <button
          className={
            selectedItem === "clients"
              ? "sidebar-item sidebar-item-selected"
              : "sidebar-item"
          }
          onClick={() => {
            dispatch(setAddClientOption(false));
            handleItemSelection("clients");
            dispatch(setSidebarOption("clients"));
            navigate("/clients");
          }}
        >
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/ios-glyphs/30/user--v1.png"
            alt="guest-male"
          />
          <p style={{ margin: 0, color: "#000" }}>Clients</p>
          
        </button>
        <button
          className={
            selectedItem === "blocked"
              ? "sidebar-item sidebar-item-selected"
              : "sidebar-item"
          }
          onClick={() => {
            handleItemSelection("blocked");
            dispatch(setSidebarOption("blocked"));
            navigate("/blocked-users");
          }}
        >
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/ios-glyphs/30/cancel-2.png"
            alt="cancel-2"
          />
          <p style={{ margin: 0, color: "#000" }}>Blocked</p>
        </button>
        {/* <button
          className={
            selectedItem === "activities"
              ? "sidebar-item sidebar-item-selected"
              : "sidebar-item"
          }
          onClick={() => {
            handleItemSelection("activities");
            dispatch(setSidebarOption("activities"));
          }}
        >
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/windows/32/checked-user-male--v1.png"
            alt="checked-user-male--v1"
          />
          <p style={{ margin: 0, color: "#000" }}>Activities</p>
        </button> */}
      </div>
      <div className="sidebar-storage-container">
        <LinearProgress
          variant="determinate"
          value={calculatePercentage(storageUsage, totalStorage)}
          style={{
            height: "8px",
            borderRadius: "10px",
            width: "100%",
            margin: "10px 0px",
            backgroundColor: "#D9D9D9",
          }}
          sx={{
            "& .MuiLinearProgress-bar": {
              borderRadius: "10px",
              backgroundColor: "#FA6E5A",
            },
          }}
        />
        <p id="storage-text">
          {storageUsage} GB of {totalStorage} GB used
        </p>
        <button
          id="sidebar-upgrade-btn"
          onClick={() => {
            navigate("/pricing");
          }}
        >
          upgrade
        </button>
      </div>
      <span style={{ color: '#FA6E5A', position: 'absolute', bottom: '0' }}>© The Smart Trunk</span>
    </div>
  );
};

function UploadingFile({ data, setLocalUploadingFilesArray, decreaseUploadingLoadingCounter }) {
  return (
    <div id="file-uploading-modal" style={{ width: '23rem' }}>
      <div
        style={{
          display: "flex",
          borderBottomColor: "#000",
          borderBottom: "1px solid rgb(212, 212, 212)",
          alignItems: 'center'
        }}
      >
        {
          data?.length !== 0 ?
            <div>
              {data.filter(item => item.status === 'uploading').length > 0 ? <span
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  fontFamily: "Nunito",
                  marginLeft: '10px'
                }}
              >
                Uploading {data.filter(item => item.status === 'uploading').length} Files
              </span> : data.filter(item => item.status === 'failed').length > 0 ?

                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    fontFamily: "Nunito",
                  }}
                >
                  {data.filter(item => item.status === 'failed').length}Upload failed
                </span> :
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    fontFamily: "Nunito",
                  }}
                >
                  {data.filter(item => item.status === 'success').length} Files Uploaded Succesfully
                </span>

              }


              <br />
            </div>
            :
            <span style={{fontWeight: "bold",fontSize: "16px",fontFamily: "Nunito",marginLeft: '10px'}}>Please wait ...</span>
        }
        <Button
          onClick={() => {
            decreaseUploadingLoadingCounter();
            setLocalUploadingFilesArray([]);
          }}
          style={{ margin: 0, padding: "0px", marginLeft: "auto" }}
        >
          <span style={{ margin: 0, padding: 0 }}>X</span>
        </Button>
      </div>
      <div style={{ overflow: "scroll", overflowX: "hidden", maxHeight: '250px' }}>

        {data?.map((item, index) => (
          <div
            style={{ marginTop: "20px", }}
            key={index}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    fontFamily: "Nunito",
                  }}
                >
                  {item?.name?.slice(0, 25)}{item?.name?.length > 25 && "..."}
                </span>
              </div>
              {item?.status === 'uploading' ? (
                <div style={{ marginRight: "15px", marginLeft: "auto", }}>
                  <CircularProgressWithLabel
                    size={30}
                    value={item?.progress}
                  />
                </div>
              ) : item?.status === 'failed' ? (
                <ErrorOutlineIcon style={{ color: "red", marginLeft: "auto" }} />
              ) : item?.status === 'success' ? (
                <CheckCircleOutlineIcon
                  color="#5DCE7B"
                  style={{ color: "#5DCE7B", marginLeft: "auto" }}
                />
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div >
  )
}



function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}


export default Sidebar;
