import React, {useState}from 'react';
import mainLogo from "../assets/img/GSPro-Logo.svg";

export default function StaticHeader() {
    const [openMenu, setopenMenu] = useState(false);

    const menuMobile = ()=>{
        setopenMenu(check => !check)
    }
  return (
    <div className="container w-container">
                <div className="grid-navbar">
                  <a href="/" className="brand w-nav-brand" aria-label="home">
                    <img src={mainLogo} loading="eager" alt="" className="logo" /></a>
                  <nav role="navigation" className={openMenu ? "nav-menu w-nav-menu mobileMenu" : "nav-menu w-nav-menu"}>
                    <a href="/" aria-current="page" className="nav-link w-nav-link">Home</a>
                    <a href="/about" aria-current="page" className="nav-link w-nav-link">About</a>
                    <a href="/features" className="nav-link w-nav-link">Features</a>
                    <a href="/pricings" className="nav-link w-nav-link">Pricing</a>
                    
                    <a href="/login" className="nav-link m-nav-link">Login</a>
                  </nav>
                  <div className="navbar-right nav-hide">
                    <a href="/login" className="nav-link">Login</a>
                    <a data-w-id="f3952b50-6d1f-393e-b11f-b676ceacc841" href="/login" className="button-link-icon nav-button-hide w-inline-block">
                      <div>
                        <div className="button-primary-1 button-subscribe w-button" > Try For Free</div>
                      </div>
                    </a>
                  </div>
                  <div className="menu-button w-nav-button" onClick={menuMobile}>
                    <div className="w-icon-nav-menu" />
                  </div>
                </div>
              </div>
  )
}
