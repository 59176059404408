import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AdminProtectedRoutes = ({ Component }) => {
  const navigate = useNavigate();
  const [jwt, setJwt] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    setJwt(Cookies.get("admin_access_token"));
    setIsInitialRender(false);
  }, []);

  useEffect(() => {
    if (!isInitialRender) {
      if (jwt) {
        getVerified();
      } else {
        navigate("/admin-login");
      }
    }
  }, [jwt, navigate, isInitialRender]);

  const getVerified = async () => {
    try {
      const decodedToken = jwtDecode(jwt);
      if (decodedToken.exp * 1000 < Date.now()) {
        navigate("/admin-login");
      } else {
        Cookies.set("adminId", decodedToken._id);
        Cookies.set("adminUsername", decodedToken.username);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error decoding JWT:", error.message);
      navigate("/admin-login");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <Component />;
};

export default AdminProtectedRoutes;
