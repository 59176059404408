import React from "react";
// import "./assets/css/custom.css";
// import "./assets/css/main.css";
import featureImg from "./assets/img/feature-img.jpg";
import star from "./assets/img/star.svg";
import UserPortaImg from "./assets/img/User-Portal.png";
import ClientPortaImg from "./assets/img/ClientPortal.png";
import MediaIcons from "./assets/img/MediaIcons.png";
import StaticHeader from "./comps/StaticHeader";
import StaticFooter from "./comps/StaticFooter";
import TryBanner from "./comps/TryBanner";
const FeaturesPage = () => {
    
    return(
        <div>
          
          <div id="mainContent" >
            <div className="navbar w-nav" id="myHeader">
              <StaticHeader />
              <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0" />
            </div>
        <section className="hero-section">
        <div className="hero-section-decoration innerpage">
          <div className="container-medium w-container">
            <div className="container w-container gs_reveal">
              <div className="w-layout-grid grid-product-hero">
                <div
                  className="product-hero-content-wrap"
                  style={{ textAlign: "left" }}
                >
                  <h1>Robust Media Gallery Management</h1>
                  <p className="blacktext">
                    Organize, categorize, and centralize your media assets for easy
                    access and efficient management.
                    <br />
                    <br />
                    Create custom folders and tags to keep your media gallery
                    well-structured and organized.
                  </p>
                </div>
                <div
                  id="w-node-_75191d26-a37c-22c4-5677-a5cf82ad9d75-8b2981b4"
                  className="product-hero-image-wrap"
                >
                  <img
                    src={featureImg}
                    loading="eager"
                    alt=""
                    className="product-hero-image"
                  />
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <section className="feature-section">
        <div className="container w-container">
          <div className="feature-content-gradient gs_reveal">
            <div className="feature-content-left">
              <div className="pre-title-inline">
                <img
                  src={star}
                  loading="lazy"
                  alt=""
                  className="pre-title-image"
                />
                <div>Features</div>
              </div>
              <h2>User Portal</h2>
              <p className="margin-bottom-28" style={{fontWeight:"inherit" , color:"#000"}}>
              Create custom folders and tags to keep your media gallery and client well-structured and organized.
              </p>
            </div>
            <div className="w-layout-grid grid-feature-right">
              <div className="UserPortalImage"><img src={UserPortaImg}  alt="user Portal"/></div>
            </div>
          </div>
        </div>
      </section>
      <br/>
      <br/>
      <section className="feature-section">
        <div className="container w-container">
          <div className="feature-content-gradient gs_reveal">
            
            <div className="w-layout-grid grid-feature-right">
              <div className="UserPortalImage"><img src={ClientPortaImg}  alt="user Portal"/></div>
            </div>
            <div className="feature-content-left">
              <div className="pre-title-inline">
                <img
                  src={star}
                  loading="lazy"
                  alt=""
                  className="pre-title-image"
                />
                <div>Features</div>
              </div>
              <h2>Client Portal</h2>
              <p className="margin-bottom-28" style={{fontWeight:"inherit" , color:"#000"}}>
              Share a Secured link with your Clients and manage the media approval workflows
              </p>
            </div>
          </div>
        </div>
      </section>
      <br/>
      <br/>
      <section className="feature-section">
        <div className="container w-container">
          <div className="feature-content-gradient gs_reveal">
            <div className="feature-content-left">
              <div className="pre-title-inline">
                <img
                  src={star}
                  loading="lazy"
                  alt=""
                  className="pre-title-image"
                />
                <div>Features</div>
              </div>
              <h2>Media Types</h2>
              <p className="margin-bottom-28" style={{fontWeight:"inherit" , color:"#000"}}>
              GSPro accepts Images, Video and Audio Files for approval workflows
              </p>
            </div>
            <div className="w-layout-grid grid-feature-right">
            <div className="UserPortalImage"><img src={MediaIcons}  alt="user Portal"/></div>
            </div>
          </div>
        </div>
      </section>
      <section className="acb-section section-spacing">
        <div className="container-medium w-container gs_reveal">
          <div className="acb-content-wrap">
            <div className="acb-content">
              <h4>
                Gallery Studio Pro is your all-in-one solution for secure media
                gallery management, approval workflows, and collaborative
                commenting. Elevate your creative and professional endeavors with
                confidence in the security and efficiency of your media assets.
              </h4>
            </div>
          </div>
        </div>
      </section>
      
      <section className="getstarted">
      <TryBanner />
    </section>
    <footer className="footer">
      <StaticFooter />
    </footer>
  </div>
  {/* Adding JavaScript code */}
</div>
    );
}

export default FeaturesPage;