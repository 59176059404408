import React from "react";
// import "./assets/css/custom.css";
// import "./assets/css/main.css";

const SecurityPage = ({url}) => {
    const hostedUrl = `${url}/static`;
    const staticHTML = `
    <body>
        <div class="navbar w-nav" id="myHeader">
            <div class="container w-container">
                <div class="grid-navbar">
                    <a href="/" 
                        class="brand w-nav-brand" aria-label="home">
                        <img
                            src="${hostedUrl}/assets/img/GSPro-Logo.svg"
                            loading="eager" alt="" class="logo"></a>
                            <nav role="navigation" 
                            class="nav-menu w-nav-menu">
                            <a href="/" aria-current="page"
                            class="nav-link w-nav-link" >Home</a>
                            <a href="/about" aria-current="page" class="nav-link w-nav-link" >About</a>
                            <a href="/features" class="nav-link w-nav-link" >Features</a>
                            <a href="/pricings" class="nav-link w-nav-link" >Pricing</a>
                          
                            <a href="/login" class="nav-link m-nav-link">Login</a>
                        </nav>
                    <div class="navbar-right nav-hide">
                        <a href="/login" class="nav-link">Login</a>
                        <a data-w-id="f3952b50-6d1f-393e-b11f-b676ceacc841" href="/user-pages/sign-up"
                            class="button-link-icon nav-button-hide w-inline-block">
                            <div>
                                <input type="submit" value="Try For Free" data-wait="Please wait..." class="button-primary-1 button-subscribe w-button">
                            </div>
                        </a>
                    </div>
                    <div  class="menu-button w-nav-button">
                        <div class="w-icon-nav-menu"></div>
                    </div>
                </div>
            </div>
            <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
        </div>
        <section class="hero-section">
            <div class="hero-section-decoration innerpage">
                <div class="container-medium w-container">
                    <div class="hero-inner-content-wrap">
                        <div class="hero-inner-content">
                            
                            <h1>Security</h1>
                            <p class="benefit-content">
                                Last updated: March 05, 2024
                            </p>
                            <div class="rich-text w-richtext" style="text-align: left;">
                                <p>At Gallery Studio Pro, we prioritize the security and privacy of your media gallery, approval workflows, and collaborative comments. We employ a robust set of security measures to safeguard your data and ensure a secure user experience. Here are the key security features and practices we have in place:</p>

    <h5>1. Data Encryption:</h5>

    <p class="blacktext">1.1. Data in Transit: All data transmitted between your device and our servers is encrypted using industry-standard SSL/TLS protocols, ensuring that your information remains confidential during transit.</p>

    <p class="blacktext">1.2. Data at Rest: Media files and user data stored on our servers are encrypted, adding an extra layer of protection against unauthorized access.</p>

    <h5>2. Access Control:</h5>

    <p class="blacktext">2.1. User Authentication: Users are required to authenticate their identity through secure login credentials (e.g., username and password) before accessing their accounts.</p>

    <p class="blacktext">2.2. Role-Based Access: Our platform allows for customizable user roles and permissions, ensuring that users only have access to the features and data necessary for their roles.</p>

    <h5>3. Secure Infrastructure:</h5>

    <p class="blacktext">3.1. Hosting Provider: We partner with reputable hosting providers that adhere to strict security standards and industry best practices to maintain the integrity and availability of our services.</p>

    <p class="blacktext">3.2. Regular Security Audits: We conduct regular security audits and assessments of our infrastructure to identify and address vulnerabilities.</p>

    <h5>4. Secure Commenting and Collaboration:</h5>

    <p class="blacktext">4.1. Comment Encryption: Comments and annotations made on media files are encrypted to protect the content and ensure that only authorized users can access them.</p>

    <p class="blacktext">4.2. Collaborative Controls: You have control over who can access and comment on your media files, allowing you to manage collaboration securely.</p>

    <h5>5. Compliance:</h5>

    <p class="blacktext">5.1. Regulatory Compliance: We strive to comply with applicable data protection laws and regulations, such as GDPR and CCPA, to ensure the privacy rights of our users are upheld.</p>

    <h5>6. Monitoring and Incident Response:</h5>

    <p class="blacktext">6.1. Continuous Monitoring: Our security team continually monitors our platform for suspicious activities and potential threats.</p>

    <p class="blacktext">6.2. Incident Response Plan: In the event of a security incident, we have a well-defined incident response plan in place to quickly assess, mitigate, and report any breaches.</p>

    <h5>7. Data Backups:</h5>

    <p class="blacktext">7.1. Regular Backups: We perform regular data backups to ensure that your media files and important information can be recovered in case of data loss.</p>

    <h5>8. User Education:</h5>

    <p class="blacktext">8.1. Security Awareness: We provide guidance to users on best practices for maintaining the security of their accounts and data.</p>

    <h5>9. Third-Party Integration:</h5>

    <p class="blacktext">9.1. Secure Integration: When integrating with third-party services, we ensure that data sharing is done securely, and user information remains protected.</p>

    <h5>10. Transparency:</h5>

    <p class="blacktext">10.1. Transparency Reports: We are committed to providing transparency regarding our security practices and any incidents that may impact user data.</p>

    <p class="blacktext">Gallery Studio Pro takes security seriously, and we continuously strive to improve and adapt our security measures to address emerging threats and vulnerabilities. Your trust in our platform is of paramount importance, and we are dedicated to maintaining a secure environment for your media gallery, approvals, and comments. If you have any security concerns or questions, please contact us at info@gallerystudiopro.com.</p>
                                <p>&zwj;</p>
                                
                                <p class="blacktext"><strong>&zwj;</strong>Please send your feedback, comments, requests for technical support:</p>
                                <p><a href="#" target="_blank">info@gallerystudiopro.com</a></p>
                            </div>
                        </div>
                    </div>
                    
            </div>
            
            </div>

        </section>


    
        <footer class="footer">
            <div class="container w-container">
                <div class="w-layout-grid grid-footer">
                    <div>
                        <a href="/"
                            aria-current="page" class="footer-logo-wrap w-inline-block w--current">
                            <img src="${hostedUrl}/assets/img/FavIcon.png" loading="eager" alt="" class="footer-logo-wrap">
                        </a>
                        <p class="no-margin blacktext">Securely Organize, Showcase,and Collaborate 
                            On your Media Assets with <b>Gallery Studio pro</b></p>
                    </div>
                    <div id="w-node-_5b5b05ab-e775-238d-7db9-2056f436ada0-f436ad98" class="footer-item-wrap">
                        <h6 class="footer-title">Pages</h6>
                        <div class="w-layout-grid grid-footer-link">
                            <div class="footer-link-wrap">
                                <a href="/" aria-current="page"
                                class="footer-link w--current">Home</a>
                                <a href="/about" aria-current="page"
                                class="footer-link w--current">About</a>
                                <a href="/features" aria-current="page"
                                class="footer-link w--current">Features</a>
                                <a href="/pricings" aria-current="page"
                                class="footer-link w--current">Pricing</a>
                               
                            </div>
                        </div>
                    </div>
                    <div id="w-node-_5b5b05ab-e775-238d-7db9-2056f436adb6-f436ad98" class="footer-item-wrap">
                        <h6 class="footer-title">Utility pages</h6>
                        <div class="w-layout-grid grid-footer-link">
                            <div class="footer-link-wrap">
                                <a href="privacy" class="footer-link"> Privacy</a>
                                <a href="terms" class="footer-link">Terms</a>
                                <a href="security" class="footer-link">Security</a>
                            </div>
                        </div>
                    </div>
                    <div id="w-node-_5b5b05ab-e775-238d-7db9-2056f436adcc-f436ad98" class="footer-item-wrap">
                        <h6 class="footer-title">Contact Us</h6>
                        <div class="grid-footer-link"><a href="mailto:info@gallerystudiopro.com"
                                class="footer-link">info@gallerystudiopro.com</a>
                            <div class="footer-social-wrap">
                                <a href="" target="_blank"
                                    class="footer-social-link  w-inline-block"><img
                                        src="${hostedUrl}/assets/img/fb.svg"
                                        loading="eager" alt="" class="footer-social-icon"></a>
                                        <a href=""
                                    target="_blank" class="footer-social-link w-inline-block"><img
                                        src="${hostedUrl}/assets/img/insta.svg"
                                        loading="eager" alt="" class="footer-social-icon"></a>
                                        <a
                                    href="" target="_blank"
                                    class="footer-social-link w-inline-block"><img
                                        src="${hostedUrl}/assets/img/youtube.svg"
                                        loading="eager" alt="" class="footer-social-icon"></a>
                                    
                                    </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom text-center ">
                    <div class="xy-auto"> Powered by <a href=""
                            target="_blank" class="footer-link text-primary-2">The Smart Trunk</a></div>
                </div>
            </div>
        </footer>
    </body>
    `;
    return <div dangerouslySetInnerHTML={{ __html: staticHTML }} />;
}

export default SecurityPage;