import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ClientProtectedRoutes = ({ Component }) => {

  const navigate = useNavigate();
  const [jwt, setJwt] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [clientUserId, setClientUserId] = useState()

  useEffect(() => {
    setJwt(Cookies.get("client_access_token"));
    setClientUserId(Cookies.get("clientUserId"));
    setIsInitialRender(false);
  }, []);

  useEffect(() => {
    if (!isInitialRender) {
      if (jwt) {
        getVerified();
      } else {
        navigate(`/ClientLogin?userId=${clientUserId}`);
      }
    }
  }, [jwt, navigate, isInitialRender]);

  const getVerified = async () => {
    try {

      const decodedToken = jwtDecode(jwt);
      if (decodedToken.exp * 1000 < Date.now()) {

        navigate(`/ClientLogin?userId=${clientUserId}`);
      } else {
        Cookies.set("clientId", decodedToken._id);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error decoding JWT:", error.message);
      navigate(`/ClientLogin?userId=${clientUserId}`);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <Component />;
};

export default ClientProtectedRoutes;
