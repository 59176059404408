import React, {useState} from "react";
// import "./assets/css/custom.css";
// import "./assets/css/main.css";
import star from "./assets/img/star.svg";
import checkImg from "./assets/img/Check.svg";
import StaticHeader from "./comps/StaticHeader";
import StaticFooter from "./comps/StaticFooter";
import TryBanner from "./comps/TryBanner";


const PricingPage = () => {
    const [pricing, setPricing] = useState(false);
    const changeCurrency = () =>{
        setPricing( current => !current);
    }
    return (
        <div>
          
        <div id="mainContent" >
          <div className="navbar w-nav" id="myHeader">
            <StaticHeader />
            <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0" />
          </div>
        <section className="hero-section">
        <div className="hero-section-decoration innerpage">
          <div className="container-medium w-container">
            <div className="hero-inner-content-wrap gs_reveal">
              <div className="hero-inner-content">
                <div className="pre-title-inline">
                  <img
                    src={star}
                    loading="lazy"
                    alt=""
                    className="pre-title-image"
                  />
                  <div>Pricing</div>
                </div>
                <h1>Perfect plan for you</h1>
                <p className="benefit-content">
                  Free Plans Available | Cancel anytime
                </p>
              </div>
            </div>
            <div className="toggle-wrapper text-primary-2 gs_reveal">

              <div className="w-flex w-f-center">
                <div className="toggle-text">$ USD</div>
                <div className="pricing-toggle">
                  <label className="toggle" htmlFor="myCur">
                    <input
                      className="toggle__input"
                      name=""
                      type="checkbox"
                      id="myCur"
                    />
                    <div className="toggle__fill" onClick={changeCurrency} />
                  </label>
                </div>
                <div>₹ INR</div>
              </div>
            </div>
            <div className="w-layout-grid grid-pricing gs_reveal">
              <div className="pricing-item">
                <h5 className="pricing-pre-title">Free</h5>
                <div
                  style={{ display: "grid", opacity: 1 }}
                  className="pricing-monthly"
                >
                  <div className="pricing-simple-title-wrap">
                  {pricing == false ?  
                    <h3 className="pricing-simple-price">
                      $<span className="price">0</span>{" "}
                    </h3>
                    : 
                    <h3 className="pricing-simple-price">
                    ₹<span className="price">0</span>{" "}
                    </h3>}
                    <div>/</div>
                    <div>Month</div>
                  </div>
                </div>
                <h6 className="pricing-pre-title">
                  All essential sharing and review options to get started.
                </h6>
                <div
                  style={{ opacity: 0, display: "none" }}
                  className="pricing-annualy"
                >
                  <div className="pricing-simple-title-wrap">
                    <h3 className="pricing-simple-price">
                      $<span className="price">0</span>
                    </h3>
                    <div>/</div>
                    <div>Month</div>
                  </div>
                </div>
                <div className="pricing-list">
                  <div className="pricing-feature">
                    <img
                      src={checkImg}
                      loading="eager"
                      alt=""
                      className="pricing-feature-icon"
                    />
                    <div>2 GB Storage</div>
                  </div>
                  <div className="pricing-feature">
                    <img
                      src={checkImg}
                      loading="eager"
                      alt=""
                      className="pricing-feature-icon"
                    />
                    <div>One Client Creation</div>
                  </div>
                  <div className="pricing-feature">
                    <img
                      src={checkImg}
                      loading="eager"
                      alt=""
                      className="pricing-feature-icon"
                    />
                    <div>All Review Options</div>
                  </div>
                  <div className="pricing-feature">
                    <img
                      src={checkImg}
                      loading="eager"
                      alt=""
                      className="pricing-feature-icon"
                    />
                    <div>Password Protection</div>
                  </div>
                </div>
                <a href="/contact" className="button-primary-1 w-button">
                  Try For Free
                </a>
              </div>
              <div className="pricing-item">
                <h5 className="pricing-pre-title">Lite</h5>
                <div
                  style={{ display: "grid", opacity: 1 }}
                  className="pricing-monthly"
                >
                  <div className="pricing-simple-title-wrap">
                  {pricing == false ?  
                    <h3 className="pricing-simple-price">
                      $<span className="price">10</span>
                    </h3>
                    : 
                    <h3 className="pricing-simple-price">
                    ₹<span className="price">849</span>
                    </h3>}
                    <div>/</div>
                    <div>Month</div>
                  </div>
                </div>
                <h6 className="pricing-pre-title">
                  All essential sharing and review options to get started.
                </h6>
                <div
                  style={{ opacity: 0, display: "none" }}
                  className="pricing-annualy"
                >
                  <div className="pricing-simple-title-wrap">
                  {pricing== false ?  
                    <h3 className="pricing-simple-price">
                      $<span className="price">20</span>
                    </h3>
                    : 
                    <h3 className="pricing-simple-price">
                    ₹<span className="price">1699</span>
                    </h3>}
                    <div>/</div>
                    <div>Month</div>
                  </div>
                </div>
                <div className="pricing-list">
                  <div className="pricing-feature">
                    <img
                      src={checkImg}
                      loading="eager"
                      alt=""
                      className="pricing-feature-icon"
                    />
                    <div>15 GB Storage</div>
                  </div>
                  <div className="pricing-feature">
                    <img
                      src={checkImg}
                      loading="eager"
                      alt=""
                      className="pricing-feature-icon"
                    />
                    <div>10 Clients Creation</div>
                  </div>
                  <div className="pricing-feature">
                    <img
                      src={checkImg}
                      loading="eager"
                      alt=""
                      className="pricing-feature-icon"
                    />
                    <div>All Review Options</div>
                  </div>
                  <div className="pricing-feature">
                    <img
                      src={checkImg}
                      loading="eager"
                      alt=""
                      className="pricing-feature-icon"
                    />
                    <div>Password Protection</div>
                  </div>
                </div>
                <a href="/contact" className="button-primary-1 w-button">
                  Subscribe
                </a>
              </div>
              <div className="pricing-item">
                <h5 className="pricing-pre-title">Pro</h5>
                <div
                  style={{ display: "grid", opacity: 1 }}
                  className="pricing-monthly"
                >
                  <div className="pricing-simple-title-wrap">
                  {pricing== false ?  
                    <h3 className="pricing-simple-price">
                      $<span className="price">20</span>
                    </h3>
                    : 
                    <h3 className="pricing-simple-price">
                    ₹<span className="price">1699</span>
                    </h3>}
                    <div>/</div>
                    <div>Month</div>
                  </div>
                </div>
                <h6 className="pricing-pre-title">
                  All essential sharing and review options to get started.
                </h6>
                <div
                  style={{ opacity: 0, display: "none" }}
                  className="pricing-annualy"
                >
                  <div className="pricing-simple-title-wrap">
                    <h3 className="pricing-simple-price">
                      $<span className="price">10</span>
                    </h3>
                    <div>/</div>
                    <div>Month</div>
                  </div>
                </div>
                <div className="pricing-list">
                  <div className="pricing-feature">
                    <img
                      src={checkImg}
                      loading="eager"
                      alt=""
                      className="pricing-feature-icon"
                    />
                    <div>50 GB Storage</div>
                  </div>
                  <div className="pricing-feature">
                    <img
                      src={checkImg}
                      loading="eager"
                      alt=""
                      className="pricing-feature-icon"
                    />
                    <div>Unlimited Clients Creation</div>
                  </div>
                  <div className="pricing-feature">
                    <img
                      src={checkImg}
                      loading="eager"
                      alt=""
                      className="pricing-feature-icon"
                    />
                    <div>All Review Options</div>
                  </div>
                  <div className="pricing-feature">
                    <img
                      src={checkImg}
                      loading="eager"
                      alt=""
                      className="pricing-feature-icon"
                    />
                    <div>Password Protection</div>
                  </div>
                </div>
                <a href="/contact" className="button-primary-1 w-button">
                  Subscribe
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="getstarted">
      <TryBanner />
    </section>
    <footer className="footer">
      <StaticFooter />
    </footer>
  </div>
  {/* Adding JavaScript code */}
</div>
    );
}

export default PricingPage;