import React, { useContext, useEffect, useState } from "react";
import "./homeStyles.css";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import bgImg from "../../assets/images/homebg.png";
import bgImg2 from "../../assets/images/homebg2.png";
import AddClientForm from "../../components/AddClientForm/AddClientForm";
import Clients from "../Clients/Clients";
import { useNavigate } from "react-router-dom";
import ClientDetails from "../Clients/ClientDetails";
import BlockedUsers from "./BlockedUsers";
import { useDispatch, useSelector } from "react-redux";
import { getCostumers } from "../../redux/actions/userAction";
import Cookies from "js-cookie";
import { setAddClientOption } from "../../redux/actions/sidebarAction";
import { LoadingContext } from "./ContextProvider";
import ReactGA from "react-ga";

const Home = () => {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const dispatch = useDispatch();
  const { Add_ClientOption } = useSelector((state) => state.sidebar);
  const { get_Costumers, user_update_customer_status, delete_customer } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);
  const navigation = useNavigate();
  const path = window.location.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  const [addClient, setAddClient] = useState(false);
  const [profile, setprofile] = useState(false);

  useEffect(() => {
    increaseLoadingCounter();
    dispatch(getCostumers(Cookies.get("access_token"))).then(() => {
        decreaseLoadingCounter();
    });
  }, [user_update_customer_status, delete_customer]);
  
  //prevent user from going back
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname)
   },[])
  const HomeItems = () => (
    <div
      style={{
        marginTop: "50px",
      }}
    >
      <div className="home-main-text-container">
        <p style={{marginBottom:'10px'}} id="home-title-text">
          <span id="home-title-text-orange">Hi</span> {user?.name}
          <span id="home-title-text-orange">,</span>
        </p>
        <p id="home-title-text-orange">Welcome to Gallery Studio Pro</p>
        <p
          style={{
            fontSize: "16px",
            color: "#3F414E",
          }}
        >
          Explore the app, Upload your client media files and share with them for
          finalization
        </p>
        <button
          id="home-action-btn"
          onClick={() => {
            dispatch(setAddClientOption(true));
          }}
        >
          <img
            width="15"
            height="15"
            src="https://img.icons8.com/ios-glyphs/30/FFFFFF/plus-math.png"
            alt="plus-math"
          />
          <p style={{ margin: 0 }}>Add New Client</p>
        </button>
      </div>
      <div id="home-bg-img-container">
        <img src={bgImg} alt="home background" id="home-bg-img" />
        <img src={bgImg2} alt="home background" id="home-bg-img-absolute" />
        <div id="home-bg-img-absolute-overlay"></div>
        <button id="home-play-btn">
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/sf-black-filled/64/FFFFFF/play.png"
            alt="play"
          />
        </button>
      </div>
    </div>
  );

  return (
    <div className="home-page">
      <Header />
      <Sidebar />
      <div id="sidebar-placeholder"></div>
      <div className="home-main-container">
        {path === "/home" && (
          <>
            {Add_ClientOption ? (
              <AddClientForm />
            ) : (
              <>
                {get_Costumers?.customers?.length > 0 ? (
                  navigation("/clients")
                ) : (
                  <HomeItems />
                )}
              </>
            )}
          </>
        )}
        {urlParams.get("client-details") === null && path === "/clients" && (
          <Clients navigation={navigation} />
        )}
        {path === "/blocked-users" && <BlockedUsers navigation={navigation} />}

        {urlParams.get("client-details") !== null && <ClientDetails />}
      </div>
    </div>
  );
};

export default Home;
