export const setSidebarOption = (sidebarOption) => {
  return {
    type: "SIDEBAR_OPTION",
    payload: sidebarOption,
  };
};
export const setAddClientOption = (AddClientOption) => {
  return {
    type: "Add_ClientOption",
    payload: AddClientOption,
  };
};
