import React from "react";

import star from "./assets/img/star.svg";
import AbImg from "./assets/img/AbImg.png";
import StaticHeader from "./comps/StaticHeader";
import StaticFooter from "./comps/StaticFooter";
import TryBanner from "./comps/TryBanner";

const AboutPage = () => {
    
    return (
        <div>
          <div id="mainContent" >
            <div className="navbar w-nav" id="myHeader">
              <StaticHeader />
              <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0" />
            </div>
        <section className="hero-section">
        <div className="hero-section-decoration innerpage">
          <div className="container-medium w-container ">
            <div className="hero-inner-content-wrap gs_reveal">
              <div className="hero-inner-content">
                <div className="pre-title-inline">
                  <img
                    src={star}
                    loading="lazy"
                    alt=""
                    className="pre-title-image"
                  />
                  <div>About</div>
                </div>
                <h1>About</h1>
                <p className="benefit-content">
                  Welcome to Gallery Studio Pro, your ultimate solution for secure
                  media gallery management, approval workflows, and collaborative
                  commenting. We understand the importance of seamlessly managing
                  your valuable media assets while ensuring they remain protected
                  and easily accessible.
                </p>
              </div>
            </div>
            <p className="m-t-10p gs_reveal blacktext">
              <b>Our Mission</b>
            </p>
            <div className="w-col w-layout-grid grid-benefit-two gs_reveal">
              <div className="">
                <img src={AbImg} alt="" />
              </div>
              <div
                className="benefit-content"
                style={{ textAlign: "left", display: "flex", alignItems: "center" }}
              >
                <p className="blacktext">
                  At Gallery Studio Pro, our mission is to empower professionals and
                  businesses across all industries with a robust platform to
                  efficiently organize, showcase, and collaborate on their media
                  assets. We believe that streamlined media management should be
                  accessible to everyone, whether you're a photographer, graphic
                  designer, web developer, 3D renderer, or involved in
                  infrastructure projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-medium w-container ">
          <div className="feature-content-wrap">
            <h2 style={{ textAlign: "center" }} className="gs_reveal">
              What Sets Us Apart
            </h2>
            <div className="w-layout-grid grid-feature">
              <div className="feature-item gs_reveal">
                <h3 className="heading-h5">Security First</h3>
                <p className="feature-content">
                  We prioritize the security of your media assets. With
                  state-of-the-art encryption and access controls, your files remain
                  confidential and protected.
                </p>
              </div>
              <div className="feature-item gs_reveal">
                <h3 className="heading-h5">Effortless Approval Workflows</h3>
                <p className="feature-content">
                  Streamline your approval processes with our intuitive tools.
                  Easily share media files for review and feedback, ensuring a
                  smoother creative collaboration.
                </p>
              </div>
              <div className="feature-item gs_reveal">
                <h3 className="heading-h5">Collaborative Commenting</h3>
                <p className="feature-content">
                  Encourage productive discussions with our commenting features.
                  Receive feedback, suggestions, and comments directly on your media
                  files to enhance your work..
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br/>
      <br/>
      <section className="getstarted">
      <TryBanner />
    </section>
    <footer className="footer">
      <StaticFooter />
    </footer>
  </div>
  {/* Adding JavaScript code */}
</div>
    );
}

export default AboutPage;