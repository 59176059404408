import axios from "axios";
import Cookies from "js-cookie";
import { backend_api } from "../store";
import toast from "react-hot-toast";

const redirectToLogin = () => {
  Cookies.remove("admin_access_token");
  window.location.href = "/error404";
};

export const loginAdmin = (userDetails) => async (dispatch) => {
    dispatch({
      type: "adminLoginLoading",
    });
    await axios.post(`${backend_api}/api/auth/adminLogin`, {
      username: userDetails.username,
      password: userDetails.password,
    }).then(async (res) => {
      return new Promise((resolve, reject) => {
        Cookies.set("admin_access_token", res.data.access_token);
        resolve();
      }).then(() => {
        dispatch({
          type: "adminLoginSuccess",
          payload: res.data,
        });
        toast.success(res.data.message);
      });
    }).catch((err) => {
      dispatch({
        type: "adminLoginFailure",
        payload: err.response.data.message,
      });
      return toast.error(err.response.data.message || "Something went wrong");
    });
};

export const getAllUsers = () => async (dispatch) => {
    dispatch({
      type: "adminGetAllUsersLoading",
    });
    await axios.get(`${backend_api}/api/admin/getUsers`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("admin_access_token")}`,
      },
    }).then(async (res) => {
      dispatch({
        type: "adminGetAllUsersSuccess",
        payload: res.data,
      });
    }).catch((err) => {
      dispatch({
        type: "adminGetAllUsersFailure",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
      return toast.error(err.response.data.message || "Something went wrong");
    });
};

export const adminDeleteUser = (id, navigate) => async (dispatch) => {
    dispatch({
      type: "adminDeleteUserLoading",
    });
    await axios.delete(
      `${backend_api}/api/admin/deleteUser/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("admin_access_token")}`,
        },
    }).then(async (res) => {
      dispatch({
        type: "adminDeleteUserSuccess",
        payload: res.data,
      });
      toast.success(res.data.message);
      navigate("/admin-dashboard");
    }).catch((err) => {
      dispatch({
        type: "adminDeleteUserFailure",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
      return toast.error(err.response.data.message || "Something went wrong");
    });
};

export const adminUpdateUserStatus = (id, status, navigate) => async (dispatch) => {
    dispatch({
      type: "adminUpdateUserStatusLoading",
    });
    await axios.patch(
      `${backend_api}/api/admin/updateUserStatus/${id}`,
      {
        status: status ? "active" : "inactive",
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("admin_access_token")}`,
        },
      }
    ).then(async (res) => {
      dispatch({
        type: "adminUpdateUserStatusSuccess",
        payload: res.data,
      });
      toast.success(res.data.message);
      navigate("/admin-dashboard");
    }).catch((err) => {
      dispatch({
        type: "adminUpdateUserStatusFailure",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
      return toast.error(err.response.data.message || "Something went wrong");
    });
};

export const adminUpdateUserPlan = (id, plan) => async (dispatch) => {
    dispatch({
      type: "adminUpdateUserPlanLoading",
    });
    axios.patch(
      `${backend_api}/api/admin/updateUserSubscription`,
      {
        userId: id,
        planType: plan,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("admin_access_token")}`,
        },
      }
    ).then(async (res) => {
      dispatch({
        type: "adminUpdateUserPlanSuccess",
        payload: res.data,
      });
      toast.success("User Plan Updated Successfully");
    }).catch((err) => {
      dispatch({
        type: "adminUpdateUserPlanFailure",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
      return toast.error(err.response.data.message || "Something went wrong");
    });
};

export const getAllUsersCustomers = (id) => async (dispatch) => {
    dispatch({
      type: "adminUsersCustomersLoading",
    });
    await axios.get(
      `${backend_api}/api/admin/getUserCustomers/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("admin_access_token")}`,
        },
      }
    ).then(async (res) => {
      dispatch({
        type: "adminUsersCustomersSuccess",
        payload: res.data,
      });
    }).catch((err) => {
      dispatch({
        type: "adminUsersCustomersFailure",
        payload: err.response.data.message,
      });
      if(err.response.status === 401) redirectToLogin();
      return toast.error(err.response.data.message || "Something went wrong");
    });
};