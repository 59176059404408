import React, { useContext, useEffect, useState } from "react";
import "./loginStyles.css";
import logo from "../../assets/images/GSPro-Logo.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import BackButton from "../../components/BackButton";
import axios from "axios";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/actions/userAction";
import loginImg from "../../assets/images/LoginAvatar.svg";
import { backend_api } from "../../redux/store";
import { LoadingContext } from "../Home/ContextProvider";

const Login = () => {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);

  const navigate = useNavigate();
  const { userLogin } = useSelector((state) => state.user);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("access_token");
  const error = urlParams.get("error");
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const emailValidation = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onPressLogin = async () => {
    if (userDetails.email === "" || userDetails.password === "") {
      toast.error("Please enter all the details");
    } else if (!emailValidation(userDetails.email)) {
      // toast.error("Please enter a valid email");
      setEmailError(true);
    } else if (userDetails.password.length < 6) {
      setEmailError(false);
      setPasswordError(true);
    } else {
      setEmailError(false);
      setPasswordError(false);
      increaseLoadingCounter();
      dispatch(loginUser(userDetails, navigate)).then(() => {
        decreaseLoadingCounter();
      });
    }
  };

  useEffect(() => {
    const previousToken = Cookies.get("access_token");
    if (previousToken) {
      try {
        const decodedToken = jwtDecode(previousToken);
        if (decodedToken?.exp * 1000 >= Date.now()) navigate("/home");
      } catch (error) { }
    }
  }, [userLogin]);

  useEffect(() => {
    if (token) {
      new Promise((resolve, reject) => {
        Cookies.set("access_token", token);
        resolve();
      }).then(() => {
        navigate("/home");
      });
    }
    if (error) toast.error(error);
  }, [token, error]);

  const handleGoogleLogin = () => {
    window.open(`${backend_api}/api/auth/google`, '_self');
  };

  return (
    <div className="login-page">
      <div className="login-page-inner-container container-left">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <h1 id="title-text">Hi Professional, Welcome </h1>
          <h1 id="title-text">to Gallery Studio Pro</h1>
        </div>
        <p id="subtitle-text">
          Explore the app, Upload your client image and share with them for approval workflows
        </p>
        <img src={loginImg} alt="login" id="login-img" />
      </div>
      <div className="login-page-inner-container container-right">
        <div className="login-page-form-container">
          <div className="login-page-form-container-top">
            {/* <BackButton /> */}
            <img
              src="https://img.icons8.com/material-sharp/50/left.png"
              alt="go-back"
              id="back-icon"
              onClick={()=>navigate('/')}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img src={logo} alt="google" id="logo" style={{ height: '3rem' }} />
            </div>
          </div>
          <div className="login-page-form-container-middle">
            <h1 id="welcome-back">Welcome Back</h1>
            <button className="login-btn google-btn" onClick={handleGoogleLogin}>
              <img
                src="https://img.icons8.com/color/48/google-logo.png"
                alt="google"
                id="google-icon"
              />
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "#3F414E",
                  marginBottom: '0px'
                }}
              >
                Continue with Google
              </p>
            </button>
            <p id="continue-with-email">OR LOGIN WITH EMAIL</p>
            <input
              type="text"
              placeholder="Email Address"
              className="login-form-input"
              value={userDetails.email}
              onChange={(e) =>
                setUserDetails({ ...userDetails, email: e.target.value })
              }
            />
            {emailError && (
              <span style={{ color: "red" }}>Please enter a valid email</span>
            )}
            <input
              type="password"
              placeholder="Password"
              className="login-form-input"
              value={userDetails.password}
              onChange={(e) =>
                setUserDetails({ ...userDetails, password: e.target.value })
              }
            />
            {passwordError && (
              <span style={{ color: "red" }}>
                Password should be atleast 6 characters long
              </span>
            )}
            <button className="login-btn main-btn" onClick={onPressLogin}>
              Log in
            </button>
            <Link
              to="/forgot-password"
              style={{
                textDecoration: "none",
              }}
            >
              <p id="forgot-pass">Forgot Password?</p>
            </Link>
          </div>
          <div className="login-page-form-container-bottom">
            <Link
              to="/registration"
              style={{
                textDecoration: "none",
              }}
            >
              <p id="continue-with-email">Don't have an account? Sign up</p>
            </Link>
            <div id="line" />
            <p className="poweredBy">Powered By : GalleryStudioPro</p>
          </div>
        </div>
      </div>
      <div id="bottom-div"></div>
    </div>
  );
};

export default Login;
