import React, { useEffect, useState } from "react";
import folderImg from "../../assets/images/folderImg.png";
import { useSelector, useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import moment from "moment";
import emptyFiles from '../../assets/images/emptyClients.png'


function BlockedUsers() {
  const [data, setdata] = useState([])
  const { get_Costumers } = useSelector((state) => state.user);
  const dispatch = useDispatch()

  useEffect(() => {

    const filteredData = get_Costumers?.customers.filter((customer) => {
      return customer.status === 'inactive';
    });
    setdata(filteredData)
  }, [get_Costumers])
  return (
    <div style={{
      width: "100%",
      marginTop: "50px",
    }}>
      {
        data?.length > 0 ?
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",

              gap: "30px",
            }}
          >
            {data?.map((item) => (
              <Card item={item} />
            ))}
          </div> :
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img draggable={false} src={emptyFiles} alt="no files found" id="no-files-img" />
          </div>
      }
    </div>
  );
}



const Card = ({ item }) => {

  // const navigation = useNavigation();
  return (
    <div
      className="card"
      style={{backgroundColor: '#F6F6F6'}}
    >
      <div>

        <IconButton
          aria-label="more"
          id="long-button"
          // aria-controls={open ? "long-menu" : undefined}
          // aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
        >
          {/* <MoreVertIcon onClick={handleClick} /> */}
        </IconButton>
      </div>
      <div
        className="card-top"
        onClick={() => {
          // navigation(`/clients?client-details=`, { state: { data: item } });
        }}
      >
        <img src={folderImg} alt="folder" id="folder-img" />
        <p className="card-top-title" style={{color: 'rgba(63, 65, 78, 0.6)'}}>{item?.name}</p>
        <p className="card-top-text" style={{color: 'rgba(63, 65, 78, 0.6)'}}>
          Created Date : {moment(item?.createdAt).format("DD MMM YYYY hh:mm a")}
        </p>
      </div>
      <div className="card-bottom">
        <div className="card-bottom-left">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <img
              id="folder-card-icon"
              src="https://img.icons8.com/small/50/folder-invoices--v1.png"
              alt="folder-invoices--v1"
            />
            <p className="card-bottom-text">{item?.totalFolders}</p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <img
              id="folder-card-icon"
              src="https://img.icons8.com/ios/50/copy--v1.png"
              alt="copy--v1"
            />
            <p className="card-bottom-text">{item?.totalFiles}</p>
          </div>
        </div>
        <div className="card-bottom-right">
          <button style={{backgroundColor: '#C5C5C5', fontWeight: 'bold'}} id="active-btn">{item?.status}</button>
        </div>
      </div>
    </div>
  )
};

export default BlockedUsers;
