import React from 'react'
import Image from "../../assets/images/81-crystalline-banner1.png"
import Header from './Header'
import ImageGallery from '../../components/ImageGallery/ImageGallery'

export default function ClientInsideFolder() {
  return (
    <div style={{backgroundColor:'#f9f6f1'}}>
      <Header />
      <div style={{ backgroundImage: `url(${Image})`, width: window.innerWidth, height: '367px', backgroundSize: 'cover', alignItems: 'center', display: "flex", flexDirection: 'column' }}>
        <span style={{ color: '#fff', fontSize: '18px', marginTop: '20%' }}>Welcome Back!</span>
        <span style={{ color: '#fff', fontSize: '25px', fontWeight: 'bold' }}>Murali Krishna WEDS Sowmya</span>
      </div>
      <ImageGallery/>
      <img src={require('../../assets/images/GSPro-Logo.png')} style={{ height: '50px', width: '260px',marginLeft:'43%' }} alt="Logo" />
       
      </div>
  )
}
