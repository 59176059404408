import { createReducer } from "@reduxjs/toolkit";

export const adminReducer = createReducer({}, (builder) => {
  builder

    // LOADING REQUEST
    .addCase("adminLoginLoading", (state) => {
      state.loading = true;
    })
    .addCase("adminGetAllUsersLoading", (state) => {
      state.loading = true;
    })
    .addCase("adminCreateUsersLoading", (state) => {
      state.loading = true;
    })
    .addCase("adminDeleteUserLoading", (state) => {
      state.loading = true;
    })
    .addCase("adminUpdateUserStatusLoading", (state) => {
      state.loading = true;
    })
    .addCase("adminUpdateUserPlanLoading", (state) => {
      state.loading = true;
    })
    .addCase("adminUsersCustomersLoading", (state) => {
      state.loading = true;
    })

    // SUCCESS REQUEST
    .addCase("adminLoginSuccess", (state, action) => {
      state.loading = false;
      state.adminLogin = action.payload;
    })
    .addCase("adminGetAllUsersSuccess", (state, action) => {
      state.loading = false;
      state.adminAllUsers = action.payload;
    })
    .addCase("adminCreateUserSuccess", (state, action) => {
      state.loading = false;
      state.adminCreateUser = action.payload;
    })
    .addCase("adminDeleteUserSuccess", (state, action) => {
      state.loading = false;
      state.adminDeleteUser = action.payload;
    })
    .addCase("adminUpdateUserStatusSuccess", (state, action) => {
      state.loading = false;
      state.adminUpdateUserStatus = action.payload;
    })
    .addCase("adminUpdateUserPlanSuccess", (state, action) => {
      state.loading = false;
      state.adminUpdateUserPlan = action.payload;
    })
    .addCase("adminUsersCustomersSuccess", (state, action) => {
      state.loading = false;
      state.adminUsersCustomers = action.payload;
    })

    // FAIL REQUEST
    .addCase("adminLoginFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("adminGetAllUsersFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("adminCreateUserFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("adminDeleteUserFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("adminUpdateUserStatusFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("adminUpdateUserPlanFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("adminUsersCustomersFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});
