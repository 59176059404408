import React from 'react'
import FavIcon from "../assets/img/FavIcon.png";
export default function StaticFooter() {
  return (
    <div className="container w-container gs_reveal">
                <div className="w-layout-grid grid-footer">
                  <div>
                    <a href="/" aria-current="page" className="footer-logo-wrap w-inline-block w--current">
                      <img src={FavIcon} loading="eager" alt="" className="footer-logo-wrap" />
                    </a>
                    <p className="no-margin">Securely Organize, Showcase,and Collaborate 
                      On your Media Assets with <br/><b>Gallery Studio pro</b></p>
                  </div>
                  <div id="w-node-_5b5b05ab-e775-238d-7db9-2056f436ada0-f436ad98" className="footer-item-wrap">
                    <h6 className="footer-title">Pages</h6>
                    <div className="w-layout-grid grid-footer-link">
                      <div className="footer-link-wrap">
                        <a href="/" aria-current="page" className="footer-link w--current">Home</a>
                        <a href="/About" aria-current="page" className="footer-link w--current">About</a>
                        <a href="/features" aria-current="page" className="footer-link w--current">Features</a>
                        <a href="/pricing" aria-current="page" className="footer-link w--current">Pricing</a>
                        
                      </div>
                    </div>
                  </div>
                  <div id="w-node-_5b5b05ab-e775-238d-7db9-2056f436adb6-f436ad98" className="footer-item-wrap">
                    <h6 className="footer-title">Utility pages</h6>
                    <div className="w-layout-grid grid-footer-link">
                      <div className="footer-link-wrap">
                        <a href="/privacy" className="footer-link"> Privacy</a>
                        <a href="/terms" className="footer-link">Terms</a>
                        <a href="/security" className="footer-link">Security</a>
                      </div>
                    </div>
                  </div>
                  <div id="w-node-_5b5b05ab-e775-238d-7db9-2056f436adcc-f436ad98" className="footer-item-wrap">
                    <h6 className="footer-title">Contact Us</h6>
                    <div className="grid-footer-link"><a href="mailto:info@gallerystudiopro.com" className="footer-link">info@gallerystudiopro.com</a>
                      <div className="footer-social-wrap">
                        <a href target="_blank" className="footer-social-link  w-inline-block"><img src="assets/img/fb.svg" loading="eager" alt="" className="footer-social-icon" /></a>
                        <a href target="_blank" className="footer-social-link w-inline-block"><img src="/assets/img/insta.svg" loading="eager" alt="" className="footer-social-icon" /></a>
                        <a href target="_blank" className="footer-social-link w-inline-block"><img src="/assets/img/youtube.svg" loading="eager" alt="" className="footer-social-icon" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-bottom text-center ">
                  <div className="xy-auto"> Powered by <a href target="_blank" className="footer-link text-primary-2">The Smart Trunk</a></div>
                </div>
              </div>
  )
}
