import React, { useContext } from "react";
import "./ClientDashboardImgStyles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useState } from "react";
import { Button, Modal } from "@mui/material";
import { Star, StarBorder, ChatBubbleOutline, CircleOutlined, Close, CheckCircle, DeleteOutline } from "@mui/icons-material";
import { ReactDialogBox } from "react-js-dialog-box";
import CloseIcon from "@mui/icons-material/Close";
import "react-js-dialog-box/dist/index.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Circle } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { moveFileToTrash } from "../../redux/actions/userAction";
import { addComment } from "../../redux/actions/userAction";
import moment from "moment/moment";
import ImageIcon from '../../assets/images/imageicon.png'
import { useEffect } from "react";
import {
  addClientComment,
  getClientFilesFolders,
  moveClientFileToTrash,
  moveMultipleFilesToTrashCustomer,
  updateClientApproveStatus,
  updateClientImportantStatus,
} from "../../redux/actions/clientActions";
import ReactPlayer from 'react-player';
import HeadPhoneImage from '../../assets/images/headphone.png';
import { LoadingContext } from "../Home/ContextProvider";

function ClientDashboardImg({ data, index, handleClose }) {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const { clientComment, clientApproveStatus, clientImportantStatus } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [dialogn, setDialog] = useState(false);
  const location = useLocation();
  const handleTextChange = (e) => {
    setComment(e.target.value);
  };

  // const data = location.state?.ImageArray || [];
  // const index = location.state?.index || 0;

  const [CurrentIndex, setCurrentIndex] = useState(index);
  const [fileType, setFileType] = useState(data[CurrentIndex]?.mimeType?.split("/")[0]); // ["image", "video", "audio"
  const [isImportant, setIsImportant] = useState(data[CurrentIndex]?.isImportant);
  const [isApproved, setIsApproved] = useState(data[CurrentIndex]?.isApproved);
  const [parentFolderId, setParentFolderId] = useState(data[0]?.parent);
  const [allComments, setAllComments] = useState(data[CurrentIndex]?.comments);
  const [CommentClose, setCommentClose] = useState(false);
  const [customerName, setCustomerName] = useState(data[CurrentIndex]?.parent.username);

  useEffect(() => {
    setAllComments(clientComment?.file?.comments);
  }, [clientComment]);


  useEffect(() => {
    setAllComments(data[CurrentIndex]?.comments);
  }, []);

  const styles = {
    icon: {
      color: "white",
      marginLeft: "10px",
      cursor: "pointer",
    },
  };

  useEffect(() => {
    setIsImportant(data[CurrentIndex]?.isImportant);
    setIsApproved(data[CurrentIndex]?.isApproved);
    setAllComments(data[CurrentIndex]?.comments);
    setFileType(data[CurrentIndex]?.mimeType?.split("/")[0]);
  }, [CurrentIndex, data]);

  const handleUpdateImportantStatus = () => {
    setIsImportant(!isImportant);
    increaseLoadingCounter();
    dispatch(updateClientImportantStatus(data[CurrentIndex]._id, !isImportant)).then(() => {
      decreaseLoadingCounter();
    });
  };

  const handleUpdateApprovalStatus = () => {
    setIsApproved(!isApproved);
    increaseLoadingCounter();
    dispatch(updateClientApproveStatus(data[CurrentIndex]._id, !isApproved)).then(() => {
      decreaseLoadingCounter();
    });
  };


  const DeleteWarningModal = () => (
    <div className="image-gallery-modal">
      <Modal
        open={dialogn}
        onClose={() => setDialog(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modalPopup">
          <p style={{ color: '#000' }}>
            Are you sure you want to delete !
          </p>
          <Button
            onClick={() => setDialog(false)}
            style={{ position: 'absolute', right: 0, top: 0 }}
          >
            <span style={{ fontFamily: 'Nunito', fontWeight: 'bold', fontSize: '18px', color: '#000' }}>x</span>
          </Button>
          <div >
            <button
              style={{ color: '#000', padding: '5px', fontFamily: 'Nunito', backgroundColor: '#5DCE7B', color: '#000', width: '80px', textTransform: 'capitalize', borderRadius: '20px', marginTop: '20px' }}
              onClick={() => {
                increaseLoadingCounter();
                dispatch(moveMultipleFilesToTrashCustomer([data[CurrentIndex]._id])).then(() => {
                  decreaseLoadingCounter();
                });
                setDialog(false);
              }}
            >
              yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );

  return (
    <div className="Imagecontainer">
      {/* {dialogn && (
        <ReactDialogBox
          closeBox={() => setDialog(false)}
          modalWidth="450px"
          headerBackgroundColor="transparent"
          headerTextColor="white"
          headerHeight="20px"
          closeButtonColor="black"
          bodyBackgroundColor="white"
          bodyTextColor="black"
          bodyHeight="150px"
          style={{ borderRadius: "20px" }}

        >
          <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}} >
            <p
              style={{ color: "black", fontSize: "21px",  }}
            >
              Are you sure you want to delete !
            </p>
            <Button
              onClick={() => {
                increaseLoadingCounter();
                dispatch(moveMultipleFilesToTrashCustomer([data[CurrentIndex]._id])).then(()=> {
                  decreaseLoadingCounter();
                });
                setDialog(false);
              }}
              style={{
                backgroundColor: "#5dce7b",
                borderRadius: "20px",
                color: "white",
                marginBottom: "10px",
                marginTop: "20px",
              }}
            >
              Yes
            </Button>
          </div>
        </ReactDialogBox>
      )} */}
      <div className={CommentClose ? 'w70 div1' : 'w95 div1'} >
        <DeleteWarningModal />
        <div
          style={{
            display: "flex",
            height: window.innerHeight - 110,
            alignItems: "center",
          }}
        >
          <ArrowBackIosIcon style={{ color: '#fff', cursor: 'pointer' }} onClick={() => { CurrentIndex > 0 && setCurrentIndex(CurrentIndex - 1) }} />
          {
            fileType === "image" ?
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column',  paddingTop: '2%' }}>
                <span className="imageGalleryName" style={{ marginBottom: '10px', color: '#fff', }}> <img src={ImageIcon} style={{ height: '20px' }} />  {data[CurrentIndex]?.name}</span>
                <img src={data[CurrentIndex]?.url} style={{ alignSelf: 'center', width: "95%", height: "80%", margin: '0 auto', objectFit: 'contain', maxHeight: '90%', maxWidth: '100%'}} />
              </div>
              : fileType === "video" ?
                <ReactPlayer url={data[CurrentIndex]?.url} controls={true} config={{ file: { attributes: { controlsList: 'nodownload', onContextMenu: e => e.preventDefault() } } }} width={'100%'} height={'70%'} />
                : fileType === "audio" ?
                  <div  style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', backgroundColor: "#F9F6F1" }}>
                    <img src={HeadPhoneImage} style={{ width: '8rem', height: '8rem', margin: '8rem 0rem' }} />
                    <ReactPlayer url={data[CurrentIndex]?.url} controls={true} config={{ file: { attributes: { controlsList: 'nodownload', onContextMenu: e => e.preventDefault() } } }} width={'100%'} height={'50px'} />
                  </div>
                  : <></>
          }
          <ArrowForwardIosIcon style={{ color: '#fff', cursor: 'pointer', marginLeft: "auto" }} onClick={() => { CurrentIndex < data.length - 1 && setCurrentIndex(CurrentIndex + 1) }} />
        </div>
        <div className="client-popup-btns"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "auto",
          }}
        >
          <Button
            onClick={handleUpdateImportantStatus}
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              background:isImportant ? "#424200" : "#111319",
              borderRadius:"10px"
            }}
          >
            <p style={{ fontFamily: "Nunito", marginBottom: '0px' }}>{isImportant ? 'Marked' : 'Mark'} As Important</p>
            {
              isImportant ? <Star style={{ color: "yellow", marginLeft: "10px" }} /> : <StarBorder style={{ color: "yellow", marginLeft: "10px" }} />
            }
          </Button>
          <Button
            onClick={handleUpdateApprovalStatus}
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              textTransform: "capitalize",
              background:isApproved ? "#034803": "#111319",
              borderRadius:"10px"
            }}
          >
            <p style={{ fontFamily: "Nunito", marginBottom: '0px' }}>{isApproved ? 'Approved' : 'Approve'}</p>
            {
              isApproved ? <CheckCircle style={{ ...styles.icon, color: "green" }} /> : <CircleOutlined style={{ ...styles.icon, color: "green" }} />
            }
          </Button>
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              marginLeft: "10px",
              background:"#111319",
              borderRadius:"10px",
              position:"relative"
            }}
            onClick={() => setCommentClose(true)}
          >
            <p style={{ fontFamily: "Nunito", marginBottom: '0px' }}>Comments</p>
            <ChatBubbleOutline style={{ ...styles.icon }} />
            {allComments.length > 0 ? <span className="commnetNumbers" style={{}}>{allComments.length}</span> : " "}
          </Button>
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              marginLeft: "10px",
              background:"#2c0b0b",
              borderRadius:"10px"
            }}
            onClick={() => setDialog(true)}
          >
            <p style={{ fontFamily: "Nunito",marginBottom:'0px' }}>Delete</p>
            <DeleteOutline
              onClick={() => setDialog(true)}
              style={{ ...styles.icon }}
            />
          </Button>
        </div>
      </div>
      {
        CommentClose ?
          <div className="div2 client-comments-main" style={{ height: window.innerHeight }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: " 20px 10px",
                alignItems: "center",
                width: "90%",
              }}
            >
              <p style={{ color: "white" }}>Comments</p>
              <CloseIcon
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => setCommentClose(false)}
              />
            </div>
            <div
              style={{
                overflow: "auto",
                overflowX: "hidden",
                width: "90%",
                height: "100%",
              }}
            >
             
              {
                allComments?.map((item) => (
                  <div
                    style={{
                      display: "flex",
                    flexDirection: "row",
                    borderRadius: "10px",
                    width: "90%",
                    wordWrap: "break-word",
                    margin: "5px",
                    }}
                  >
                    <img
                      src={require("../../assets/images/chat.png")}
                      style={{ height: "30px", width: "30px" }}
                    />
                    <div className= "commentBlue" style={{ marginLeft: "15px", padding:"10px", borderRadius:"0 10px 10px 10px" }}>
                      <span style={{ color: "#83c8ff", fontSize: "12px", marginBottom: '5px', display:"block" }}>
                        {item.name}
                        
                      </span>
                      
                      <p
                        style={{
                          marginBottom: 5,
                          color: "#fff",
                          fontSize: "14px",
                          fontFamily: "Nunito",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {item.comment}
                      </p>
                      
                      <p
                        style={{
                          marginTop: 5,
                        color: "#a5a5a5",
                        fontSize: "12px",
                        fontWeight: "normal",
                        fontFamily: "Nunito",
                        }}
                      >
                        Added : {moment(item?.createdAt).format("DD MMM YYYY")}
                      </p>
                    </div>
                  </div>
                ))}
            </div>

            <textarea
              placeholder="Enter Your Comments Here ...."
              id="textArea"
              style={{
                borderRadius: "10px",
                width: "90%",
                marginTop: "auto",
                marginBottom: "20px",
                padding: "10px",
              }}
              rows={4} // Set the minimum number of visible lines
              value={comment}
              onChange={handleTextChange}
            />
            <Button
              onClick={() => {
                increaseLoadingCounter();
                dispatch(addClientComment(data[CurrentIndex]._id, comment)).then(() => {
                  decreaseLoadingCounter();
                });
                setComment("");
              }}
              style={{
                backgroundColor: "#5dce7b",
                borderRadius: "20px",
                color: "white",
                marginBottom: "10px",
                marginLeft: "auto",
                marginRight: "20px",
              }}
            >
              Add
            </Button>
          </div>
          :
          <Close style={{ color: '#fff', position: 'absolute', top: '1.5rem', right: '1.5rem', cursor: 'pointer' }} onClick={handleClose} />
      }
    </div>
  );
}

export default ClientDashboardImg;
